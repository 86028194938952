import { AxiosPromise } from "axios";
import API from "./API";

import { IParamPopup } from "../interfaces/Popup";

const PopupAPI = {
    set: (params: IParamPopup): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_POPUP_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    update: (params: IParamPopup): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_POPUP_URL + `/${params.uid}`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    get: (uid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_POPUP_URL + `/${uid}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    delete: (uid: string): AxiosPromise => {
        return API({
            method: 'delete',
            url: process.env.REACT_APP_API_POPUP_URL + `/${uid}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    upload: (file: File, uploadKey: string = ''): AxiosPromise => {
        let baseUrl = process.env.REACT_APP_API_UPLOAD_URL + `/editor/popup`;
        let targetUrl = (uploadKey) ? baseUrl + `?upload_key=${uploadKey}` : baseUrl;

        let fileData = new FormData();

        fileData.append("editor_image", file);
        
        return API({
            method: 'post',
            url: targetUrl,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: fileData
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    }
}

export default PopupAPI;