import React, { useState } from 'react';

import routes from '../../routes';

import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

import { ILayoutProps } from '../../interfaces/Layout';

const Dashboard = (layoutProps: ILayoutProps) => {
    
    const [backgroundColor, setBackgroundColor] = useState('black');
    const [activeColor, setActiveColor] = useState("primary");

    return (
        <>
            <Sidebar
                routes={routes}
                backgroundColor={backgroundColor}
                activeColor={activeColor}
            />
            <div className="panel">
                <Header routes={routes} />
                {layoutProps.element}
                <Footer />
            </div>
        </>
    );
};

export default Dashboard