import React from "react";

import SettingMarketCombinesForm from "./SettingMarketCombinesForm";

const SettingMarketCombinesView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingMarketCombinesForm />
            </div>
        </div>
    )
}

export default SettingMarketCombinesView