import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button
} from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';

import { IBoard } from "../../interfaces/Board";
import BoardAPI from "../../modules/BoardAPI";

const BoardFormModal = ({ uid }: any) => {
    const location = useLocation();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert } = useModal();
    
    const [boardKeyDisabled, setBoardKeyDisabled] = useState<boolean>(false);

    const typeOptions = [
        { value: 'default', label: '일반게시판' },
        { value: 'qna', label: 'Q&A게시판' }
    ]

    let defaultValues: IBoard = {
        type: 'default',
        key: '',
        name: ''
    };

    const { watch, handleSubmit, control, reset, setFocus, setError, formState } = useForm<IBoard>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    useEffect(() => {
        if(uid){
            BoardAPI.get(uid).then((res) => {
                if(res.data.success){
                    const board = res.data.message;

                    let resetValues: IBoard = {
                        ...board
                    }

                    reset(resetValues);

                    setBoardKeyDisabled(true);
                }else{
                    modalAlert({
                        component: <>존재하지 않는 게시판입니다.</>,
                        title: '게시판 추가/수정'
                    });
                }
            });
        }
    }, [location]);

    const handleOnSubmit: SubmitHandler<IBoard> = (formData) => {
        let { ...formParams } = formData;

        console.log('formParams', formParams)

        if(uid){
            BoardAPI.update(uid, formParams).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '게시판 추가/수정'
                    });

                    reset(formParams);
                }else{
                    console.log(res)

                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시판 추가/수정'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시판 추가/수정'
                    });
                }
            })
        }else{
            BoardAPI.create(formParams).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '게시판 추가/수정'
                    });
                }else{
                    reset(formParams);

                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시판 추가/수정'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시판 추가/수정'
                    });
                }
            })
        }
    }
    
    return (
        <div className="modal-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)} id="modal-form-board">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        name="key"
                                        label="게시판 키"
                                        placeholder="게시판 키"
                                        rules={{
                                            required: true,
                                            pattern: /^[a-z]+([a-z0-9]+)?$/
                                        }}
                                        disabled={boardKeyDisabled}
                                    />
                                    <FormFeedback>
                                        올바른 게시판 키를 입력해주세요.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        type="select"
                                        name="type"
                                        label="게시판 타입"
                                        placeholder="게시판 타입"
                                        options={typeOptions}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        name="name"
                                        label="게시판 이름"
                                        placeholder="게시판 이름"
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default BoardFormModal