import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import {
    Modal,
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form
} from 'reactstrap';

import { hideModal, toggleModal } from "../../../redux/reducers/modalReducer";
import { SubmitHandler } from "react-hook-form";

const FormModal = ({ modalVisible, formId, component, title='', size='lg', confirmText = 'OK', cancelText = 'Cancel', handleClose }: any) => {

    title = (title == '') ? 'Confirm' : title;

    const dispatch = useDispatch();

    const handleHideModal = () => dispatch(hideModal());
    const handleToggleModal = () => dispatch(toggleModal());

    const onClose = () => {
        if (handleClose) {
            handleClose();
        }

        handleHideModal();
    };

    return (
        <Modal size={size} isOpen={modalVisible} toggle={handleToggleModal}>
            <ModalHeader toggle={handleToggleModal}>{title}</ModalHeader>
            <ModalBody>
                {component}
            </ModalBody>
            <ModalFooter>
                <Button type="submit" className="btn-round" color="primary" form={formId}>
                    {confirmText}
                </Button>
                <Button className="btn-round" color="secondary" onClick={onClose}>
                    {cancelText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FormModal;
