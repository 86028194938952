import { AxiosPromise } from "axios";
import API from "./API";

const CommonAPI = {
    getCommon: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_COMMON_URL,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
}

export default CommonAPI;