import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col, Tooltip } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link, useParams } from "react-router-dom";
import * as DOMPurify from 'dompurify';

import Util from "../../modules/Util";

import SearchForm from './SearchForm';
import PopupsAPI from "../../modules/PopupsAPI";
import PopupFormModal from "./PopupFormModal";
import PopupAPI from "../../modules/PopupAPI";

const PopupsView = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm, modalForm } = useModal();

    const [ viewIndex, setViewIndex ] = useState<number | null>(null)
    const [ data, setData ] = useState({
        total: 0,
        popups: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ searchForms, setSearchForms ] = useState({
        type: '',
        keyword: ''
    });

    useEffect(() => {
        PopupsAPI.get(page, limit, searchForms.type, searchForms.keyword).then(( res ) => {
            const { total, popups } = res.data.message;

            let filterPopups: any = [];

            for( const popup of popups ){
                let filterMemo = {
                    ...popup
                }

                filterPopups.push(filterMemo);
            }

            setData({ total, popups: filterPopups });

        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '팝업 목록'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            PopupsAPI.get(1, limit, searchForms.type, searchForms.keyword).then(( res ) => {
                const { total, popups } = res.data.message;

                let filterPopups: any = [];

                for( const popup of popups ){
                    let filterMemo = {
                        ...popup
                    }

                    filterPopups.push(filterMemo);
                }

                setData({ total, popups: filterPopups });
    
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '팝업 목록'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [searchForms.type, searchForms.keyword]);

    const handleDelete = (uid: string) => {
        modalConfirm({
            component: <>정말 삭제하시겠습니까?</>,
            title: '팝업 삭제',
            handleConfirm: () => {
                PopupAPI.delete(uid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>삭제되었습니다.</>,
                            title: '팝업 삭제'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '팝업 삭제'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '팝업 삭제'
                    });
                });
            }
        })
    }

    const handleEdit = (uid?: string) => {
        modalForm({
            formId: 'modal-form-popup',
            component: <PopupFormModal uid={uid} />,
            title: '팝업 추가/수정'
        });
    }

    return (
        <div className="contents">
            <Row className="mb-3">
                <Col>
                    <div className="d-flex justify-content-end">
                        <Button
                            className="btn-round"
                            type="button"
                            color="secondary" 
                            onClick={(event) => handleEdit()}
                        >
                            <i className="fas fa-plus"></i> 팝업추가
                        </Button>
                    </div>
                </Col>
            </Row>
            <SearchForm searchForms={searchForms} setSearchForms={setSearchForms} />
            <Card>
                <CardBody>
                    <table className="table">
                        <colgroup>
                            <col width="80px"/>
                            <col width="120px"/>
                            <col />
                            <col width="200px"/>
                            <col width="150px"/>
                        </colgroup>
                        <thead>
                            <tr className="align-middle">
                                <th scope="col">번호</th>
                                <th scope="col">타입</th>
                                <th scope="col">제목</th>
                                <th scope="col">등록일</th>
                                <th scope="col">관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.popups.map(( popup: any, key ) => 
                                <React.Fragment key={key}>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <th scope="row">{key + 1}</th>
                                        <td>{popup.type}</td>
                                        <td><span onClick={(event) => handleEdit(popup.uid)} className="text-primary text-decoration-underline cursor-pointer">{popup.subject}</span></td>
                                        <td>{moment(popup.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td>
                                            <Button
                                                className="btn-table-icon"
                                                type="button"
                                                color="danger"
                                                onClick={(event) => handleDelete(popup.uid)}
                                            >
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}

                            {data.total <= 0 && 
                                <tr className="align-middle">
                                    <td colSpan={5} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                        total={data.total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default PopupsView