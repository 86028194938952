import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";

import SearchForm from './SearchForm';
import PostFormModal from "./PostFormModal";
import BoardPostAPI from "../../modules/BoardPostAPI";
import PostViewModal from "./PostViewModal";

const PostsView = () => {
    const { key_board } = useParams();

    const { state: locationState } = useLocation();
    const type_board = (locationState && locationState.type_board) ? locationState.type_board : 'default';

    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm, modalForm } = useModal();

    const [ data, setData ] = useState({
        total: 0,
        posts: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { keyword }, setSearchForms ] = useState({
        keyword: ''
    });

    useEffect(() => {
        if(key_board){
            BoardPostAPI.getPosts(key_board, page, limit, keyword).then(( res ) => {
                const { total, posts } = res.data.message;
    
                setData({ total, posts });
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '게시글 목록'
                });
            });
        }else{
            modalAlert({
                component: <>게시판을 선택해주세요.</>,
                title: '게시글 목록',
                handleConfirm: () => {
                    navigate('/boards');
                }
            });
        }
    }, [page, limit]);

    useEffect(() => {
        if(page != 1){
            setPage(1);
        }else{
            if(key_board){
                const delay = setTimeout(() => {
                    BoardPostAPI.getPosts(key_board, 1, limit, keyword).then(( res ) => {
                        const { total, posts } = res.data.message;
        
                        setData({ total, posts });
                    }).catch(( error ) => {
                        modalAlert({
                            component: <>{error.message}</>,
                            title: '게시글 목록'
                        });
                    });
                }, 300);
                
                return () => clearTimeout(delay)
            }
        }
    }, [keyword]);

    const handleView = (uid: string, received: boolean, subject: string = '') => {
        if(type_board == 'qna'){
            if(received){
                modalAlert({
                    component: <PostViewModal key_board={key_board} type_board={type_board} uid_post={uid} />,
                    title: (!subject) ? '게시글' : subject,
                    size: "lg"
                });
            }else{
                modalForm({
                    formId: 'modal-form-answer',
                    component: <PostViewModal key_board={key_board} type_board={type_board} uid_post={uid} />,
                    title: (!subject) ? '게시글' : subject
                });
            }
        }else{
            modalAlert({
                component: <PostViewModal key_board={key_board} type_board={type_board} uid_post={uid} />,
                title: (!subject) ? '게시글' : subject,
                size: "lg"
            });
        }
    }

    const handleEdit = (uid: string = '') => {
        modalForm({
            formId: 'modal-form-post',
            component: <PostFormModal key_board={key_board} uid_post={uid} />,
            title: (!uid) ? '게시글 쓰기' : '게시글 수정'
        });
    }

    const handleDeleteBoard = (uuid: string) => {
        modalConfirm({
            component: <>정말로 삭제하시겠습니까?</>,
            title: '게시글 목록',
            handleConfirm: () => {
                /*
                AccountAPI.delete(uuid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            message: 'Deletion is complete.',
                            title: 'Alert'
                        });
                    }else{
                        modalAlert({
                            message: res.data.message,
                            title: 'Alert'
                        });
                    }
                }).catch((error) => {
                    const res = error.response;
        
                    if(res){
                        modalAlert({
                            message: res.data.message,
                            title: 'Alert'
                        });
                    }
                })
                */
            }
        })
    }

    return (
        <div className="contents">
            <Row className="mb-3">
                <Col>
                    <div className="d-flex justify-content-end">
                        <Button
                            className="btn-round"
                            type="button"
                            color="secondary" 
                            onClick={(event) => handleEdit()}
                        >
                            <i className="fa fa-plus"></i> 게시글 쓰기
                        </Button>
                    </div>
                </Col>
            </Row>
            <SearchForm setSearchForms={setSearchForms} />
            <Card>
                <CardBody>
                    <table className="table">
                        <colgroup>
                            <col  width="8%"/>
                            {type_board == 'qna' && <col  width="10%" />}
                            <col />
                            <col  width="10%" />
                            <col  width="15%" />
                            <col  width="15%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">번호</th>
                                {type_board == 'qna' && <th scope="col">답변</th>}
                                <th scope="col">제목</th>
                                <th scope="col">작성자</th>
                                <th scope="col">등록일</th>
                                <th scope="col">관리</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.posts.map(( post: any, key ) => 
                                <tr className="align-middle text-center" key={key}>
                                    <th scope="row">{key + 1}</th>
                                    {type_board == 'qna' && <td>{!post.received ? '답변대기':'답변완료'}</td>}
                                    <td className="text-start">
                                        <div
                                            className="clickable"
                                            onClick={(event) => handleView(post.uid, post.received, post.name_board)}
                                        >
                                            {post.subject}
                                        </div>
                                    </td>
                                    <td>{post.writer}</td>
                                    <td>{moment(post.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>
                                        <Button
                                            className="btn-table-icon"
                                            type="button"
                                            color="primary"
                                            onClick={(event) => handleEdit(post.uid)}
                                        >
                                            <i className="fas fa-edit" />
                                        </Button>
                                        &nbsp;
                                        <Button
                                            className="btn-table-icon"
                                            type="button"
                                            color="danger"
                                            onClick={(event) => handleDeleteBoard(post.idx)}
                                        >
                                            <i className="fas fa-trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )}

                            {data.total <= 0 && 
                                <tr className="align-middle">
                                    <td colSpan={type_board == 'qna' ? 6 : 5} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                        total={data.total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default PostsView