import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link, useParams } from "react-router-dom";

import Util from "../../modules/Util";

import SearchForm from './SearchForm';
import AmountsAPI from "../../modules/AmountsAPI";

const AmountsView = () => {
    const { request_type } = useParams();

    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();

    const [ data, setData ] = useState({
        total: 0,
        amounts: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ searchForms, setSearchForms ] = useState({
        //method: '',
        type: '',
        category: '',
        keyword: ''
    });

    useEffect(() => {
        if(request_type){
            AmountsAPI.getRequests(1, limit, request_type, searchForms.type, searchForms.keyword).then(( res ) => {
                if(res.data.success){
                    const { total, amounts } = res.data.message;

                    let filterAmounts: any = [];

                    for( const amount of amounts ){
                        let filterAmount = { 
                            ...amount, 
                            method_sign: (amount.method == '증액') ? '+' : '-',
                            username: (configState.signup_used_fullname == 'true') ? amount.fullname : `${amount.firstname} ${amount.lastname}`
                        };

                        filterAmounts.push(filterAmount);
                    }

                    setData({ total, amounts: filterAmounts });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: 'Amounts'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: 'Amounts'
                });
            });
        }else{
            AmountsAPI.get(page, limit, searchForms.type, searchForms.category, searchForms.keyword).then(( res ) => {
                const { total, amounts } = res.data.message;

                let filterAmounts: any = [];

                for( const amount of amounts ){
                    let filterAmount = { 
                        ...amount, 
                        method_sign: (amount.method == '증액') ? '+' : '-',
                        username: (configState.signup_used_fullname == 'true') ? amount.fullname : `${amount.firstname} ${amount.lastname}`
                    };

                    filterAmounts.push(filterAmount);
                }

                setData({ total, amounts: filterAmounts });
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: 'Amounts'
                });
            });
        }
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            if(request_type){
                AmountsAPI.getRequests(1, limit, request_type, searchForms.type, searchForms.keyword).then(( res ) => {
                    if(res.data.success){
                        const { total, amounts } = res.data.message;

                        let filterAmounts: any = [];

                        for( const amount of amounts ){
                            let filterAmount = { 
                                ...amount, 
                                method_sign: (amount.method == '증액') ? '+' : '-',
                                username: (configState.signup_used_fullname == 'true') ? amount.fullname : `${amount.firstname} ${amount.lastname}`
                            };

                            filterAmounts.push(filterAmount);
                        }

                        setData({ total, amounts: filterAmounts });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: 'Amounts'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: 'Amounts'
                    });
                });
            }else{
                AmountsAPI.get(1, limit, searchForms.type, searchForms.category, searchForms.keyword).then(( res ) => {
                    if(res.data.success){
                        const { total, amounts } = res.data.message;

                        let filterAmounts: any = [];

                        for( const amount of amounts ){
                            let filterAmount = { 
                                ...amount, 
                                method_sign: (amount.method == '증액') ? '+' : '-',
                                username: (configState.signup_used_fullname == 'true') ? amount.fullname : `${amount.firstname} ${amount.lastname}`
                            };

                            filterAmounts.push(filterAmount);
                        }

                        setData({ total, amounts: filterAmounts });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: 'Amounts'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: 'Amounts'
                    });
                });
            }
        }, 300);

        return () => clearTimeout(delay)
    }, [searchForms.keyword]);

    const handleDeleteAccount = (uuid: string) => {
        modalConfirm({
            component: <>Are you sure you want to delete?</>,
            title: 'Delete Account',
            handleConfirm: () => {
                /*
                AccountAPI.delete(uuid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>Deletion is complete.</>,
                            title: 'Alert'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: 'Alert'
                        });
                    }
                }).catch((error) => {
                    const res = error.response;
        
                    if(res){
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: 'Alert'
                        });
                    }
                })
                */
            }
        })
    }

    return (
        <div className="contents">
            <SearchForm searchForms={searchForms} setSearchForms={setSearchForms} />
            <Card>
                <CardBody>
                    <table className="table">
                        <thead>
                            <tr className="align-middle">
                                <th scope="col" rowSpan={2}>번호</th>
                                <th scope="col">타입 / 카테고리</th>
                                <th scope="col">아이디</th>
                                <th scope="col" rowSpan={2}>설명</th>
                                <th scope="col" rowSpan={2}>금액</th>
                                <th scope="col" rowSpan={2}>이전금액</th>
                                <th scope="col" rowSpan={2}>이후금액</th>
                                <th scope="col">등록일</th>
                            </tr>
                            <tr className="align-middle">
                                <th scope="col">상태</th>
                                <th scope="col">이름</th>
                                <th scope="col">처리일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.amounts.map(( amount: any, key ) => 
                                <React.Fragment key={key}>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <th rowSpan={2} scope="row">{key + 1}</th>
                                        <td>{amount.type} {amount.category}</td>
                                        <td>{amount.userid}</td>
                                        <td rowSpan={2}>{amount.description}</td>
                                        <td rowSpan={2}>{amount.method_sign} {Util.getNumberFormat(amount.amount)}</td>
                                        <td rowSpan={2}>{Util.getNumberFormat(amount.amount_before)}</td>
                                        <td rowSpan={2}>{Util.getNumberFormat(amount.amount_after)}</td>
                                        
                                        <td>{moment(amount.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <td>{amount.status}</td>
                                        <td>{amount.username}</td>
                                        <td>{amount.processed_at ? moment(amount.processed_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                    </tr>
                                </React.Fragment>
                            )}

                            {data.total <= 0 && 
                                <tr className="align-middle">
                                    <td colSpan={10} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                        total={data.total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default AmountsView