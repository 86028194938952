import React from "react";

import SettingBasicsForm from "./SettingBasicsForm";

const SettingBasicsView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingBasicsForm />
            </div>
        </div>
    )
}

export default SettingBasicsView