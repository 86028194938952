import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button,
    Label,
    Input,
    InputGroup
} from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';
import BetAPI from "../../modules/BetAPI";

const ChildMoveFormModal = ({ uuid, userid_current_parent }: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert } = useModal();
    
    const [agentOptions, setAgentOptions] = useState<{ value: any, label: any }[]>([]);

    let defaultValues: any = {
        userid_move_parent: ''
    };

    const { watch, handleSubmit, control, reset, setFocus, setError, formState } = useForm<any>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    useEffect(() => {
        if(!uuid){
            modalAlert({
                component: <>이동할 회원을 선택해주세요.</>,
                title: '하위 총판/회원 이동'
            });
        }else{
            BetAPI.getAgents().then(( res ) => {
                const { total, agents } = res.data.message;

                //let filterAgents = agents.filter((agent: any) => agent.userid != userid_current_parent)
                let filterAgents: { value: any, label: any }[] = [
                    {
                        value: '',
                        label: '선택해주세요'
                    }
                ]

                for( const agent of agents ){
                    if(agent.uuid != uuid && agent.userid != userid_current_parent)
                        filterAgents.push({ value: agent.userid, label: `${agent.userid} (${agent.detail.nickname})` })
                }

                setAgentOptions(filterAgents)
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '하위 총판/회원 이동'
                });
            });
        }
    }, [])

    const handleOnSubmit: SubmitHandler<any> = (formData) => {
        let { userid_move_parent, ...formParams } = formData;

        console.log('formParams', formParams)

        if(!userid_move_parent){
            modalAlert({
                component: <>이동할 총판을 선택해주세요.</>,
                title: '하위 총판/회원 이동'
            });
        }else{
            BetAPI.moveChildren(uuid, userid_move_parent).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '하위 총판/회원 이동'
                    });

                    navigate(0);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '하위 총판/회원 이동'
                    });
                }
            }).catch((error) => {
                const res = error.response;
    
                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '하위 총판/회원 이동'
                    });
                }
            });
        }
    }
    
    return (
        <div className="modal-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)} id="modal-form-children-move">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <p className="text-danger">
                                    ※ 이동 시 하위총판/회원이 이동하는 총판에 맞게 초기화됩니다.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Label>현재 총판</Label>
                                <InputGroup>
                                    <Input
                                        bsSize="sm"
                                        placeholder="현재 총판"
                                        value={userid_current_parent}
                                        disabled={true}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        type="select"
                                        name="userid_move_parent"
                                        label="이동 총판"
                                        placeholder="이동 총판"
                                        options={agentOptions}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default ChildMoveFormModal