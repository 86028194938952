import { AxiosPromise } from "axios";
import API from "./API";

import { IAccount } from "../interfaces/Account";
import { IBoard } from "../interfaces/Board";

const BoardAPI = {
    get: (uid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BOARD_URL + `/${uid}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    create: (params: IBoard): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_BOARD_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    update: (uid: string, params: IBoard): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BOARD_URL + `/${uid}`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    delete: (uid: string): AxiosPromise => {
        return API({
            method: 'delete',
            url: process.env.REACT_APP_API_BOARD_URL + `/${uid}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
}

export default BoardAPI;