import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link, useParams } from "react-router-dom";
import useSpinner from "../../hooks/GlobalSpinner";

import Util from "../../modules/Util";

import SearchForm from './SearchForm';
import BetAPI from "../../modules/BetAPI";
import PicksModal from "./PicksModal";

const BettingsView = () => {
    const { request_type } = useParams();

    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm, modalForm } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ data, setData ] = useState<{total: number, accountBets: any[]}>({
        total: 0,
        accountBets: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { target, result_type, amount_type, sport, keyword }, setSearchForms ] = useState({
        target: 'upcoming',
        result_type: '',
        amount_type: '',
        sport: '',
        keyword: ''
    });

    useEffect(() => {
        spinnerVisible(true);

        BetAPI.getBets(target, page, limit, result_type, amount_type, sport, keyword).then(( res ) => {
            if(res.data.success){
                const { total, accountBets } = res.data.message;

                console.log('accountBets', accountBets);

                let filterAccountBets: any = [];

                for( const accountBet of accountBets ){
                    let filterAccountBet = { 
                        ...accountBet, 
                        username: (configState.signup_used_fullname == 'true') ? accountBet.account_detail.fullname : `${accountBet.account_detail.firstname} ${accountBet.account_detail.lastname}`
                    };

                    filterAccountBets.push(filterAccountBet);
                }

                setData({ total, accountBets: filterAccountBets });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '베팅관리'
                });
            }

            spinnerVisible(false);
        }).catch(( error ) => {
            spinnerVisible(false);

            modalAlert({
                component: <>{error.message}</>,
                title: '베팅관리'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            spinnerVisible(true);

            BetAPI.getBets(target, 1, limit, amount_type, sport, keyword).then(( res ) => {
                if(res.data.success){
                    const { total, accountBets } = res.data.message;

                    console.log('accountBets', accountBets);

                    let filterAccountBets: any = [];

                    for( const accountBet of accountBets ){
                        let filterAccountBet = { 
                            ...accountBet, 
                            username: (configState.signup_used_fullname == 'true') ? accountBet.account_detail.fullname : `${accountBet.account_detail.firstname} ${accountBet.account_detail.lastname}`
                        };

                        filterAccountBets.push(filterAccountBet);
                    }

                    setData({ total, accountBets: filterAccountBets });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '베팅관리'
                    });
                }

                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);

                modalAlert({
                    component: <>{error.message}</>,
                    title: '베팅관리'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [target, result_type, amount_type, sport, keyword]);

    const handleCancel = (uid: string) => {
        modalConfirm({
            component: <>정말 취소하시겠습니까?</>,
            title: '베팅관리',
            handleConfirm: () => {
                spinnerVisible(true);

                BetAPI.setBetResult('cancel', uid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리가 완료되었습니다.</>,
                            title: '베팅관리'
                        });

                        navigate(0)
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '베팅관리'
                        });
                    }

                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '베팅관리'
                    });
                });
            }
        })
    }

    const handlePicks = (uid: string) => {
        modalForm({
            formId: 'modal-form-picks',
            component: <PicksModal uid_bet={uid} />,
            title: '베팅 상세내역'
        });
    }

    return (
        <div className="contents">
            <SearchForm setSearchForms={setSearchForms} />
            <Card>
                <CardBody>
                    <table className="table">
                        <thead>
                            <tr className="align-middle">
                                <th scope="col">코드</th>
                                <th scope="col">아이디</th>
                                <th scope="col" rowSpan={2}>폴더</th>
                                <th scope="col" rowSpan={2}>금액타입</th>
                                <th scope="col" rowSpan={2}>금액</th>
                                <th scope="col" rowSpan={2}>배당</th>
                                <th scope="col" rowSpan={2}>예상당첨금액</th>
                                <th scope="col" rowSpan={2}>처리</th>
                                <th scope="col">등록일</th>
                            </tr>
                            <tr className="align-middle">
                                <th scope="col">상태</th>
                                <th scope="col">이름</th>
                                <th scope="col">처리일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.accountBets.map(( accountBet: any, key ) => 
                                <React.Fragment key={key}>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <td>{accountBet.uid}</td>
                                        <td>{accountBet.account.userid}</td>
                                        <td rowSpan={2}>
                                            <Button
                                                color="secondary"
                                                type="button"
                                                onClick={(event) => handlePicks(accountBet.uid)}
                                            >
                                                {Util.getNumberFormat(accountBet.picks.length) + ' 폴더'}
                                            </Button>
                                        </td>
                                        <td rowSpan={2}>{accountBet.amount_type_kr}</td>
                                        <td rowSpan={2}>{Util.getNumberFormat(accountBet.amount)}</td>
                                        <td rowSpan={2}>{Number(accountBet.rate).toFixed(2)}</td>
                                        <td rowSpan={2}>{Util.getNumberFormat(accountBet.rate * accountBet.amount)}</td>
                                        <td rowSpan={2}>
                                            {(accountBet.result == 'wait' || accountBet.result == 'error') 
                                            ? 
                                                <Button
                                                    color="danger"
                                                    type="button"
                                                    onClick={(event) => handleCancel(accountBet.uid)}
                                                >
                                                    취소
                                                </Button>
                                            :
                                                <>-</>
                                            }
                                        </td>
                                        <td>{moment(accountBet.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <td>{accountBet.result_kr}</td>
                                        <td>{accountBet.username}</td>
                                        <td>{accountBet.processed_at ? moment(accountBet.processed_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                    </tr>
                                </React.Fragment>
                            )}

                            {data.total <= 0 && 
                                <tr className="align-middle">
                                    <td colSpan={9} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                        total={data.total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default BettingsView