import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormGroup,
    Button,
    InputGroup,
    InputGroupText,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import InputControl from '../common/InputControl';

import axios from "axios";
import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import ConfigAPI from "../../modules/ConfigAPI";
import BetAPI from "../../modules/BetAPI";

import { IConfig } from "../../interfaces/Config";
import { IBetConfig, IBetConfigObject, IBetConfigValues } from "../../interfaces/BetConfig";
import Util from "../../modules/Util";
import AmountAPI from "../../modules/AmountAPI";
import { IAmountConfigObject, IAmountConfigValues } from "../../interfaces/Amount";

const SettingAmountForm = () => {
    const [ levels, setLevels ] = useState<any>([]);
    const [ targetLevel, setTargetLevel] = useState<any>(null);

    const [ configValues, setConfigValues ] = useState<IConfig>();
    const [ configObjects, setConfigObjects ] = useState<IBetConfigObject>();

    const { modalAlert } = useModal();
    const { spinnerVisible } = useSpinner();

    const { watch, handleSubmit, control, reset, setValue, formState: { errors, isSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: useMemo(() => configValues, [configValues])
    });

    useEffect(() => {
        ConfigAPI.getLevels().then(( res ) => {
            if(res.data.success){
                const { total, levels } = res.data.message;

                if(total > 0){
                    setLevels(levels);
                    setTargetLevel(levels[0]);
                }else{
                    modalAlert({
                        component: <>사이트 레벨 설정이 초기화되지 않았습니다.</>,
                        title: '머니 설정'
                    });
                }
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '머니 설정'
            });
        });
    }, [])

    useEffect(() => {
        if(targetLevel){
            spinnerVisible(true);
            
            AmountAPI.getConfigsByTarget(targetLevel.luid).then((res) => {
                if(res.data.success){
                    const { total: amountConfigsTotal, amountConfigs } = res.data.message;

                    console.log('amountConfigs', amountConfigs)
    
                    if(amountConfigsTotal > 0){
    
                        let filterConfigObjects: IAmountConfigObject = {};
                        let filterConfigValues: IAmountConfigValues = {};
    
                        for( const amountConfig of amountConfigs ){
                            const configKey = amountConfig.key_config;
    
                            const levelLidx = Number(amountConfig.idx_level);
                            const levelName = amountConfig.name_level;
    
                            const inputName = amountConfig.uid;
                            const inputValue = amountConfig.value;
    
                            filterConfigObjects[`lv${levelLidx + 1}_${configKey}`] = {
                                configKey,
                                levelLidx,
                                inputName
                            };
    
                            switch(amountConfig.value_type){
                                case 'string':
                                    filterConfigValues[`${inputName}`] = String(inputValue);
                                    break;
                                case 'number':
                                    filterConfigValues[`${inputName}`] = Number(inputValue);
                                    break;
                                case 'boolean':
                                    filterConfigValues[`${inputName}`] = Util.getBoolean(inputValue);
                                    break;
                            }
                        }
    
                        setConfigObjects((configObjects) => ({ ...configObjects, ...filterConfigObjects }));
                        setConfigValues((configValues) => ({ ...configValues, ...filterConfigValues }));
    
                        reset(filterConfigValues);
                    }
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '머니 설정'
                    });
                }

                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);

                modalAlert({
                    component: <>{error.message}</>,
                    title: '머니 설정'
                });
            });

            
        }
    }, [targetLevel]);

    const handleOnSubmit: SubmitHandler<any> = (data) => {
        console.log('data', data)

        spinnerVisible(true);

        AmountAPI.setConfigs(data).then((res) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리가 완료되었습니다.</>,
                    title: '머니 설정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '머니 설정'
                });
            }

            spinnerVisible(false);
        }).catch(( error ) => {
            spinnerVisible(false);

            modalAlert({
                component: <>{error.message}</>,
                title: '머니 설정'
            });
        });
    }
    
    return (
        <div className="settings-form">
            {configValues && 
                <Form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Row>
                        <Col>
                            {targetLevel != null && 
                                <>
                                    <Row>
                                        <Col>
                                            <Card className="card-form">
                                                <CardHeader>
                                                    <CardTitle tag="h5">레벨별 기본설정</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Nav tabs fill className="mb-3">
                                                        {levels.map((level: any, key: number) => {
                                                            return (
                                                                <NavItem key={`levels-${key}`}>
                                                                    <NavLink className="cursor-pointer" onClick={(event) => setTargetLevel(level)} active={level.lidx == targetLevel.lidx}>
                                                                        <span>{level.name}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                            )
                                                        })}
                                                    </Nav>

                                                    <table className="table">
                                                        <thead>
                                                            <tr className="align-middle">
                                                                <th>입금액 단위</th>
                                                                <th>최소 입금액</th>
                                                                <th>최대 입금액</th>
                                                                <th rowSpan={2}>일일 최대 출금액</th>
                                                            </tr>
                                                            <tr className="align-middle">
                                                                <th>출금액 단위</th>
                                                                <th>최소 출금액</th>
                                                                <th>최대 출금액</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="align-middle">
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_unit`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_unit`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_min`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td rowSpan={2}>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_day_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_day_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle">
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_unit`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_unit`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_min`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className="card-form">
                                                <CardHeader>
                                                    <CardTitle tag="h5">레벨별 보너스설정</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <table className="table">
                                                        <colgroup>
                                                            <col width="150px"/>
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">항목</th>
                                                                <th scope="col">설정</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">보너스 최소 전환액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_bns_transform_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_bns_transform_min`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">충전보너스 사용</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_bns_used_withdraw`]) && 
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_bns_used_withdraw`].inputName}`}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">최대 충전보너스</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">첫충 보너스</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_first_rate`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_first_rate`].inputName}`}
                                                                                step="1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">가입 첫충 보너스</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_first_signin_rate`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_first_signin_rate`].inputName}`}
                                                                                step="1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">매충 보너스</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_every_rate`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_bns_withdraw_every_rate`].inputName}`}
                                                                                step="1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            {/*
                                            <Card className="card-form">
                                                <CardHeader>
                                                    <CardTitle tag="h5">레벨별 기본설정</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <table className="table">
                                                        <colgroup>
                                                            <col width="150px"/>
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">항목</th>
                                                                <th scope="col">설정</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">입금액 단위</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_unit`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_unit`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">최소 입금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_min`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">최대 입금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_deposit_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">출금액 단위</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_unit`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_unit`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">최소 출금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_min`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">최대 출금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">일일 최대 출금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_day_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`lv${targetLevel.lidx + 1}_amt_withdraw_day_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                            */}
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center py-2">
                            <Button
                                className="btn-round"
                                color="primary"
                                type="submit"
                            >
                                설정 수정
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
        </div>
    );
}

export default SettingAmountForm