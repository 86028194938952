import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col, Nav, NavItem } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import BetAPI from "../../modules/BetAPI";
import APISocket from "../../modules/APISocket";

import InplayItem from "./InplayItem";

import { IInplayItem, IInplayItemSport } from "../../interfaces/Inplay";
import useSpinner from "../../hooks/GlobalSpinner";
import { NavLink } from "react-router-dom";

const InplayView = () => {

    const { spinnerVisible } = useSpinner();
    const { modalAlert, modalConfirm } = useModal();

    const [ updateFlag, setUpdateFlag ] = useState<boolean>(false);

    const [ inplaySports, setInplaySports ] = useState<IInplayItemSport[]>([]);
    const [ inplayEvents, setInplayEvents ] = useState<IInplayItem[]>([]);

    const [ targetSport, setTargetSport ] = useState<IInplayItemSport | null>(null);
    const [ targetEvents, setTargetEvents ] = useState<IInplayItem[]>([]);

    /*
    useEffect(() => {
        const filterEvents: any[] = [
            {timer: '00:00', score_home: '1', score_away: '0', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '1', score_away: '1', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '3', score_away: '2', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '2', score_away: '1', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '1', score_away: '1', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '2', score_away: '0', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '0', score_away: '3', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '1', score_away: '2', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '2', score_away: '2', home: 'Event Team 1', away: 'Event Team 2'},
        ];

        setInplayEvents(filterEvents)
    }, [])
    */

    useEffect(() => {
        spinnerVisible(true);

        const targetSocket = APISocket.getIo(process.env.REACT_APP_API_SOCKET_URL + '/inplay');

        targetSocket.connect();

        targetSocket.on('connect', () => {
            console.log('Connected APISocket.');

            targetSocket.emit('/request', { type: 'inplay' });
        });

        targetSocket.on('message', (response) => {
            switch(response.type){
                case 'inplay':
                    if(updateFlag){
                        console.log('Update yet.')
                    }else{
                        setUpdateFlag(true);
                        spinnerVisible(false);
                        
                        const inplayData = response.data;

                        if(inplayData){
                            const sportKeys = Object.keys(inplayData);

                            if(sportKeys.length <= 0){
                                // Nothing Data
                            }else{
                                let filterSports: IInplayItemSport[] = [];
                                let filterEvents: IInplayItem[] = [];

                                let forCount = 0;
                                for( const sportKey of sportKeys ){
                                    for( const inplayKey in inplayData[sportKey] ){
                                        //if(forCount >= 50) break;

                                        const inplay = inplayData[sportKey][inplayKey];

                                        const home = (inplay.team_home.name_kr) ? inplay.team_home.name_kr : inplay.team_home.name;
                                        const away = (inplay.team_away.name_kr) ? inplay.team_away.name_kr : inplay.team_away.name;

                                        if(!filterSports.find((filterSport) => filterSport.uid == inplay.sport.uid))
                                            filterSports.push(inplay.sport);

                                        filterEvents.push({
                                            uid: inplay.uid,
                                            key: inplay.key,
                                            sport: inplay.sport,
                                            timer: {
                                                timer_updated: inplay.timer_updated,
                                                timer_ticking: inplay.timer_ticking,
                                                timer_minutes: inplay.timer_minutes,
                                                timer_seconds: inplay.timer_seconds,
                                            },
                                            score: inplay.score,
                                            home, 
                                            away,
                                            images: [ inplay.team_home.image_id, inplay.team_away.image_id ]
                                        });

                                        forCount++;
                                    }
                                }

                                setInplaySports(() => [ ...filterSports ]);
                                setInplayEvents(() => [ ...filterEvents ]);
                            }
                        }
                    }

                    //targetSocket.disconnect();
                    
                    break;
                case 'error':
                    console.error(response.message);
                    break;
            }
        });

        targetSocket.on('disconnect', () => {
            console.log('Disonnected APISocket.')
        });

        targetSocket.on('connect_error', (error) => {
            console.log(error.message);
        })

        return() => {
            targetSocket.disconnect();
        }
    }, []);

    useEffect(() => {
        if(inplaySports.length > 0 && !targetSport) setTargetSport({ ...inplaySports[0] });
    }, [inplaySports])

    useEffect(() => {
        if(!targetSport){
            // Nothing
        }else{
            let filterEvents: IInplayItem[] = [];

            let forCount = 0;
            for( const inplayKey in inplayEvents ){
                //if(forCount >= 50) break;

                const inplay = inplayEvents[inplayKey];

                if(inplay.sport.uid !== targetSport.uid){
                    continue;
                }else{
                    filterEvents.push(inplay);

                    forCount++;
                }
            }

            setTargetEvents([ ...filterEvents ]);
        }

        setUpdateFlag(false);
    }, [targetSport, inplayEvents]);

    const toggleSports = (sport: IInplayItemSport) => {
        if(targetSport && targetSport.uid !== sport.uid) setTargetSport({ ...sport });
    }

    return (
        <div className="contents">
            <div className="inplays">
                <Row>
                    <Col>
                        <ul>
                            {
                            targetSport 
                                ? inplaySports.map(( inplaySport, key ) => 
                                    <li 
                                        key={key} 
                                        className={`${ inplaySport.uid === targetSport.uid ? 'active' : '' }`}
                                        onClick={() => { toggleSports(inplaySport); }}
                                    >
                                        {inplaySport.name}
                                    </li>
                                )
                                : <li>
                                    Empty sports.
                                </li>
                            }
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            {targetEvents.length}
                        </Row>
                        <Row>
                            {targetEvents.map(( targetEvent, key ) => 
                                <InplayItem key={key} item={targetEvent} />
                            )}

                            {targetEvents.length <= 0 && 
                                <Col>
                                    <Card className="card-form">
                                        <CardBody>
                                            <Row>
                                                <Col className="text-center py-5">
                                                    Inplays not found.
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default InplayView
