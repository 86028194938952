import { AxiosPromise } from "axios";
import API from "./API";

import { IBetConfig, IParamBetConfig, IParamSettlementRequest } from "../interfaces/BetConfig";
import { IParamAgent } from "../interfaces/Agent";

const BetAPI = {
    getTimeStatus: (value: number) => {
        let result = '';

        switch(value){
            case 0:
                result = '시작 전';
                break;
            case 1:
                result = '경기중';
                break;
            case 2:
                result = '수정 예정';
                break;
            case 3:
                result = '경기종료';
                break;
            case 4:
                result = '일정연기';
                break;
            case 5:
                result = '경기취소';
                break;
            case 6:
                result = '부전승';
                break;
            case 7:
                result = '경기중단';
                break;
            case 8:
                result = '경기포기';
                break;
            case 9:
                result = '경기기권';
                break;
            case 10:
                result = '경기정지';
                break;
            case 11:
                result = 'FA가 결정';
                break;
            case 99:
                result = '경기삭제';
                break;
        }
    
        return result;
    },
    /*
    getConfigs: (target: string, uid_level: string = ''): AxiosPromise => {
        let _query = (!uid_level) ? '' : `&uid_level=${uid_level}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V1_CONFIGS_URL + `/${target}?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    */
    getConfigs: (target: string, target_config: string, uid_level: string = ''): AxiosPromise => {
        let _query = `target_config=${target_config}`;
        _query += (!uid_level) ? '' : `&uid_level=${uid_level}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_CONFIGS_URL + `/${target}?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setConfigs: (params: IParamBetConfig): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V1_CONFIGS_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getConfigLeagues: (target: string, page: number = 1, limit: number = 10, sport: string, keyword: string = ''): AxiosPromise => {
        let _query = (page <= 1) ? 'page=1' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (sport) ? `&sport=${sport}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_CONFIG_URL + `/${target}/leagues?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setConfigLeague: (target: string, uid_league: string, key: string, value: string): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V2_CONFIG_URL + `/${target}/league/${uid_league}`,
            data: { key, value }
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getConfigEvents: (target: string, page: number = 1, limit: number = 10, sport: string, status: number = 0, keyword: string = ''): AxiosPromise => {
        let _query = (page <= 1) ? 'page=1' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (sport) ? `&sport=${sport}` : '';
        _query += (status >= 0) ? `&status=${status}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_CONFIG_URL + `/${target}/events?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getConfigMarkets: (target: string, sport: string, keyword: string = ''): AxiosPromise => {
        let _query = (sport) ? `&sport=${sport}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_CONFIG_URL + `/${target}/markets?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getConfigMarketCombines: (target: string, sport: string, keyword: string = ''): AxiosPromise => {
        let _query = (sport) ? `&sport=${sport}` : '';
        
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_CONFIG_URL + `/${target}/market_combines?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setConfigEvent: (target: string, uid_event: string, key: string, value: string): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V1_CONFIG_URL + `/${target}/${uid_event}`,
            data: { key, value }
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setConfigMarket: (params: IBetConfig | IBetConfig[]): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V1_CONFIG_URL + `/market`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setConfigMarketCombines: (params: IBetConfig): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V1_CONFIG_URL + `/market_combines`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    deleteConfigMarketCombine: (uid_target: string): AxiosPromise => {
        return API({
            method: 'delete',
            url: process.env.REACT_APP_API_BET_V1_CONFIG_URL + `/market_combine`,
            data: { uid_target }
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    /*
    getSports: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_SPORTS_URL,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    */
    getSports: (target: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_URL + `/${target}/sports`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getEventResult: (target:string, uid_sport: string, uid_event: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_URL + `/${target}/result/${uid_sport}/${uid_event}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getBets: (target: string, page: number = 1, limit: number = 10, result_type: string, amount_type: string, sport: string, keyword: string = ''): AxiosPromise => {
        let _query = (page <= 1) ? 'page=1' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (result_type) ? `&result_type=${result_type}` : '';
        _query += (amount_type) ? `&amount_type=${amount_type}` : '';
        _query += (sport) ? `&sport=${sport}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_URL + `/${target}/bets?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getBetPicks: (page: number = 1, limit: number = 10, uid_bet: string): AxiosPromise => {
        let _query = (page <= 1) ? 'page=1' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_URL + `/bet/${uid_bet}/picks?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setAgent: (params: IParamAgent): AxiosPromise => {
        const { uuid, ...filterParams } = params;

        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V2_CONFIG_URL + `/agent/${uuid}`,
            data: filterParams
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    moveAgent: (uuid: string, userid_move_parent: string): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V2_URL + `/agent/move`,
            data: {
                uuid,
                userid_move_parent
            }
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    moveChildren: (uuid: string, userid_move_parent: string): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V2_URL + `/children/move`,
            data: {
                uuid,
                userid_move_parent
            }
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getChildren: (uuid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_URL + `/${uuid}/children`
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getAgents: (keyword: string = ''): AxiosPromise => {
        let _query = (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_CONFIG_URL + `/agents?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getSettlements: (page: number = 1, limit: number = 10, category: string = '', keyword: string = ''): AxiosPromise => {
        let _query = (page <= 1) ? 'page=1' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (category) ? `&category=${category}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BET_V2_URL + `/settlements?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setBetResult: (result_type: string, uid_bet: string): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_BET_V2_URL + `/bet/${uid_bet}/${result_type}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    requestSettlementDeposit: (params: IParamSettlementRequest): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_SETTLEMENT_URL + '/deposit',
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    requestSettlementWithdraw: (params: IParamSettlementRequest): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_SETTLEMENT_URL + '/withdraw',
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
}

export default BetAPI;
