export type TConfig = {
    [key: string]: string;
};

export type TRole = {
    idx: number;
    role_key: string;
    role_name: string;
};

export const ROLES_KEY_DEFAULT = ['deleted', 'guest', 'blocked', 'sleeper', 'waiter', 'member', 'manager', 'admin', 'root'] as const;
export type TRolesKeyTuple = typeof ROLES_KEY_DEFAULT;
export type TRoleKey = TRolesKeyTuple[number];