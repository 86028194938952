//import { pathToRegexp } from 'path-to-regexp';
import { IMultiRoute } from './interfaces/MultiRoutes';

import SignXLayout from './components/layouts/SignX';
import DashboardLayout from './components/layouts/Dashboard';

import Sign from './components/sign/SignView';
import Main from './components/main/MainView';
import Application from './components/application/ApplicationView';
import Applications from './components/applications/ApplicationsView';
import Accounts from './components/accounts/AccountsView';
import Account from './components/account/AccountView';
import SettingBasics from './components/settings/SettingBasicsView';
import SettingRoles from './components/settings/SettingRolesView';
import SettingPermissions from './components/settings/SettingPermissionsView';
import Popups from './components/popups/PopupsView';
import Memos from './components/memos/MemosView';
import Boards from './components/boards/BoardsView';
import Posts from './components/posts/PostsView';
import SettingNavigations from './components/settings/SettingNavigationsView';
import Inplay from './components/inplay/InplayView';
import InplayEvent from './components/inplay/InplayEventView';
import Upcoming from './components/upcoming/UpcomingView';

import Amounts from './components/amounts/AmountsView';
import AmountsRequestAgent from './components/amounts/AmountsRequestAgentView';
import AmountsRequestUser from './components/amounts/AmountsRequestUserView';
import SettingAmount from './components/amounts/SettingAmountView';
import SettingLevels from './components/settings/SettingLevelsView';

import SettingInplay from './components/sports/SettingInplayView';
import SettingUpcoming from './components/sports/SettingUpcomingView';
import SettingLeagues from './components/sports/SettingLeaguesView';
import SettingEvents from './components/sports/SettingEventsView';
import SettingMarkets from './components/sports/SettingMarketsView';
import SettingMarketCombines from './components/sports/SettingMarketCombinesView';

import Bettings from './components/bettings/BettingsView';
import Agents from './components/agents/AgentsView';
import Settlements from './components/settlements/SettlementsView';
import AccountsOnline from './components/accounts/AccountsOnlineView';

const routes: IMultiRoute[] = [
    {
        path: '/signx',
        access: 'public',
        name: 'SignX',
        icon: '',
        layout: SignXLayout,
        layoutName: 'SignX',
        layoutPath: '',
        component: <Sign />
    },
    {
        path: '/',
        access: 'private',
        name: '메인',
        icon: 'fa fa-home',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Main />
    },
    {
        path: '/settings',
        access: 'private',
        name: '기본 설정',
        icon: 'fa fa-home',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <SettingBasics />,
        subRoutes: [
            {
                path: '/settings',
                access: 'private',
                name: '기본 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingBasics />,
            },
            {
                path: '/settings/popups',
                access: 'private',
                name: '팝업 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Popups />,
            }
        ]
    },
    {
        path: '/accounts',
        access: 'private',
        name: '회원 목록',
        icon: 'fa fa-user',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Accounts />,
        subRoutes: [
            {
                path: '/account',
                access: 'private',
                name: '회원 추가/수정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Account />,
                childRoutes: [
                    {
                        path: ':uuid',
                        name: '회원 추가/수정',
                        component: undefined
                    }
                ],
            },
            {
                path: '/accounts',
                access: 'private',
                name: '회원 목록',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Accounts />
            },
            {
                path: '/accounts/online',
                access: 'private',
                name: '회원 접속현황',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <AccountsOnline />
            },
            {
                path: '/settings/levels',
                access: 'private',
                name: '회원레벨 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingLevels />,
            }
        ]
    },
    {
        path: '/agents',
        access: 'private',
        name: '총판관리',
        icon: 'fa fa-user',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Agents />,
        subRoutes: [
            {
                path: '/agents',
                access: 'private',
                name: '총판 목록',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Agents />
            }
        ]
    },
    {
        path: '/settlements',
        access: 'private',
        name: '정산관리',
        icon: 'fa fa-user',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Settlements />,
        subRoutes: [
            {
                path: '/settlements',
                access: 'private',
                name: '정산 목록',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Settlements />
            }
        ]
    },
    {
        path: '/amounts',
        access: 'private',
        name: '통합 머니 내역',
        icon: 'fas fa-list-alt',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Amounts />,
        subRoutes: [
            {
                path: '/settings/amount',
                access: 'private',
                name: '머니 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingAmount />
            },
            {
                path: '/amounts',
                access: 'private',
                name: '통합 머니 내역',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Amounts />
            },
            {
                path: '/amounts/request-agent',
                access: 'private',
                name: '총판 입/출금 내역',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <AmountsRequestAgent />
            },
            {
                path: '/amounts/request-user',
                access: 'private',
                name: '회원 입/출금 내역',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <AmountsRequestUser />
            }
        ]
    },
    {
        path: '/memos',
        access: 'private',
        name: '쪽지관리',
        icon: 'fas fa-list-alt',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Memos />,
        subRoutes: [
            {
                path: '/memos',
                access: 'private',
                name: '쪽지 목록',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Memos />
            }
        ]
    },
    {
        path: '/boards',
        access: 'private',
        name: '게시판 목록',
        icon: 'fas fa-list-alt',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Boards />,
        subRoutes: [
            {
                path: '/boards',
                access: 'private',
                name: '게시판 목록',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Boards />
            },
            {
                path: '/posts',
                access: 'private',
                name: '게시글 목록',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Posts />,
                childRoutes: [
                    {
                        path: ':key_board',
                        name: '게시글 목록',
                        component: undefined
                    }
                ]
            }
        ]
    },
    {
        path: '/settings/prematch',
        access: 'private',
        name: '프리매치 설정',
        icon: 'fa fa-user',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <SettingUpcoming />,
        subRoutes: [            
            {
                path: '/settings/prematch',
                access: 'private',
                name: '프리매치 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingUpcoming />
            },
            {
                path: '/settings/inplay',
                access: 'private',
                name: '인플레이 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingInplay />
            },
            {
                path: '/settings/leagues',
                access: 'private',
                name: '리그 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingLeagues />
            },
            {
                path: '/settings/events',
                access: 'private',
                name: '경기설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingEvents />
            },
            {
                path: '/settings/markets',
                access: 'private',
                name: '마켓 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingMarkets />
            },
            {
                path: '/settings/market-combines',
                access: 'private',
                name: '마켓조합 설정',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <SettingMarketCombines />
            }
        ]
    },
    {
        path: '/bettings',
        access: 'private',
        name: '베팅관리',
        icon: 'fa fa-user',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Bettings />,
        subRoutes: [            
            {
                path: '/bettings',
                access: 'private',
                name: '베팅관리',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Bettings />
            },
        ]
    },
    {
        path: '/upcoming',
        access: 'private',
        name: 'Upcoming',
        icon: 'fa fa-user',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Upcoming />,
        subRoutes: [
            {
                path: '/upcoming',
                access: 'private',
                name: 'Upcoming',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Upcoming />
            }
        ]
    },
    {
        path: '/inplay',
        access: 'private',
        name: 'Inplay',
        icon: 'fa fa-user',
        layout: DashboardLayout,
        layoutName: 'Dashboard',
        layoutPath: '',
        component: <Inplay />,
        subRoutes: [
            {
                path: '/inplay',
                access: 'private',
                name: 'Inplay',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <Inplay />,
                childRoutes: [
                    {
                        path: ':uid',
                        name: 'Inplay Event',
                        component: undefined
                    }
                ],
            },
            {
                path: '/inplay-event',
                access: 'private',
                name: 'Inplay Event',
                icon: '',
                layout: DashboardLayout,
                layoutName: 'Dashboard',
                layoutPath: '',
                component: <InplayEvent />,
                childRoutes: [
                    {
                        path: ':uid_sport/:uid',
                        name: 'Inplay Event',
                        component: undefined
                    }
                ],
            }
        ]
    }
];

export default routes
