import { TAuth } from "../../types/Auth";

const SET_AUTH = 'auth/SET_AUTH' as const;

export const setAuth = (auth: TAuth) => ({ type: SET_AUTH, auth });

type AuthAction =
    | ReturnType<typeof setAuth>;

const defaultState: TAuth = {
    modifire: 'PUBLIC',
    uuid: '',
    role: null,
    level: null,
    userid: '',
    name: '',
    amount: 0,
    amount_bonus: 0,
    amount_point: 0,
    ip: '',
    expires: 0
}

const authReducer = (state: TAuth = defaultState, action: AuthAction): TAuth => {
    switch(action.type){
        case SET_AUTH:
            return {
                ...state,
                ...action.auth
            }
        default:
            return state;
    }
}

export default authReducer;