const Util = {
    getBoolean: (value: any): boolean => {
        let result = false;

        const valueType = typeof value;

        switch(valueType){
            case 'boolean':
                result = value;
                break;
            case 'string':
                value = value.toLowerCase();
                result = value === 'true';
                break;
            case 'number':
                result = value === 1;
                break;
            default:
                result = false;
        }

        return result;
    },
    removeSpace: (value: string) => {
        const regexp = /[\s\uFEFF\xA0]+/gi;
    
        return value.replace(regexp, '');
    },
    getNumberFormat: (value: number, maxDigits: number = 3) => {
        return value.toLocaleString(undefined, {maximumFractionDigits: maxDigits});
    },
    getTimeStatus: (value: number) => {
        let result = '';
    
        switch(value){
            case 0:
                result = '시작 전';
                break;
            case 1:
                result = '경기중';
                break;
            case 2:
                result = '수정 예정';
                break;
            case 3:
                result = '경기종료';
                break;
            case 4:
                result = '일정연기';
                break;
            case 5:
                result = '경기취소';
                break;
            case 6:
                result = '부전승';
                break;
            case 7:
                result = '경기중단';
                break;
            case 8:
                result = '경기포기';
                break;
            case 9:
                result = '경기기권';
                break;
            case 10:
                result = '경기정지';
                break;
            case 11:
                result = 'FA가 결정';
                break;
            case 99:
                result = '경기삭제';
                break;
        }
    
        return result;
    }
}

export default Util;