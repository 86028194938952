import React, { useRef, useState, useEffect } from "react";

import { IInplayItemTimer, IInplayItemTimerProps } from "../../interfaces/Inplay";

import moment from "moment-timezone";

const extractTimer = (timer: IInplayItemTimer) => {
    let TIMER = '00:00';

    if(/^[0-9]{14}$/.test(timer.timer_updated)){
        var NOW_DATE = moment.utc();
        var NOW_DATE_TIME = NOW_DATE.unix();

        var TU_DATE = moment.utc(timer.timer_updated, 'YYYYMMDDHHmmss');
        var TU_DATE_TIME = TU_DATE.unix();

        var TIMESTAMP = 0;
        var D = 0;
        var H = 0;
        var M = 0;
        var S = 0;

        if (Number(timer.timer_ticking) > 0) {  // when playing
            //console.log('playing');

            TIMESTAMP = (NOW_DATE_TIME - TU_DATE_TIME) + (Number(timer.timer_minutes) * 60) + Number(timer.timer_seconds);

            D = Math.floor(TIMESTAMP / 86400);
            H = Math.floor((TIMESTAMP - (D * 86400)) / 3600 % 3600);
            M = Math.floor((TIMESTAMP - (H * 3600)) / 60 % 60);
            S = Math.floor((TIMESTAMP - (M * 60)) % 60);

            H = (H + 1) % 24;
            M = M + 60;
        } else {
            //console.log('stop');

            TIMESTAMP = (Number(timer.timer_minutes) * 60) + Number(timer.timer_seconds);

            D = Math.floor(TIMESTAMP / 86400);
            H = Math.floor((TIMESTAMP - (D * 86400)) / 3600 % 3600);
            M = Math.floor((TIMESTAMP - (H * 3600)) / 60 % 60);
            S = Math.floor((TIMESTAMP - (M * 60)) % 60);

            M = M + (H * 60);
        }

        //TIMER = D + ' / ' + H + ':' + M + ':' + S;
        TIMER = ((M < 10) ? '0' + M : M) + ':' + ((S < 10) ? '0' + S : S);
    }

    return TIMER;
}

const InplayItemTimer = (props: IInplayItemTimerProps) => {
    const { timer } = props;

    return (
        <div className="timer">{extractTimer(timer)}</div>
    )
}

export default InplayItemTimer
