import React, { useEffect, useState } from "react";
import { Row, Col, Nav, Button, Label, Input } from "reactstrap";
import { IPaginationProps } from "../../interfaces/Pagination";

const Pagination = (props: IPaginationProps) => {
    const { total, limit, page, setPage, setLimit, paginateType } = props;
    const [ block, setBlock ] = useState<number>(1);

    const limitPageBlock = 10;

    const totalPage = Math.ceil(total / limit); // 전체 데이터 수 / 페이지 당 데이터 수
    const totalBlock = Math.ceil(totalPage / limitPageBlock); // 전체 페이지 수 / 블럭 당 페이지 수

    let startPageNumber = (block - 1) * limitPageBlock + 1 // (현재 블럭 번호 - 1) * 블럭 당 페이지 수 + 1 */
    startPageNumber = (startPageNumber <= 0) ? 1 : startPageNumber;

    let endPageNumber = block * limitPageBlock; // 현재 블럭 번호 * 블럭 당 페이지 수
    endPageNumber = (endPageNumber > totalPage) ? totalPage : endPageNumber;

    let pageNumbers = (endPageNumber - startPageNumber) + 1;
    pageNumbers = (pageNumbers) ? pageNumbers : 1;

    useEffect(() => {
        setBlock(Math.ceil(page / limitPageBlock)); // 현재 페이지 번호 / 블럭 당 페이지 수
    }, []);

    const prevPage = () => {
        if(page > 1) setPage(page - 1)
    }

    const nextPage = () => {
        if(page < total) setPage(page + 1)
    }

    const prevBlock = () => {
        if(block > 1){
            const _block = block - 1;

            setBlock(_block);
            setPage(_block * limitPageBlock + 1);
        }
    }

    const nextBlock = () => {
        if(block < totalBlock){
            const _block = block + 1;

            setBlock(_block);
            setPage(_block * limitPageBlock + 1);
        }
    }

    return (
        <div className="pagination-wrapper">
            <Row>
                <Col className={`${paginateType != 'right' ? 'col-3' : 'col-4'}`}>
                    <Input
                        className="d-inline-block w-auto mx-2"
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={(event) => { setLimit(parseInt(event.target.value)) }}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </Input>
                    <span>개씩 보기</span>
                </Col>
                <Col className={`d-flex align-items-center ${paginateType != 'right' ? 'justify-content-center' : 'justify-content-end'}`}>
                    <Nav>
                        <li>
                            <Button onClick={prevBlock} disabled={block <= 1}><i className="fa fa-angle-double-left"></i></Button>
                        </li>
                        <li>
                            <Button onClick={prevPage} disabled={page <= 1}><i className="fa fa-angle-left"></i></Button>
                        </li>
                        
                        {Array(pageNumbers).fill('').map((_, i) => {
                            let _pageNumber = startPageNumber + i;

                            return (
                                <li key={i}>
                                    <Button onClick={() => {setPage(_pageNumber)}} active={_pageNumber == page}>{_pageNumber}</Button>
                                </li>
                            )
                        })}

                        <li>
                            <Button onClick={nextPage} disabled={page >= totalPage}><i className="fa fa-angle-right"></i></Button>
                        </li>
                        <li>
                            <Button onClick={nextBlock} disabled={block >= totalBlock}><i className="fa fa-angle-double-right"></i></Button>
                        </li>
                    </Nav>
                </Col>
                {paginateType != 'right' &&
                    <Col className="col-3"></Col>
                }
            </Row>
        </div>
    );
}

export default Pagination