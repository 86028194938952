import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardTitle, 
    CardBody, 
    Form, 
    FormGroup, 
    Button, 
    InputGroup, 
    InputGroupText, 
    Label 
} from 'reactstrap';

import moment from "moment";

import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link } from "react-router-dom";

import Util from "../../modules/Util";
import ConfigAPI from "../../modules/ConfigAPI";
import AccountAPI from "../../modules/AccountAPI";
import AccountsAPI from "../../modules/AccountsAPI";

import SearchForm from './SearchForm';
import InputControl from '../common/InputControl';
import { IAccount } from "../../interfaces/Account";
import { ITreeItem, TTreeItems } from "../../interfaces/Tree";

import { IAgent } from "../../interfaces/Agent";
import BetAPI from "../../modules/BetAPI";

const SettlementsView = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);
    const authState = useSelector((state: RootState) => state.authReducer)

    const { modalAlert, modalConfirm } = useModal();

    const [ targetPassbook, setTargetPassbook ] = useState<any>(null)
    const [ settlementsData, setSettlementsData ] = useState({
        total: 0,
        settlements: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { category, keyword }, setSearchForms ] = useState({
        category: '',
        keyword: ''
    });

    const agentTypeOptions = [
        { value: 'online', label: '온라인총판' },
        { value: 'offline', label: '오프라인총판' }
    ]

    const agentSettlementTypeOptions = [
        { value: 'designated_day', label: '지정일' },
        { value: 'designated_cycle', label: '지정일주기' },
        { value: 'end_month', label: '매 월말' }
    ]

    const agentLosingTypeOptions = [
        { value: 'default', label: '개별정산' },
        { value: 'formula_1', label: '(입금 - 출금) x 요율' },
        { value: 'formula_2', label: '(입금 - 출금 - 포인트전환) x 요율' },
        { value: 'formula_3', label: '{(입금 - 출금 - 포인트전환) x 요율} - 하부회원보유머니' },
    ]

    let depositDefaultValues = {
        amount_deposit: 0
    }

    const { watch: depositWatch, handleSubmit: depositHandleSubmit, control: depositControl, reset: depositReset, formState: { errors: depositErrors, isSubmitting: depositIsSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: depositDefaultValues
    });

    let withdrawDefaultValues = {
        amount_withdraw: 0,
        password_withdraw: ''
    }

    const { watch: withdrawWatch, handleSubmit: withdrawHandleSubmit, control: withdrawControl, reset: withdrawReset, formState: { errors: withdrawErrors, isSubmitting: withdrawIsSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: withdrawDefaultValues
    });

    useEffect(() => {
        AccountAPI.getPassbook(authState.uuid).then(( res ) => {
            console.log('res.data', res.data)
            if(res.data.success){
                const accountPassbook = res.data.message;

                console.log(accountPassbook)

                setTargetPassbook(accountPassbook)
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '총판 정산관리'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '총판 정산관리'
            });
        });
    }, [])

    useEffect(() => {
        if(withdrawErrors.password_withdraw){
            modalAlert({
                component: <>{withdrawErrors.password_withdraw.message}</>,
                title: '총판 정산관리'
            });
        }
    }, [withdrawErrors, withdrawIsSubmitting])

    useEffect(() => {
        BetAPI.getSettlements(page, limit, category, keyword).then(( res ) => {
            if(res.data.success){
                const { total, settlements } = res.data.message;

                let filterSettlements: any = [];

                for( const settlement of settlements ){
                    let filterSettlement = { 
                        ...settlement, 
                        username: (configState.signup_used_fullname == 'true') ? settlement.fullname : `${settlement.firstname} ${settlement.lastname}`
                    };

                    filterSettlements.push(filterSettlement);
                }

                setSettlementsData({ total, settlements: filterSettlements });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '총판 정산관리'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '총판 정산관리'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            BetAPI.getSettlements(1, limit, category, keyword).then(( res ) => {
                if(res.data.success){
                    const { total, settlements } = res.data.message;

                    console.log('res.data.message', res.data.message)

                    let filterSettlements: any = [];

                    for( const settlement of settlements ){
                        let filterSettlement = { 
                            ...settlement, 
                            username: (configState.signup_used_fullname == 'true') ? settlement.fullname : `${settlement.firstname} ${settlement.lastname}`
                        };

                        filterSettlements.push(filterSettlement);
                    }

                    setSettlementsData({ total, settlements: filterSettlements });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '총판 정산관리'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '총판 정산관리'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [category, keyword]);

    const depositHandleOnSubmit: SubmitHandler<any> = (data) => {
        let { amount_deposit, ...formParams} = data

        if(amount_deposit <= 0){
            modalAlert({
                component: <>충전신청금액을 정확하게 입력해주세요.</>,
                title: '총판 정산관리'
            })
        }else{
            BetAPI.requestSettlementDeposit({ amount: amount_deposit }).then((res) => {
                if(res.data.success){
                    modalAlert({
                        component: <>충전신청이 완료되었습니다.</>,
                        title: '총판 정산관리'
                    });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '총판 정산관리'
                    });
                }
            }).catch((error) => {
                const res = error.response;
    
                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '총판 정산관리'
                    });
                }
            })
        }
    }

    const withdrawHandleOnSubmit: SubmitHandler<any> = (data) => {
        let { amount_withdraw, password_withdraw, ...formParams} = data

        if(amount_withdraw <= 0){
            modalAlert({
                component: <>환전신청금액을 정확하게 입력해주세요.</>,
                title: '총판 정산관리'
            })
        }else if(password_withdraw.message){
            modalAlert({
                component: <>{password_withdraw.message}</>,
                title: '총판 정산관리'
            })
        }else{
            BetAPI.requestSettlementWithdraw({ amount: amount_withdraw, password: password_withdraw }).then((res) => {
                if(res.data.success){
                    modalAlert({
                        component: <>환전신청이 완료되었습니다.</>,
                        title: '총판 정산관리'
                    });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '총판 정산관리'
                    });
                }
            }).catch((error) => {
                const res = error.response;
    
                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '총판 정산관리'
                    });
                }
            })
        }
    }

    return (
        <div className="contents">
            <Row>
                <Col>
                    <Card className="card-form">
                        <CardHeader>
                            <CardTitle tag="h5">정산금 충전신청</CardTitle>
                        </CardHeader>
                        <CardBody className="position-relative">
                            <Form onSubmit={depositHandleSubmit(depositHandleOnSubmit)}>
                            <Row>
                                <Col>
                                    <p className="text-danger">
                                        ※ 입금계좌가 수시로 변경되기때문에 꼭 "입금계좌문의" 후 신청바랍니다.<br/>
                                        ※ 은행 점검시간동안은 입금확인이 지연 될 수 있습니다.<br/>
                                        ※ 회원정보에 설정된 계좌정보로 입금하셔야 처리됩니다.
                                    </p>
                                    <table className="table">
                                        <colgroup>
                                            <col width="150px"/>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th scope="row">보유금액</th>
                                                <td>
                                                    {Util.getNumberFormat(authState.amount_settlement ? authState.amount_settlement : 0)} 원
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">계좌정보</th>
                                                <td>
                                                    {targetPassbook && 
                                                        <>[{targetPassbook.bankname}] {targetPassbook.number} {targetPassbook.holder}</>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>충전신청금액</Label>
                                    <InputGroup className="mb-3">
                                        <InputControl 
                                            control={depositControl}
                                            name="amount_deposit"
                                            placeholder="충전신청금액"
                                        />
                                        <InputGroupText>원</InputGroupText>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center py-2">
                                    <Button
                                        className="btn-round"
                                        color="primary"
                                        type="submit"
                                    >
                                        충전 신청
                                    </Button>
                                </Col>
                            </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className="card-form">
                        <CardHeader>
                            <CardTitle tag="h5">정산금 환전신청</CardTitle>
                        </CardHeader>
                        <CardBody className="position-relative">

                            <Form onSubmit={withdrawHandleSubmit(withdrawHandleOnSubmit)}>
                            <Row>
                                <Col>
                                    <p className="text-danger">
                                        ※ 은행 점검시간동안은 환전처리가 지연 될 수 있습니다.<br/>
                                        ※ 회원정보에 설정된 출금 비밀번호로 신청할 수 있습니다.<br/>
                                        ※ 회원정보에 설정된 계좌정보로 환전됩니다.
                                    </p>
                                    <table className="table">
                                        <colgroup>
                                            <col width="150px"/>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th scope="row">보유금액</th>
                                                <td>
                                                    {Util.getNumberFormat(authState.amount_settlement ? authState.amount_settlement : 0)} 원
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">계좌정보</th>
                                                <td>
                                                    {targetPassbook && 
                                                        <>[{targetPassbook.bankname}] {targetPassbook.number} {targetPassbook.holder}</>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>환전신청금액</Label>
                                    <InputGroup className="mb-3">
                                        <InputControl 
                                            control={withdrawControl}
                                            name="amount_withdraw"
                                            placeholder="환전신청금액"
                                        />
                                        <InputGroupText>원</InputGroupText>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Label>출금 비밀번호</Label>
                                    <FormGroup>
                                        <InputControl 
                                            control={withdrawControl}
                                            type="password"
                                            name="password_withdraw"
                                            placeholder="출금 비밀번호"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: '출금 비밀번호를 입력해주세요.'
                                                },
                                                pattern: {
                                                    value: /^[0-9]{4,}$/,
                                                    message: '형식에 맞지 않는 출금 비밀번호입니다.'
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center py-2">
                                    <Button
                                        className="btn-round"
                                        color="primary"
                                        type="submit"
                                    >
                                        환전 신청
                                    </Button>
                                </Col>
                            </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <SearchForm setSearchForms={setSearchForms} />
            
            <Row>
                <Col>
                    <Card className="card-form">
                        <CardHeader>
                            <CardTitle tag="h5">적립/정산 내역</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <table className="table">
                                <colgroup>
                                    <col width="60px" />
                                    <col width="120px" />
                                    <col />
                                    <col width="120px" />
                                    <col width="100px" />
                                    <col width="60px" />
                                    <col width="120px" />
                                    <col width="120px" />
                                    <col width="200px" />
                                </colgroup>
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col" rowSpan={2}>상태</th>
                                        {/*<th scope="col">루트총판</th>*/}
                                        <th scope="col">아이디</th>
                                        <th scope="col">관련회원</th>
                                        <th scope="col" rowSpan={2}>금액</th>
                                        
                                        
                                        <th scope="col" colSpan={2}>롤링금(%)</th>
                                        <th scope="col">이전 롤링금</th>
                                        <th scope="col">이후 롤링금</th>
                                        <th scope="col">신청일</th>
                                    </tr>
                                    <tr>
                                        {/*<th scope="col">상위총판</th>*/}
                                        <th scope="col">닉네임</th>
                                        <th scope="col">관련설명</th>

                                        <th scope="col" colSpan={2}>루징금(%)</th>
                                        <th scope="col">이전 루징금</th>
                                        <th scope="col">이후 루징금</th>
                                        <th scope="col">처리일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {settlementsData.settlements.map((settlement: any, key) => 
                                        <React.Fragment key={key}>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <td rowSpan={2}>{settlement.status}</td>
                                                {/*<td>{settlement.userid_agent_root}</td>*/}
                                                <td>{settlement.userid}</td>
                                                <td>{settlement.relation ? settlement.relation.userid : ''}</td>
                                                <td rowSpan={2}>{Util.getNumberFormat(settlement.amount)}</td>
                                                
                                                <td>{Util.getNumberFormat(settlement.amount_rolling)}</td>
                                                <td>{Util.getNumberFormat(settlement.rate_rolling)}%</td>
                                                <td>{Util.getNumberFormat(settlement.amount_rolling_before)}</td>
                                                <td>{Util.getNumberFormat(settlement.amount_rolling_after)}</td>
                                                <td>{settlement.created_at ? moment(settlement.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                            </tr>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                {/*<td>{settlement.userid_agent_parent}</td>*/}
                                                <td>{settlement.nickname}</td>
                                                <td>{settlement.category}</td>
                                                
                                                <td>{Util.getNumberFormat(settlement.amount_losing)}</td>
                                                <td>{Util.getNumberFormat(settlement.rate_losing)}%</td>
                                                <td>{Util.getNumberFormat(settlement.amount_losing_before)}</td>
                                                <td>{Util.getNumberFormat(settlement.amount_losing_after)}</td>
                                                <td>{settlement.created_at ? moment(settlement.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {settlementsData.settlements.length <= 0 && 
                                        <tr>
                                            <td colSpan={9} className="py-5 text-center">자료가 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination 
                                total={settlementsData.total}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SettlementsView