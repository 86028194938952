import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button,
    Label
} from 'reactstrap';

import Util from '../../modules/Util';
import AccountAPI from "../../modules/AccountAPI";
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';
import TextEditor from '../common/Editor/TextEditor';

import { IBoard } from "../../interfaces/Board";
import BoardAPI from "../../modules/BoardAPI";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IParamPost, IPost } from "../../interfaces/Post";
import { hideModal } from "../../redux/reducers/modalReducer";
import BoardPostAPI from "../../modules/BoardPostAPI";

const PostFormModal = (props: any) => {
    const { key_board, uid_post } = props;

    const dispatch = useDispatch();

    const handleHideModal = () => dispatch(hideModal());

    const authState = useSelector((state: RootState) => state.authReducer);
    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert } = useModal();

    let defaultValues: IPost = {
        writer: '',
        subject: '',
        content: '',
    };

    const { watch, handleSubmit, control, reset, register, setValue } = useForm<IPost>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const watchWriter = watch('writer');
    const watchContent = watch('content');

    useEffect(() => {
        if(!key_board){
            modalAlert({
                component: <>Incorrect post.</>,
                title: '게시글 쓰기/수정'
            });
        }else{
            if(uid_post){
                BoardPostAPI.get(key_board, uid_post).then(( res ) => {
                    if(res.data.success){
                        const post = res.data.message;

                        let resetValues: any = {
                            writer: post.writer,
                            subject: post.subject,
                            content: post.content,
                        }

                        reset(resetValues);
                    }else{
                        reset();
    
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '게시글 쓰기/수정'
                        });
                    }
                }).catch((error) => {
                    const res = error.response;
    
                    if(res){
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '게시글 쓰기/수정'
                        });
                    }
                })
            }
        }
    }, []);

    const handleOnSubmit: SubmitHandler<IParamPost> = (formData) => {
        let { ...formParams } = formData;

        console.log('formData', formData)

        if(uid_post){
            BoardPostAPI.update(key_board, uid_post, formParams).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '게시글 쓰기/수정'
                    });
                }else{
                    reset(formParams);

                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시글 쓰기/수정'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시글 쓰기/수정'
                    });
                }
            })
        }else{
            BoardPostAPI.setPost(key_board, formParams).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '게시글 쓰기/수정'
                    });
                }else{
                    reset(formParams);

                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시글 쓰기/수정'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시글 쓰기/수정'
                    });
                }
            })
        }
    }
    
    return (
        <div className="modal-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)} id="modal-form-post">
                <Row>
                    <Col>
                        {uid_post &&
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>작성자</Label>
                                        <div>{watchWriter}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        name="subject"
                                        label="제목"
                                        placeholder="제목"
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {!authState.uuid && 
                            <>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <InputControl
                                                control={control}
                                                name="writer"
                                                label="작성자"
                                                placeholder="작성자"
                                                rules={{
                                                    required: true
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <InputControl
                                                control={control}
                                                type="password"
                                                name="password"
                                                label="비밀번호"
                                                placeholder="**********"
                                                rules={{
                                                    required: true
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <InputControl
                                                control={control}
                                                type="password"
                                                name="passwordConfirm"
                                                label="비밀번호 확인"
                                                placeholder="**********"
                                                rules={{
                                                    required: true
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <Col>
                                <FormGroup>
                                    <TextEditor
                                        data={watchContent}
                                        placeholder="내용을 입력해주세요."
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            //console.log( { event, editor, data } );
                                            setValue('content', data);
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            //console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            //console.log( 'Focus.', editor );
                                        } }
                                        isUpload={true}
                                        uploadType="board"
                                        uploadKey={key_board}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default PostFormModal