import { TStats } from "../../types/Stats";

const SET_SUMMARY_TODAY = 'stats/SET_SUMMARY_TODAY' as const;

export const setSummaryToday = (stats: TStats) => ({ type: SET_SUMMARY_TODAY, stats });

type StatsAction =
    | ReturnType<typeof setSummaryToday>;

const defaultState: TStats = {
    summary: {
        today: {
            amounts: {
                member: {
                    deposit: 0,
                    deposit_count: 0,
                    withdraw: 0,
                    withdraw_count: 0
                },
                agent: {
                    deposit: 0,
                    deposit_count: 0,
                    withdraw: 0,
                    withdraw_count: 0
                },
                total: {
                    deposit: 0,
                    deposit_count: 0,
                    withdraw: 0,
                    withdraw_count: 0
                }
            },
            settlements: {
                deposit_settlement: 0,
                deposit_settlement_count: 0,
                withdraw_settlement: 0,
                withdraw_settlement_count: 0
            },
            accountBets: {
                upcoming: {
                    amount: 0,
                    amount_win: 0,
                    amount_except: 0,
                    count: 0
                },
                inplay: {
                    amount: 0,
                    amount_win: 0,
                    amount_except: 0,
                    count: 0
                },
                total: {
                    amount: 0,
                    amount_win: 0,
                    amount_except: 0,
                    count: 0
                }
            },
            accountWallets: {
                total: {
                    amount: 0,
                    amount_bonus: 0,
                    amount_point: 0,
                    amount_settlement: 0
                }
            },
            accountBetUsers: 0,
            accountJoins: 0,
            accountVisited: 0
        }
    }
}

const statsReducer = (state: TStats = defaultState, action: StatsAction): TStats => {
    switch(action.type){
        case SET_SUMMARY_TODAY:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    today: {
                        ...state.summary.today,
                        ...action.stats
                    }
                }
            }
        default:
            return state;
    }
}

export default statsReducer;