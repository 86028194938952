import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody, Input, Button, Row, Col, Container } from 'reactstrap';

import { IInplayEventItem, IInplayEventItemProps } from "../../interfaces/Inplay";

import InplayItemTimer from "./InplayItemTimer";
import InplayItemScore from "./InplayItemScore";
import { channel } from "diagnostics_channel";

const InplayEventItem = (props: IInplayEventItemProps) => {
    const { item } = props;

    //const [ inplayEventItem, setInplayEventItem ] = useState<IInplayEventItem | null>(null);
    const [ inplayEventItem, setInplayEventItem ] = useState<any | null>(null);

    useEffect(() => {
        setInplayEventItem(item)
    }, [item])

    const renderMarket = (market: any, key: any) => {
        if(market.style_participant === 'dt' || market.style_participant === '_d' || market.style_participant === '_D' || market.style_participant === '_c' || market.style_participant === '_C'){
            return (
                <Col className="market" key={key} data-key={market.topic_id}>
                    <div className="marketName">
                        {(market.name_kr) ? market.name_kr : market.name} / {market.key} / {market.channel} / {market.style_participant} / {market.style}
                    </div>

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </Col>
            )
        }else if(market.style_participant === 'da' || market.style_participant === 'de' || market.style_participant === 'dn' || market.style_participant === 'is'){
            return (
                <Col className="market border-right" key={key} data-key={market.topic_id}>
                    <div className="marketName">
                    {(market.name_kr) ? market.name_kr : market.name} / {market.key} /  {market.channel} / {market.style_participant} / {market.style}
                    </div>

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </Col>
            )
        }else if(market.style_participant === 'ib'){
            return (
                <Col className="market" key={key} data-key={market.topic_id}>
                    <div className="marketName">
                        {(market.name_kr) ? market.name_kr : market.name} / {market.key} /  {market.channel} / {market.style_participant} / {market.style}
                    </div>

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </Col>
            )
        }else{
            return (
                <div className="market" key={key} data-key={market.topic_id}>
                    <div className="marketName">
                        {(market.name_kr) ? market.name_kr : market.name} / {market.key} /  {market.channel} / {market.style_participant} / {market.style}
                    </div>

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }
    }

    const renderOdds = (channel: number, style_participant: string, style: string, odds: any[]) => {
        const _onClick = (e: any) => {
            const key = e.currentTarget.dataset.key;
            const isActive = e.currentTarget.classList.contains('active');

            if(isActive){
                e.currentTarget.classList.remove('active');

                //deleteCartItem(key);
            }else{
                e.currentTarget.classList.add('active');

                //setCartItem(key);
            }
        }

        if(style == '_a' || style == 'ibm'){
            const CN = 12 / channel;

            return (
                <Container>
                    <Row>
                        {
                            odds.map((odd, key) => {
                                return (
                                    <Col className="odd-target odd-cn" key={key} xs={CN} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.topic_id}>
                                        <div className="odd">{odd.name ? odd.name : odd.handicap_formatted} <span>{odd.rate != '0' ? odd.rate : ''}</span></div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            );
        }else if(style == 'ib'){
            const CN = 12 / channel;

            return (
                <Container>
                    <Row>
                        {
                            odds.map((odd, key) => {
                                return (
                                    <Col className="odd-target odd-cn border-right" key={key} xs={CN} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.topic_id}>
                                        <Container>
                                            <Row>
                                                <Col className="odd border-right">{odd.name}</Col>
                                                <Col className="odd"><span>{odd.rate != '0' ? odd.rate : ''}</span></Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            );
        }else if(style =='dc' || style =='dg'){
            return (
                <>
                {
                    odds.map((odd, key) => {
                        return (
                            <div key={key} className="odd-target" {...(!odd.suspended && {onClick: _onClick})} data-key={odd.topic_id}>
                                <div className="odd">{odd.name} <span>{odd.rate != '0' ? odd.rate : '\u00A0'}</span></div>
                            </div>
                        )
                    })
                }
                </>
            );
        }else if(style =='da' || style =='db'){
            return (
                <>
                {
                    odds.map((odd, key) => {
                        return (
                            <div key={key} className="odd-target" {...(!odd.suspended && {onClick: _onClick})} data-key={odd.topic_id}>
                                <div className="odd">{odd.name ? odd.name : odd.handicap_formatted} <span>{odd.rate != '0' ? odd.rate : ''}</span></div>
                            </div>
                        )
                    })
                }
                </>
            );
        }else{
            return (
                <>
                {
                    odds.map((odd, key) => {
                        return (
                            <div key={key} {...(!odd.locked && {onClick: _onClick})} data-key={odd.topic_id}>
                                <div className="odd">{odd.name} <span>{odd.rate != '0' ? odd.rate : '\u00A0'}</span></div>
                            </div>
                        )
                    })
                }
                </>
            );
        }
    }

    return (
        <>
            {inplayEventItem != null &&
                <div className="item">
                    <div>
                        <div className="marketGroupName">{inplayEventItem.name}</div>
                        <div>{inplayEventItem.result_text}</div>
                        <Container>
                            <Row className="marektGroup">
                                {inplayEventItem.markets && inplayEventItem.markets.map((market: any, key: any) => {
                                    return renderMarket(market, key);
                                })}

                                {!inplayEventItem.markets && (() => {
                                    const market = inplayEventItem;

                                    return renderMarket(market, market.uid);
                                })()}
                            </Row>
                        </Container>
                    </div>
                </div>
            }
        </>
    )
}

export default InplayEventItem
