import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody, Input, Button, ListGroup, ListGroupItem, Collapse, Row, Col } from 'reactstrap';

import { TTreeItems } from "../../../interfaces/Tree";

import { setProperty, setPropertyAll } from "./utilities";

import { TreeItem } from "./TreeItem";
import { ITreeItem } from "../../../interfaces/SortableTree";

interface Props {
    items: TTreeItems;
    setItems: React.Dispatch<React.SetStateAction<TTreeItems>>
    depth?: number;
    indentationWidth?: number;
    onTarget?: React.Dispatch<React.SetStateAction<string | null>>
}

const Tree = ({
    items, 
    setItems, 
    depth=0,
    indentationWidth=30,
    onTarget
}: Props) => {

    function handleCollapse(id: string) {
        setItems((items) => 
            setProperty(items, id, "collapsed", (value) => {
                return !value;
            })
        );
    }

    function handleTarget(id: string) {
        if(onTarget){
            setItems((items) => {
                let filterItems: TTreeItems = setPropertyAll(items, "targeted", false);
    
                filterItems = setProperty(filterItems, id, "targeted", (value) => {
                    return !value;
                })
                
                return filterItems;
            });
    
            onTarget(id);
        }
    }

    return (
        <>
            {items.map((item, key) => 
                <React.Fragment key={key}>
                    <TreeItem 
                        item={item} 
                        depth={depth}
                        targeted={item.targeted}
                        collapsed={item.collapsed}
                        onCollapse={handleCollapse}
                        onTarget={handleTarget}
                        indentationWidth={indentationWidth} 
                    />

                    {item.children.length > 0 &&
                        <Collapse isOpen={item.collapsed}>
                            <Tree 
                                key={key}
                                items={item.children} 
                                setItems={setItems}
                                depth={depth+1}
                                indentationWidth={indentationWidth} 
                                onTarget={onTarget}
                            />
                        </Collapse>
                    }
                </React.Fragment>
            )}

            {items.length <= 0 &&
                <Row>
                    <Col className="text-center py-5">
                        하위 총판/회원이 없습니다.
                    </Col>
                </Row>
            }
        </>
    )
}

export default Tree;