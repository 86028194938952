import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

import ConfigAPI from "../../modules/ConfigAPI";

interface ISearchFormProps {
    setSearchForms: Dispatch<SetStateAction<any>>;
}

const SearchForm = (props: ISearchFormProps) => {
    const { setSearchForms } = props;

    const categoryOptions = [
        { value: '', label: '전체' },
        { value: 'deposit', label: '입금' },
        { value: 'withdraw', label: '출금' },
        { value: 'deposit-settlement', label: '정산금 충전' },
        { value: 'withdraw-settlement', label: '정산금 환전' },
        { value: 'upcoming-win', label: '프리매치 적중' },
        { value: 'upcoming-lose', label: '프리매치 미적중' },
        { value: 'upcoming-except', label: '프리매치 적특' },
        { value: 'inplay-win', label: '인플레이 적중' },
        { value: 'inplay-lose', label: '인플레이 미적중'},
        { value: 'inplay-except', label: '인플레이 적특'}
    ]

    return (
        <div className="search-wrapper">
            <Card className="card-form-search">
                <CardHeader>
                    <CardTitle tag="h5">검색</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="2">
                            <Label for="category">카테고리</Label>
                            <Input
                                type="select"
                                name="category"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, category: e.target.value} })}
                            >
                                {categoryOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col>
                            <Label for="keyword">검색어</Label>
                            <Input
                                name="keyword"
                                placeholder="검색어 ( 아이디, 이름 ... )"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, keyword: e.target.value} })}
                            />
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </div>
    )
}

export default SearchForm