import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import { Routes, Route, matchPath } from "react-router-dom";
import { NavLink, Link, useLocation } from "react-router-dom";

import { IMultiRoute } from '../../interfaces/MultiRoutes';

import { 
    Nav, 
} from 'reactstrap';

import logo from "../../assets/img/logo.svg";
import ConfigAPI from '../../modules/ConfigAPI';
import { INavigation } from '../../interfaces/Config';
import { INavItem, TNavItems } from '../../interfaces/Sidebar';
import Util from '../../modules/Util';

const Sidebar = ({ routes, ...props }: { routes: IMultiRoute[], [key: string]: any }) => {
    const location = useLocation();

    const authState = useSelector((state: RootState) => state.authReducer)
    const statsState = useSelector((state: RootState) => state.statsReducer)

    const [navItems, setNavItems] = useState<TNavItems>([])

    const initNavItems = (navigations: INavigation[]) => {
        let filterItems: INavigation[] = [];
        let filterNavigations = [];
        
        for( const navigation of navigations ){
            filterItems.push({ ...navigation, children:[] });

            filterNavigations.push({
                name: navigation.name,
                path: navigation.path,
                visible: navigation.visible,
            })
        }

        filterItems = combineNavItems(filterItems);

        setNavItems(extractNavItems(filterItems))
    }

    const combineNavItems = (items: INavigation[]): INavigation[] => {
        let targets = [...items];
        let result: INavigation[] = [];

        while( targets.length > 0 ){
            const target = targets.shift();

            if(!target){
                break;
            }else{
                const childIndex = targets.findIndex(({ parent_idx }) => parent_idx === target.ridx)

                if(childIndex >= 0){
                    targets.push(target)
                }else{
                    const parentIndex = targets.findIndex(({ ridx }) => ridx === target.parent_idx)

                    if(parentIndex >= 0){
                        targets[parentIndex].children.push(target)
                    }else{
                        result.push(target)
                    }
                }
            }
        }

        return result.sort((a ,b) => (a.ridx - b.ridx));
    }

    const extractNavItems = (items: INavigation[]): TNavItems => {
        let targets = [...items];
        let result: TNavItems = [];

        for( const target of targets ){
            result.push({
                name: target.name,
                path: target.path,
                children: (target.children) ? extractNavItems(target.children) : [],
            })
        }

        return result;
    }

    const renderNavIcon = (name: string, depth: number) => {
        const icons = [
            {name: '메인', class: 'fa fa-home'}, 
            {name: '사이트관리', class: 'fas fa-sitemap'},
            //{name: '설정', class: 'fa fa-cog'},
            {name: '회원관리', class: 'fa fa-user'},
            {name: '게시판관리', class: 'fas fa-list-alt'}
        ]

        const icon = icons.find((_icon) => _icon.name === name);

        if(depth == 0){
            return (icon) ? <i className={icon.class} /> : <i className="fas fa-bars"></i>
        }
    }

    const renderNavItems = (routes: IMultiRoute[], items: TNavItems, depth: number = 0) => {
        if(items.length > 0){
            return (
                <Nav>
                    {items.map((item, key) => {
                        const route = routes.find((route) => route.path === item.path);
                        const subRoutes = route && route.subRoutes;

                        return (
                            <li 
                                key={"nav-" + key}
                                className={route && activeRoute(route)}
                            >
                                <NavLink
                                    to={item.path}
                                >
                                    {renderNavIcon(item.name, depth)}
                                    <p>{item.name}</p>
                                </NavLink>
                                
                                {(item.children.length > 0 && subRoutes) && 
                                    renderNavItems(subRoutes, item.children, depth + 1)
                                }
                            </li>
                        )
                    })}
                </Nav>
            )
        }
    }
    
    const activeRoute = (route: IMultiRoute) => {
        let _active = '';

        _active = (matchPath(route.path, location.pathname)) ? 'active' : '';

        if(route.childRoutes){
            for( const childRoute of route.childRoutes) {
                let fullpath = route.path + '/' + childRoute.path;

                if(matchPath(fullpath, location.pathname)){
                    _active = 'active';
                    break;
                }
            }
        }

        route.subRoutes && route.subRoutes.map(( subRoute ) => {
            if(activeRoute(subRoute) === 'active') _active = 'active-sub';
        })

        return _active;
    };
    
    useEffect(() => {        
        ConfigAPI.getNavigations().then(( res ) => {
            if(res.data.success){
                const { total, navigations } = res.data.message as { total: number, navigations: INavigation[]};

                initNavItems(navigations)
            }
        }).catch(( error ) => {
            console.log(error)
        });
    }, [])

    return (
        <div
            className="sidebar"
            data-color={props.backgroundColor}
            data-active-color={props.activeColor}
        >
            <div className="logo">
                <Link
                    to="/"
                    className="simple-text logo-mini"
                >
                    <div className="logo-img">
                        <img src={logo} alt="react-logo" />
                    </div>
                </Link>
                <Link
                    to="/"
                    className="simple-text logo-normal"
                >
                    Dashboard
                </Link>
            </div>
            <div className="sidebar-stats">
                <div className="sidebar-stats-summary">
                    <div className="subject">금일 입금</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.amounts.total.deposit)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">금일 출금</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.amounts.total.withdraw)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">금일 베팅</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountBets.total.amount)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">금일 당첨</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountBets.total.amount_win)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">회원 보유머니</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountWallets.total.amount)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">회원 보유보너스</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountWallets.total.amount_bonus)}</b> P
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">총판 정산금 입금액</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.settlements.deposit_settlement)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">총판 정산금 출금액</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.settlements.withdraw_settlement)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">총판 보유 정산금액</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountWallets.total.amount_settlement)}</b> 원
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">베팅회원</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountBetUsers)}</b> 명
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">가입회원</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountJoins)}</b> 명
                    </div>
                </div>
                <div className="sidebar-stats-summary">
                    <div className="subject">방문회원</div>
                    <div className="number">
                        <b>{Util.getNumberFormat(statsState.summary.today.accountVisited)}</b> 명
                    </div>
                </div>
                
            </div>
            <div className="sidebar-wrapper">
                {renderNavItems(routes, navItems)}
            </div>
        </div>
    );
};

export default Sidebar