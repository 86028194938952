import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormGroup,
    Button,
    InputGroup,
    InputGroupText,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import InputControl from '../common/InputControl';

import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import ConfigAPI from "../../modules/ConfigAPI";
import BetAPI from "../../modules/BetAPI";

import { IConfig } from "../../interfaces/Config";
import { IBetConfig, IBetConfigObject, IBetConfigValues } from "../../interfaces/BetConfig";
import Util from "../../modules/Util";


const SettingUpcomingForm = () => {
    const configState = useSelector((state: RootState) => state.configReducer);

    const [ levels, setLevels ] = useState<any>([]);
    const [ targetLevel, setTargetLevel] = useState<any>(null);

    const [ sports, setSports ] = useState<any>([]);

    const [ configValues, setConfigValues ] = useState<IConfig>();
    const [ configObjects, setConfigObjects ] = useState<IBetConfigObject>();

    const sumLessOptions = [
        {value: '0', label: '변동없음'},
        {value: '1', label: '모두 증가'},
        {value: '2', label: '역배당 증가'}
    ];

    const [ orderOptions, setOrderOptions ] = useState<{value: any, label: any}[]>([]);

    const { modalAlert } = useModal();
    const { spinnerVisible } = useSpinner();

    const { watch, handleSubmit, control, reset, setValue, formState: { errors, isSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: useMemo(() => configValues, [configValues])
    });

    useEffect(() => {
        ConfigAPI.getLevels().then(( res ) => {
            if(res.data.success){
                const { total, levels } = res.data.message;

                if(total > 0){
                    setLevels(levels);
                    setTargetLevel(levels[0]);
                }else{
                    modalAlert({
                        component: <>사이트 레벨 설정이 초기화되지 않았습니다.</>,
                        title: '프리매치 설정'
                    });
                }
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '프리매치 설정'
            });
        });

        (async () => {
            try {
                spinnerVisible(true);

                const sportsResponse = await BetAPI.getSports('upcoming');
                
                if(sportsResponse.data && sportsResponse.data.success){
                    const sports = sportsResponse.data.message;

                    let filterSports: any = [];
    
                    for( const sport of sports ){
                        const sportKey = Util.removeSpace(sport.name).toLowerCase();
    
                        filterSports.push({
                            key: sportKey,
                            name: sport.name,
                            name_kr: sport.name_kr
                        })
                    }

                    const configResponse = await BetAPI.getConfigs('upcoming', 'all');

                    if(configResponse.data && configResponse.data.success){
                        const betConfigs = configResponse.data.message;

                        console.log('betConfigs', betConfigs)

                        let filterConfigObjects: IBetConfigObject = {};
                        let filterConfigValues: IBetConfigValues = {};

                        for( const betConfigKey in betConfigs ){
                            const { total: targetConfigsTotal, betConfigs: targetConfigs } = betConfigs[betConfigKey];

                            if(targetConfigsTotal > 0){
                                for( const targetConfig of targetConfigs ){
                                    const configKey = targetConfig.config.key;
                                    const typeKey = targetConfig.type.key;
    
                                    if(typeKey != 'upcoming') continue;

                                    const inputName = targetConfig.uid;
                                    const inputValue = targetConfig.value;

                                    switch(betConfigKey){
                                        case 'types':
                                            filterConfigObjects[`${typeKey}_${configKey}`] = {
                                                configKey,
                                                typeKey,
                                                inputName
                                            };
                                            break;
                                        case 'typesSports':
                                            const sportKey = Util.removeSpace(targetConfig.sport.name).toLowerCase();

                                            filterConfigObjects[`${typeKey}_${sportKey}_${configKey}`] = {
                                                configKey,
                                                typeKey,
                                                sportKey,
                                                inputName
                                            };
                                            break;
                                        case 'typesLevels':
                                            const levelLidx = Number(targetConfig.level.lidx);
                                            const levelName = targetConfig.level.name;

                                            filterConfigObjects[`${typeKey}_lv${levelLidx + 1}_${configKey}`] = {
                                                configKey,
                                                typeKey,
                                                levelLidx,
                                                inputName
                                            };
                                            break;
                                    }

                                    switch(targetConfig.config.value_type){
                                        case 'string':
                                            filterConfigValues[`${inputName}`] = String(inputValue);
                                            break;
                                        case 'number':
                                            filterConfigValues[`${inputName}`] = Number(inputValue);
                                            break;
                                        case 'boolean':
                                            filterConfigValues[`${inputName}`] = Util.getBoolean(inputValue);
                                            break;
                                    }
                                }
                            }
                        }

                        setConfigObjects((configObjects) => ({ ...configObjects, ...filterConfigObjects }));
                        setConfigValues((configValues) => ({ ...configValues, ...filterConfigValues }));
        
                        reset(filterConfigValues);
                    }
                    
                    setSports(filterSports);
                    setOrderOptions(() => filterSports.map((_filterSport: any, index: number) => ({ value: String(index + 1), label: String(index + 1)})))
                }

                spinnerVisible(false);
            } catch(error: any) {

                const req = error.request;
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{`[ ${res.status} ] Error Message: ${res.data.message}`}</>,
                        title: 'Warning'
                    });
                }else if(req){
                    console.log(req);
                }else{
                    modalAlert({
                        component: <>{`${error.message}`}</>,
                        title: 'Warning'
                    });
                }
            }
        })();
    }, []);

    const handleOnSubmit: SubmitHandler<any> = (data) => {
        spinnerVisible(true);

        BetAPI.setConfigs(data).then((res) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리가 완료되었습니다.</>,
                    title: '프리매치 설정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '프리매치 설정'
                });
            }

            spinnerVisible(false);
        }).catch(( error ) => {
            spinnerVisible(false);

            modalAlert({
                component: <>{error.message}</>,
                title: '프리매치 설정'
            });
        });
    }
    
    return (
        <div className="settings-form">
            {configValues && 
                <Form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Card className="card-form">
                                        <CardHeader>
                                            <CardTitle tag="h5">기본설정</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <table className="table">
                                                <colgroup>
                                                    <col width="20%"/>
                                                    <col width="20%"/>
                                                    <col width="20%"/>
                                                    <col width="20%"/>
                                                    <col width="20%"/>
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>사용</th>
                                                        <th>점검</th>
                                                        <th>회원만 노출</th>
                                                        <th>합배당</th>
                                                        <th>베팅 대기시간</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="align-middle text-center">
                                                    <td>
                                                            {(configObjects && configObjects['upcoming_bet_used']) && 
                                                                <FormGroup switch className="mb-0">
                                                                    <InputControl 
                                                                        control={control}
                                                                        type="switch"
                                                                        name={`${configObjects['upcoming_bet_used'].inputName}`}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {(configObjects && configObjects['upcoming_bet_used_inspection']) && 
                                                                <FormGroup switch className="mb-0">
                                                                    <InputControl 
                                                                        control={control}
                                                                        type="switch"
                                                                        name={`${configObjects['upcoming_bet_used_inspection'].inputName}`}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {(configObjects && configObjects['upcoming_bet_used_signin']) && 
                                                                <FormGroup switch className="mb-0">
                                                                    <InputControl 
                                                                        control={control}
                                                                        type="switch"
                                                                        name={`${configObjects['upcoming_bet_used_signin'].inputName}`}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {(configObjects && configObjects['upcoming_bet_used_rate_sum']) && 
                                                                <FormGroup switch className="mb-0">
                                                                    <InputControl 
                                                                        control={control}
                                                                        type="switch"
                                                                        name={`${configObjects['upcoming_bet_used_rate_sum'].inputName}`}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {(configObjects && configObjects['upcoming_bet_wait_seconds']) && 
                                                                <InputControl 
                                                                    control={control}
                                                                    type="number"
                                                                    name={`${configObjects['upcoming_bet_wait_seconds'].inputName}`}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card className="card-form">
                                        <CardHeader>
                                            <CardTitle tag="h5">종목별 설정</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <table className="table">
                                                <colgroup>
                                                    <col width="150px"/>
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col />
                                                    <col width="100px"/>
                                                    <col />
                                                    <col />
                                                    <col width="80px"/>
                                                </colgroup>
                                                <thead>
                                                    <tr className="align-middle">
                                                        <th scope="col" rowSpan={2}>종목</th>
                                                        <th scope="col" rowSpan={2}>사용</th>
                                                        <th scope="col" rowSpan={2}>점검</th>
                                                        <th scope="col" colSpan={5}>배당</th>
                                                        <th scope="col" colSpan={2}>제한</th>
                                                        <th scope="col" rowSpan={2}>순서</th>
                                                    </tr>
                                                    <tr className="align-middle">
                                                        <th scope="col">기준점</th>
                                                        <th scope="col">최소</th>
                                                        <th scope="col">최대</th>
                                                        <th scope="col">합배당</th>
                                                        <th scope="col">합배당 이하면</th>
                                                        <th scope="col">언오버</th>
                                                        <th scope="col">핸디캡</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sports.map((sport: any) => {
                                                        return (
                                                            <tr key={'inplay' + sport.key} className="align-middle text-center">
                                                                <th scope="row">{sport.name_kr ? sport.name_kr : sport.name}</th>
                                                                
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_used`]) && 
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name={`${configObjects[`upcoming_${sport.key}_bet_used`].inputName}`}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_used_inspection`]) && 
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name={`${configObjects[`upcoming_${sport.key}_bet_used_inspection`].inputName}`}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_rate_base`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="number"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_rate_base`].inputName}`}
                                                                            step="0.01"
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_rate_limit_min`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="number"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_rate_limit_min`].inputName}`}
                                                                            step="0.01"
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_rate_limit_max`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="number"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_rate_limit_max`].inputName}`}
                                                                            step="0.01"
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_rate_sum`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="number"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_rate_sum`].inputName}`}
                                                                            step="0.01"
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_rate_sum_less`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="select"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_rate_sum_less`].inputName}`}
                                                                            options={sumLessOptions}
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_rate_limit_unover`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="number"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_rate_limit_unover`].inputName}`}
                                                                            step="0.01"
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_rate_limit_handicap`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="number"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_rate_limit_handicap`].inputName}`}
                                                                            step="0.01"
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_${sport.key}_bet_order`]) && 
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="select"
                                                                            name={`${configObjects[`upcoming_${sport.key}_bet_order`].inputName}`}
                                                                            options={orderOptions}
                                                                        />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {targetLevel != null && 
                                <>
                                    <Row>
                                        <Col>
                                            <Card className="card-form">
                                                <CardHeader>
                                                    <CardTitle tag="h5">레벨별 기본설정</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Nav tabs fill className="mb-3">
                                                        {levels.map((level: any, key: number) => {
                                                            return (
                                                                <NavItem key={`levels-${key}`}>
                                                                    <NavLink className="cursor-pointer" onClick={(event) => setTargetLevel(level)} active={level.lidx == targetLevel.lidx}>
                                                                        <span>{level.name}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                            )
                                                        })}
                                                    </Nav>

                                                    <table className="table">
                                                        <thead>
                                                            <tr className="align-middle">
                                                                <th scope="col">베팅 최소금액</th>
                                                                <th scope="col">베팅 최소폴더</th>
                                                                <th scope="col">최소롤링</th>
                                                                <th scope="col" rowSpan={2}>베팅 마감시간</th>
                                                                <th scope="col" rowSpan={2}>경기시작 전 취소시간</th>
                                                                <th scope="col" rowSpan={2}>베팅 후 취소시간</th>
                                                                <th scope="col" rowSpan={2}>일일 베팅취소 횟수</th>
                                                                <th scope="col" rowSpan={2}>베팅 낙첨 보너스</th>
                                                            </tr>
                                                            <tr className="align-middle">
                                                                <th scope="col">베팅 최대금액</th>
                                                                <th scope="col">베팅 최대폴더</th>
                                                                <th scope="col">최대롤링</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="align-middle text-center">
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_all_amount_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_all_amount_min`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_folder_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_folder_min`].inputName}`}
                                                                            />
                                                                            <InputGroupText>폴더</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rolling_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rolling_min`].inputName}`}
                                                                                step="0.1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td rowSpan={2}>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_deadline_seconds`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_deadline_seconds`].inputName}`}
                                                                            />
                                                                            <InputGroupText>초</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td rowSpan={2}>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_cancel_minutes_before`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_cancel_minutes_before`].inputName}`}
                                                                            />
                                                                            <InputGroupText>분</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td rowSpan={2}>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_cancel_minutes_after`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_cancel_minutes_after`].inputName}`}
                                                                            />
                                                                            <InputGroupText>분</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td rowSpan={2}>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_cancel_limit_day`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_cancel_limit_day`].inputName}`}
                                                                            />
                                                                            <InputGroupText>회</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td rowSpan={2}>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_all_lose_bonus`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_all_lose_bonus`].inputName}`}
                                                                                step="0.1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_all_amount_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_all_amount_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_folder_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_folder_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>폴더</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rolling_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rolling_max`].inputName}`}
                                                                                step="0.1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className="card-form">
                                                <CardHeader>
                                                    <CardTitle tag="h5">레벨별 단폴설정</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <table className="table">
                                                        <colgroup>
                                                            <col width="150px"/>
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">항목</th>
                                                                <th scope="col">설정</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 사용</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_used_single`]) && 
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_used_single`].inputName}`}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 롤링사용</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_used_single_rolling`]) && 
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_used_single_rolling`].inputName}`}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 최소배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_rate_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_rate_min`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 최대배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_rate_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_rate_max`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_rate_bonus`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_rate_bonus`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 최소 베팅금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_amount_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_amount_min`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 최대 베팅금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_amount_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_amount_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 최대 당첨금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_amount_max_win`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_amount_max_win`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">단폴 낙첨 보너스</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_lose_bonus`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_single_lose_bonus`].inputName}`}
                                                                                step="0.1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="card-form">
                                                <CardHeader>
                                                    <CardTitle tag="h5">레벨별 다폴설정</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <table className="table">
                                                        <colgroup>
                                                            <col width="150px"/>
                                                            <col />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">항목</th>
                                                                <th scope="col">설정</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">다폴 최소배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_rate_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_rate_min`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">다폴 최대배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_rate_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_rate_max`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">다폴 최소 베팅금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_amount_min`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_amount_min`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">다폴 최대 베팅금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_amount_max`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_amount_max`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">다폴 최대 당첨금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_amount_max_win`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_amount_max_win`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">다폴 낙첨 보너스</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_lose_bonus`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_multi_lose_bonus`].inputName}`}
                                                                                step="0.1"
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">축베팅 사용</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_used_line`]) && 
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_used_line`].inputName}`}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">축베팅 단계</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_line_step`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_line_step`].inputName}`}
                                                                            />
                                                                            <InputGroupText>단계</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">축베팅 최대 당첨금액</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_line_amount_max_win`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_line_amount_max_win`].inputName}`}
                                                                            />
                                                                            <InputGroupText>원</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">2폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_2`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_2`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">3폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_3`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_3`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">4폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_4`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_4`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">5폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_5`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_5`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">6폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_6`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_6`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">7폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_7`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_7`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">8폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_8`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_8`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">9폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_9`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_9`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">10폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_10`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_10`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">11폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_11`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_11`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">12폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_12`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_12`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">13폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_13`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_13`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">14폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_14`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_14`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <th scope="row">15폴더 보너스배당</th>
                                                                <td>
                                                                    {(configObjects && configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_15`]) && 
                                                                        <InputGroup className="w-100">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="number"
                                                                                name={`${configObjects[`upcoming_lv${targetLevel.lidx + 1}_bet_rate_folder_bonus_15`].inputName}`}
                                                                                step="0.01"
                                                                            />
                                                                            <InputGroupText>배당</InputGroupText>
                                                                        </InputGroup>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center py-2">
                            <Button
                                className="btn-round"
                                color="primary"
                                type="submit"
                            >
                                설정 수정
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
        </div>
    );
}

export default SettingUpcomingForm