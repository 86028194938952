import { AxiosPromise } from "axios";
import API from "./API";

const StatsAPI = {
    getSummaryToday: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_STATS_URL + `/summary`
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
}

export default StatsAPI;