import React, { useEffect, useState, useRef, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { 
    Row, 
    Col, 
    Form,
    FormGroup,
    Button,
    Input,
    Label,
    Card,
    CardBody
} from 'reactstrap';
import Select, { ActionMeta, SingleValue, PropsValue } from 'react-select'

import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import { hideModal } from "../../redux/reducers/modalReducer";
import { IParamPermissionBridges } from "../../interfaces/Config";
import BetAPI from "../../modules/BetAPI";

const PicksModal = (props: any) => {
    const { uid_bet } = props;

    const dispatch = useDispatch();

    const handleHideModal = () => dispatch(hideModal());

    let selectRef = useRef<any>();

    const [ data, setData ] = useState<{total: number, accountBetPicks: any[]}>({
        total: 0,
        accountBetPicks: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const { modalAlert } = useModal();

    useEffect(() => {
        BetAPI.getBetPicks(page, limit, uid_bet).then(( res ) => {
            if(res.data.success){
                const { total, accountBetPicks } = res.data.message;

                console.log('accountBetPicks', accountBetPicks);

                let filterAccountBetPicks: any = [];

                for( const accountBetPick of accountBetPicks ){
                    let filterAccountBet = { 
                        ...accountBetPick, 
                    };

                    filterAccountBetPicks.push(filterAccountBet);
                }

                setData({ total, accountBetPicks: filterAccountBetPicks });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '베팅관리'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '베팅관리'
            });
        });
    }, [page, limit]);

    useEffect(() => {
    }, []);

    /*
    const toggleSelfPermission = (index: number) => {
        setPermissionSelects((permissionSelects) => {
            permissionSelects[index].self = !permissionSelects[index].self;

            return [...permissionSelects]
        });

        console.log(permissionSelects)
    }
    */

    return (
        <div className="modal-form">
            <table className="table">
                <thead>
                    <tr className="align-middle">
                        <th scope="col">코드</th>
                        <th scope="col">경기명</th>
                        <th scope="col" rowSpan={2}>배당</th>
                    </tr>
                    <tr className="align-middle">
                        <th scope="col">상태</th>
                        <th scope="col">배당명</th>
                    </tr>
                </thead>
                <tbody>
                    {data.accountBetPicks.map((accountBetPick, index) => 
                        (
                            accountBetPick.event
                            ?
                            <React.Fragment key={index}>
                                <tr className="align-middle text-center">
                                    <td>{accountBetPick.uid}</td>
                                    <td>
                                        {accountBetPick.event.name_kr ? accountBetPick.event.name_kr : accountBetPick.event.name}
                                    </td>
                                    <td rowSpan={2}>{Number(accountBetPick.rate).toFixed(2)}</td>
                                </tr>
                                <tr className="align-middle text-center">
                                    <td>{accountBetPick.result_kr ? accountBetPick.result_kr : accountBetPick.result}</td>
                                    <td>
                                        {accountBetPick.marketGroup.name_kr 
                                            ? 
                                            `[ ${accountBetPick.marketGroup.name_kr} ] ` 
                                            : 
                                            `[ ${accountBetPick.marketGroup.name} ] `
                                        }
                                        <br/>
                                        {accountBetPick.name_kr ? accountBetPick.name_kr : accountBetPick.name}
                                    </td>
                                </tr>
                            </React.Fragment>
                            :
                            <React.Fragment key={index}>
                                <tr className="align-middle text-center">
                                    <td>{accountBetPick.uid}</td>
                                    <td>
                                        -
                                    </td>
                                    <td rowSpan={2}>{Number(accountBetPick.rate).toFixed(2)}</td>
                                </tr>
                                <tr className="align-middle text-center">
                                    <td>{accountBetPick.result_kr ? accountBetPick.result_kr : accountBetPick.result}</td>
                                    <td>
                                        {accountBetPick.name_kr ? accountBetPick.name_kr : accountBetPick.name}
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default PicksModal