import React from "react";
import { Container, Row } from "reactstrap";

const Footer = () => {
    return (
        <footer className="footer">
            <Container fluid>
                <Row>
                    <nav className="footer-nav">
                        <ul>
                            <li>
                                <a href="#" target="_blank">
                                    Company
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    Privacy policy
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    Licenses
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="credits ms-auto">
                        <div className="copyright">
                            &copy; 2023, made with{" "}
                            <i className="fa fa-heart heart" /> by Dashboard
                        </div>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer