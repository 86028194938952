import React, { useRef, useState, useEffect, useMemo, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useNavigate, Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Card, CardBody, Input, Button, FormGroup, Form, InputGroup, InputGroupText, Row, Col } from 'reactstrap';

import moment from "moment";

import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import Util from "../../modules/Util";

import LeaguesSearchForm from './LeaguesSearchForm';
import InputControl from '../common/InputControl';
import { IAccount } from "../../interfaces/Account";
import BetAPI from "../../modules/BetAPI";
import { IConfig } from "../../interfaces/Config";
import { IBetConfigObject, IBetConfigValues } from "../../interfaces/BetConfig";

const SettingLeaguesForm = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ data, setData ] = useState<{total: number, leagues: any[]}>({
        total: 0,
        leagues: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { target, sport, keyword }, setSearchForms ] = useState({
        target: 'upcoming',
        sport: '',
        keyword: ''
    });

    const [ configValues, setConfigValues ] = useState<IConfig>();
    const [ configObjects, setConfigObjects ] = useState<IBetConfigObject[]>([]);

    useEffect(() => {
        if(sport){
            spinnerVisible(true);

            BetAPI.getConfigLeagues(target, page, limit, sport, keyword).then(( res ) => {
                if(res.data.success){
                    const { total, leagues } = res.data.message;

                    setData({ total, leagues });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '리그 설정'
                    });
                }

                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);

                modalAlert({
                    component: <>{error.message}</>,
                    title: '리그 설정'
                });
            });
        }
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            if(sport){
                spinnerVisible(true);

                BetAPI.getConfigLeagues(target, 1, limit, sport, keyword).then(( res ) => {
                    if(res.data.success){
                        const { total, leagues } = res.data.message;

                        setData({ total, leagues });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '리그 설정'
                        });
                    }

                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '리그 설정'
                    });
                });
            }
        }, 300);

        return () => clearTimeout(delay)
    }, [target, sport, keyword]);

    useEffect(() => {
        const { total, leagues } = data;

        console.log('leagues', leagues);

        let filterConfigObjects: IBetConfigObject[] = [];
        let filterConfigValues: IBetConfigValues = {};

        for( const league of leagues ){
            const inputName = league.uid;
            const inputUsedValue = league.useable;
            const inputLimitPerValue = league.limit_per;

            let filterConfigObject: any = {
                inputUsedName: `${inputName}_used`
            };

            if(typeof league.limit_per == 'number'){
                filterConfigObject = {
                    ...filterConfigObject,
                    inputLimitPerName: `${inputName}_limit_per`
                }

                if(configValues){
                    filterConfigValues[`${inputName}_limit_per`] = (typeof configValues[`${inputName}_limit_per`] == 'number') ? configValues[`${inputName}_limit_per`] : Number(inputLimitPerValue);
                }else{
                    filterConfigValues[`${inputName}_limit_per`] = Number(inputLimitPerValue);
                }
            }

            filterConfigObjects.push(filterConfigObject);

            filterConfigValues[`${inputName}_used`] = (inputUsedValue == 'true') ? true : false;
            filterConfigValues[`${inputName}_limit_per`] = Number(inputLimitPerValue);
        }

        setConfigObjects((configObjects) => [ ...filterConfigObjects ]);
        setConfigValues((configValues) => ({ ...filterConfigValues }));
    }, [data]);

    const handleConfigValue = (key: string, value: number | string | boolean) => {
        setConfigValues((configValues) => {
            if(configValues && Object.keys(configValues).length > 0){
                return { ...configValues, [`${key}`]: value }
            }else{
                return configValues;
            }
        })
    }

    const handleSetLimitPer = (target:string, uid: string) => {
        if(configValues && Object.keys(configValues).length > 0){
            spinnerVisible(true);

            if(typeof configValues[`${uid}_limit_per`] == 'number'){
                BetAPI.setConfigLeague(target, uid, 'bet_limit_per', configValues[`${uid}_limit_per`]).then((res) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리가 완료되었습니다.</>,
                            title: '리그 설정'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '리그 설정'
                        });
                    }
        
                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);
        
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '리그 설정'
                    });
                });
            }
        }
    }

    const handleSetUsed = (target: string, uid: string, value: string) => {
        if(configValues && Object.keys(configValues).length > 0){
            spinnerVisible(true);

            if(typeof configValues[`${uid}_limit_per`] == 'boolean'){
                BetAPI.setConfigLeague(target, uid, 'bet_used', value).then((res) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리가 완료되었습니다.</>,
                            title: '리그 설정'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '리그 설정'
                        });
                    }

                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '리그 설정'
                    });
                });
            }
        }
    }

    return (
        <div className="contents">
            <LeaguesSearchForm target={target} setSearchForms={setSearchForms} />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <table className="table">
                                <colgroup>
                                    <col width="120px"/>
                                    <col width="120px"/>
                                    <col />
                                    <col width="140px"/>
                                    <col width="80px"/>
                                    <col width="100px"/>
                                </colgroup>
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col">코드</th>
                                        <th scope="col">종목</th>
                                        <th scope="col">리그명</th>
                                        <th scope="col" colSpan={2}>제한율</th>
                                        <th scope="col">사용</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(configObjects.length > 0 && configValues) && data.leagues.map(( league: any, key ) => 
                                        <React.Fragment key={key}>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <td>{league.uid}</td>
                                                <td>{league.sport.name_kr ? league.sport.name_kr : league.sport.name}</td>
                                                <td>{league.name_kr ? league.name_kr : league.name}</td>
                                                <td>
                                                    {(configObjects[key] && typeof configObjects[key].inputLimitPerName == 'string') && 
                                                        <InputGroup>
                                                            <Input 
                                                                type="number"
                                                                name={`${configObjects[key].inputLimitPerName}`}
                                                                value={configValues[configObjects[key].inputLimitPerName]}
                                                                onChange={(event) => handleConfigValue(`${league.uid}_limit_per`, event.target.value)}
                                                                step="1"
                                                                min={0}
                                                                max={100}
                                                            />
                                                            <InputGroupText>%</InputGroupText>
                                                        </InputGroup>
                                                    }
                                                </td>
                                                <td>
                                                    <Button
                                                        color="secondary"
                                                        type="button"
                                                        onClick={(event) => handleSetLimitPer(target, league.uid)}
                                                    >
                                                        적용
                                                    </Button>
                                                </td>
                                                <td>
                                                    {configObjects[key] && 
                                                        <FormGroup switch className="mb-0">
                                                            <Input 
                                                                type="switch"
                                                                className="form-control-sm"
                                                                name={`${configObjects[key].inputUsedName}`}
                                                                checked={configValues[configObjects[key].inputUsedName]}
                                                                onChange={(event) => {
                                                                    handleConfigValue(`${league.uid}_used`, event.currentTarget.checked)
                                                                    handleSetUsed(target, league.uid, String(event.currentTarget.checked))
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    }
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                            <Pagination 
                                total={data.total}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SettingLeaguesForm