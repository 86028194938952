import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormGroup,
    Button,
    Input,
    Label
} from 'reactstrap';

import InputControl from '../common/InputControl';

import SignupForm from "./SignupForm";
import axios from "axios";
import useModal from "../../hooks/GlobalModals";
import ConfigAPI from "../../modules/ConfigAPI";
import DomainsForm from "./DomainsForm";

const SettingBasicsForm = () => {
    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert } = useModal();

    let defaultValues: { [key: string]: any } = {
        site_name: (configState.site_name) ? configState.site_name : '',

        site_used_inspection:  (configState.site_used_inspection == 'true') ? true : false,
        site_inspection_text: (configState.site_inspection_text) ? configState.site_inspection_text : '',
        
        site_sns_telegram: '',
        site_sns_kakaotalk: '',

        signup_used: (configState.signup_used == 'true') ? true : false,
        signup_role_key: (configState.signup_role_key) ? configState.signup_role_key : 'waiter',
        signup_level_lidx: (configState.signup_level_lidx) ? configState.signup_level_lidx : 0,

        signup_used_name:  (configState.signup_used_name == 'true') ? true : false,
        signup_used_fullname:  (configState.signup_used_fullname == 'true') ? true : false,
        signup_required_name:  (configState.signup_required_name == 'true') ? true : false,

        signup_used_email:  (configState.signup_used_email == 'true') ? true : false,
        signup_required_email:  (configState.signup_required_email == 'true') ? true : false,
        signup_verify_email:  (configState.signup_verify_email == 'true') ? true : false,

        signup_used_phone:  (configState.signup_used_phone == 'true') ? true : false,
        signup_required_phone:  (configState.signup_required_phone == 'true') ? true : false,
        signup_verify_phone:  (configState.signup_verify_email == 'true') ? true : false,

        signup_used_address:  (configState.signup_used_address == 'true') ? true : false,
        signup_required_address:  (configState.signup_required_address == 'true') ? true : false,

        domains: [],
    }

    const { watch, handleSubmit, control, reset, setValue, formState } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues
    });

    watch((data, {name, type}) => {
        switch(name){
            case 'signup_used_name':
                if(!data.signup_used_name) setValue('signup_required_name', false)
                break;
            case 'signup_required_name':
                if(data.signup_required_name) setValue('signup_used_name', true)
                break;
            case 'signup_used_email':
                if(!data.signup_used_email) setValue('signup_required_email', false)
                break;
            case 'signup_required_email':
                if(data.signup_required_email) setValue('signup_used_email', true)
                break;
            case 'signup_used_phone':
                if(!data.signup_used_phone) setValue('signup_required_phone', false)
                break;
            case 'signup_required_phone':
                if(data.signup_required_phone) setValue('signup_used_phone', true)
                break;
            case 'signup_used_address':
                if(!data.signup_used_address) setValue('signup_required_address', false)
                break;
            case 'signup_required_address':
                if(data.signup_required_address) setValue('signup_used_address', true)
                break;
        }
    });

    const domainsDefaultValues = {
        domain: '' 
    };

    const [
        site_used_inspection,
        watchDomainFields
    ] = watch(['site_used_inspection', 'domains'])

    const domainFields = useFieldArray({
        control: control, 
        name: 'domains'
    });

    useEffect(() => {
        ConfigAPI.getDomains().then(( res ) => {
            if(res.data.success){
                const { total, domains } = res.data.message;

                let filterDomains = []

                for( const domain of domains ){
                    let filterDomain = {
                        domain: domain.domain
                    }

                    filterDomains.push(filterDomain)
                }

                reset({ 'domains': filterDomains })
            }else{
                modalAlert({
                    component: <>Unable to Initialize Application.</>,
                    title: '기본 설정'
                });
            }
        })
    }, [])

    const handleOnSubmit: SubmitHandler<any> = (data) => {
        console.log('data', data)
        if(watchDomainFields.length <= 0){
            domainFields.append({ ...domainsDefaultValues, default: true });
            //setError('domains.0.domain', { type: 'leastone', message: 'Please enter at least one domain.' });

            modalAlert({
                component: <>도메인을 하나 이상 입력하세요.</>,
                title: '기본 설정'
            })

            return false;
        }

        for( const _field of watchDomainFields ){
            if(!_field.domain){
                modalAlert({
                    component: <>올바른 도메인을 입력하세요.</>,
                    title: '기본 설정'
                });

                return false;
            }
        }
        ConfigAPI.setBasics(data).then(( res ) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리되었습니다.</>,
                    title: '기본 설정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '기본 설정'
                });
            }
        })
        .catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '기본 설정'
            });
        });
    }
    
    return (
        <div className="settings-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Card className="card-form">
                                    <CardHeader>
                                        <CardTitle tag="h5">사이트 설정</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="site_name"
                                                        label="사이트명"
                                                        placeholder="사이트명"
                                                        rules={{
                                                            required: true
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pe-1">
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="site_sns_telegram"
                                                        label="텔레그램 아이디"
                                                        placeholder="텔레그램 아이디"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="ps-1">
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="site_sns_kakaotalk"
                                                        label="카카오톡 아이디"
                                                        placeholder="카카오톡 아이디"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="card-form">
                                    <CardHeader>
                                        <CardTitle tag="h5">사이트 점검 설정</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup switch>
                                                    <InputControl 
                                                        control={control}
                                                        type="switch"
                                                        name="site_used_inspection"
                                                        label="사이트 점검"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        type="textarea"
                                                        name="site_inspection_text"
                                                        label="사이트 점검문구"
                                                        placeholder="사이트 점검문구"
                                                        disabled={!site_used_inspection}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Row>
                                    <Col>
                                        <DomainsForm control={control} watch={watch} errors={formState.errors} domainFields={domainFields} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <SignupForm control={control} watch={watch} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                        >
                            설정 수정
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default SettingBasicsForm