import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

import ConfigAPI from "../../modules/ConfigAPI";
import BetAPI from "../../modules/BetAPI";
import Util from "../../modules/Util";

interface ISearchFormProps {
    target: string;
    status: number;
    setSearchForms: Dispatch<SetStateAction<any>>;
}

const EventsSearchForm = (props: ISearchFormProps) => {
    const { target, status, setSearchForms } = props;
    
    const { modalAlert } = useModal();

    const targetOptions = [
        { value: 'upcoming', label: '프리매치' },
        { value: 'inplay', label: '인플레이' }
    ]

    const statusOptions = [
        {value: '0', label: '시작 전'},
        {value: '1', label: '경기중'},
        {value: '2', label: '수정 예정'},
        {value: '3', label: '경기종료'},
        {value: '4', label: '일정연기'},
        {value: '5', label: '경기취소'},
        {value: '6', label: '부전승'},
        {value: '7', label: '경기중단'},
        {value: '8', label: '경기포기'},
        {value: '9', label: '경기기권'},
        {value: '10', label: '경기정지'},
        {value: '11', label: 'FA가 결정'},
        {value: '99', label: '경기삭제'},
    ]

    const [ sportOptions, setSportOptions ] = useState<{value: any, label: any}[]>([])

    const defaultStatus = '0';

    useEffect(() => {
        BetAPI.getSports(target).then(( res ) => {
            if(res.data.success){
                const sports = res.data.message;

                let filterSportOptions: {value: any, label: any}[] = [];
    
                let forCount = 0;
                for( const sport of sports ){
                    if(forCount == 0)
                        setSearchForms((searchForm: any) => ({ ...searchForm, sport: sport.uid }))

                    filterSportOptions.push({
                        value: sport.uid,
                        label: (sport.name_kr) ? sport.name_kr : sport.name
                    })

                    forCount++;
                }

                setSportOptions([ ...filterSportOptions ]);
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '경기 설정'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '경기 설정'
            });
        });
    }, [target])

    return (
        <div className="search-wrapper">
            <Card className="card-form-search">
                <CardHeader>
                    <CardTitle tag="h5">검색</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="2">
                            <Label for="target">타입</Label>
                            <Input
                                type="select"
                                name="target"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { 
                                    if(e.target.value == 'inplay'){
                                        return {...searchForms, target: e.target.value, status: '1'} 
                                    }else{
                                        return {...searchForms, target: e.target.value, status: defaultStatus} 
                                    }
                                })}
                            >
                                {targetOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="2">
                            <Label for="target">종목</Label>
                            <Input
                                type="select"
                                name="sport"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, sport: e.target.value} })}
                            >
                                {sportOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="2">
                            <Label for="status">상태</Label>
                            <Input
                                type="select"
                                name="status"
                                bsSize="sm"
                                value={status}
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, status: e.target.value} })}
                            >
                                {statusOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="6">
                            <Label for="keyword">검색어</Label>
                            <Input
                                name="keyword"
                                placeholder="검색어 ( 코드, 리그명, 팀명 ... )"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, keyword: e.target.value} })}
                            />
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </div>
    )
}

export default EventsSearchForm