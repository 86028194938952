import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/reducers/rootReducer';
import { setAuth } from '../../redux/reducers/authReducer';
import { setConfig } from '../../redux/reducers/configReducer';

import useModal from "../../hooks/GlobalModals";

import GlobalSpinner from  '../common/GlobalSpinner';
import GlobalModals from '../common/Modal/GlobalModals';
import GlobalNotifies from '../common/Notify/GlobalNotifies';

import MultiRoutes from '../common/MultiRoutes';

import routes from '../../routes';
import { TAuth } from '../../types/Auth';
import CommonAPI from '../../modules/CommonAPI';
import SignXAPI from '../../modules/SignXAPI';
import { Spinner } from 'reactstrap';
import { clearNotifyItems } from '../../redux/reducers/notifyReducer';


const Root = (props: any) => {
    const [ Initialized, setInitialized ] = useState<boolean>(false);

    const configState = useSelector((state: RootState) => state.configReducer);
    const spinnerState = useSelector((state: RootState) => state.spinnerReducer);
    const modalState = useSelector((state: RootState) => state.modalReducer);
    const notifyState = useSelector((state: RootState) => state.notifyReducer);

    const { modalAlert } = useModal();

    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    const authDefaultValues: TAuth = {
        modifire: 'PUBLIC',
        uuid: '',
        role: null,
        level: null,
        userid: '',
        name: '',
        amount: 0,
        amount_bonus: 0,
        amount_point: 0,
        ip: '',
        expires: 0
    }

    useEffect(() => {
        let expiresTimeout: NodeJS.Timeout | undefined = undefined;

        CommonAPI.getCommon().then(( res ) => {
            if(res.data.success){
                const common = res.data.message;

                console.log('common.user', common.user)

                dispatch(setAuth(common.user));
                dispatch(setConfig(common.config));

                console.log(common.config)

                const { uuid, expires } = common.user;

                if(expires > 0) 
                    expiresTimeout = setTimeout(() => {
                        SignXAPI.signout().then(( res ) => {
                            if(!res.data.success){
                                modalAlert({
                                    component: <>{res.data.message}</>,
                                    title: '로그아웃'
                                });
                            }else{
                                dispatch(clearNotifyItems());
                                dispatch(setAuth(authDefaultValues));
                            }
                        }).catch(( error ) => {
                            modalAlert({
                                component: <>장시간 활동이 없어 자동으로 로그아웃 되었습니다.</>,
                                title: '로그아웃'
                            });

                            dispatch(clearNotifyItems());
                            dispatch(setAuth(authDefaultValues));
                        });

                        navigate('/signx');
                    }, expires);

                setInitialized(true);
            }else{
                modalAlert({
                    component: <>Unable to Initialize Application.</>,
                    title: 'Warning'
                }); 
            }
        })
        .catch(( error ) => {
            dispatch(setAuth(authDefaultValues));

            const req = error.request;
            const res = error.response;

            if (res) {
                console.log('[ ' + res.status + ' ] Error Message:', res.data.message);
            } else if (req) {
                console.log(req);
            } else {
                console.log('Error', error.message);
            }

            navigate('/signx', { replace: true });
        });

        return () => { clearTimeout(expiresTimeout) }
    }, [location]);

    useEffect(() => {
        document.title = configState.site_name + ' 관리자 시스템';
    }, [configState]);

    return (
        <>
            <GlobalSpinner visible={spinnerState} />
            <div className="wrapper">
                {modalState.modalVisible && <GlobalModals {...modalState} />}
                {notifyState.notifiesVisible && <GlobalNotifies {...notifyState} />}
                {Initialized && <MultiRoutes routes={routes} />}
            </div>
        </>
    );
};

export default Root