import { AxiosPromise } from "axios";
import API from "./API";

import { IParamMemo } from "../interfaces/Memo";

const MemoAPI = {
    set: (params: IParamMemo): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_MEMO_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    get: (uid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_MEMO_URL + `/${uid}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    delete: (idx: number): AxiosPromise => {
        return API({
            method: 'delete',
            url: process.env.REACT_APP_API_MEMO_URL + `/${idx}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    deleteGroup: (uid: string): AxiosPromise => {
        return API({
            method: 'delete',
            url: process.env.REACT_APP_API_MEMO_URL + `/group/${uid}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default MemoAPI;