import { combineReducers } from 'redux';

import configReducer from './configReducer';
import configRolesReducer from './rolesReducer';
import authReducer from './authReducer';
import spinnerReducer from './spinnerReducer';
import modalReducer from './modalReducer';
import notifyReducer from './notifyReducer';
import statsReducer from './statsReducer';
import alarmReducer from './alarmReducer';

const rootReducer = combineReducers({
    configReducer,
    configRolesReducer,
    authReducer,
    spinnerReducer,
    modalReducer,
    notifyReducer,
    statsReducer,
    alarmReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;