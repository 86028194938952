import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button,
    Label
} from 'reactstrap';
import Select, { ActionMeta, SingleValue, PropsValue, CSSObjectWithLabel, MultiValue } from 'react-select'

import Util from '../../modules/Util';
import AccountAPI from "../../modules/AccountAPI";
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';
import TextEditor from '../common/Editor/TextEditor';

import { IPopup } from "../../interfaces/Popup";
import PopupAPI from "../../modules/PopupAPI";


const PopupFormModal = ({ uid }: any) => {
    const location = useLocation();

    const { modalAlert } = useModal();

    const typeOptions = [
        { value: 'all', label: '모두' },
        { value: 'member', label: '회원만' }
    ]

    let defaultValues: IPopup = {
        type: 'all',
        subject: '',
        contents: ''
    };

    const { watch, handleSubmit, control, reset, setFocus, setValue, setError, formState } = useForm<IPopup>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const watchContents = watch('contents');

    useEffect(() => {
        if(uid){
            PopupAPI.get(uid).then((res) => {
                if(res.data.success){
                    const _popup = res.data.message;

                    let resetValues: IPopup = {
                        type: _popup.type,
                        subject: _popup.subject,
                        contents: _popup.contents
                    }

                    reset(resetValues);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '팝업 추가/수정'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '팝업 추가/수정'
                });
            });
        }
    }, [])

    useEffect(() => {
        console.log(formState.errors)
    }, [formState])

    const handleOnSubmit: SubmitHandler<any> = (formData) => {

        if(!formData.subject){
            modalAlert({
                component: <>제목을 입력해주세요.</>,
                title: '팝업 추가/수정'
            })

            return false;
        }else if(!formData.contents){
            modalAlert({
                component: <>내용을 입력해주세요.</>,
                title: '팝업 추가/수정'
            })

            return false;
        }else{
            let filterFormData = {
                ...formData
            }

            if(uid){
                PopupAPI.update({ ...filterFormData, uid }).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리되었습니다.</>,
                            title: '팝업 추가/수정'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '팝업 추가/수정'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '팝업 추가/수정'
                    });
                });
            }else{
                PopupAPI.set(filterFormData).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리되었습니다.</>,
                            title: '팝업 추가/수정'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '팝업 추가/수정'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '팝업 추가/수정'
                    });
                });
            }
        }
    }
    
    return (
        <div className="modal-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)} id="modal-form-popup">
                <Row>
                    <Col>
                        <Row>
                            <Col className="pe-1" md={2}>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        type="select"
                                        name="type"
                                        label="타입"
                                        options={typeOptions}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="ps-1">
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        name="subject"
                                        label="제목"
                                        placeholder="제목"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <TextEditor
                                        data={watchContents}
                                        placeholder="내용을 입력하세요."
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            //console.log( { event, editor, data } );
                                            setValue('contents', data);
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            //console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            //console.log( 'Focus.', editor );
                                        } }
                                        isUpload={true}
                                        uploadType="popup"
                                        uploadKey=""
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default PopupFormModal