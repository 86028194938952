import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    ButtonGroup, 
    Button,
    Label
} from 'reactstrap';
import Select, { ActionMeta, SingleValue, PropsValue, CSSObjectWithLabel, MultiValue } from 'react-select'

import Util from '../../modules/Util';
import AccountAPI from "../../modules/AccountAPI";
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';
import TextEditor from '../common/Editor/TextEditor';

import { IBoard } from "../../interfaces/Board";
import BoardAPI from "../../modules/BoardAPI";
import { IMemo } from "../../interfaces/Memo";
import AccountsAPI from "../../modules/AccountsAPI";
import MemoAPI from "../../modules/MemoAPI";


const MemoGroupFormModal = () => {
    const location = useLocation();

    const { modalAlert } = useModal();

    const [group, setGroup] = useState<string>('member');

    let defaultValues: IMemo = {
        group: '',
        subject: '',
        contents: ''
    };

    const { watch, handleSubmit, control, reset, setFocus, setValue, setError, formState } = useForm<IMemo>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const watchContents = watch('contents');

    const handleOnSubmit: SubmitHandler<any> = (formData) => {

        if(!group){
            modalAlert({
                component: <>받을 단체를 선택해주세요.</>,
                title: '단체쪽지보내기'
            });
        }else{
            let filterFormData = {
                type: 'group',
                ...formData,
                group
            }

            console.log('filterFormData', filterFormData)

            MemoAPI.set(filterFormData).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '단체쪽지보내기'
                    });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '단체쪽지보내기'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '단체쪽지보내기'
                });
            });
        }
    }
    
    return (
        <div className="modal-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)} id="modal-form-memo-group">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        name="subject"
                                        label="제목"
                                        placeholder="제목"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>받는 사람</Label>
                                <Row>
                                    <Col>
                                        <ButtonGroup>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => setGroup('member')}
                                                active={group === 'member'}
                                            >
                                                전체 정상회원
                                            </Button>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => setGroup('agent')}
                                                active={group === 'agent'}
                                            >
                                                전체 총판회원
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <TextEditor
                                        data={watchContents}
                                        placeholder="내용을 입력하세요."
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            //console.log( { event, editor, data } );
                                            setValue('contents', data);
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            //console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            //console.log( 'Focus.', editor );
                                        } }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default MemoGroupFormModal