import React, { useEffect, useState } from "react";
import { useFieldArray, Control, UseFormWatch, UseFormSetValue } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Button,
} from 'reactstrap';

import InputControl from '../common/InputControl';
import ConfigAPI from "../../modules/ConfigAPI";

const SignupForm = (props: { control: Control<any, any>, watch: UseFormWatch<any>}) => {
    let { control, watch } = props;

    const [roleOptions, setRoleOptions] = useState<{value: any, label: any}[]>([]);
    const [levelOptions, setLevelOptions] = useState<{value: any, label: any}[]>([]);

    useEffect(() => {
        ConfigAPI.getRoles().then(( res ) => {
            if(res.data.success){
                const { total, roles } = res.data.message;

                let filterRoleOptions = [];

                for(var role of roles){
                    filterRoleOptions.push({
                        value: role.key,
                        label: role.name
                    })
                }

                setRoleOptions(filterRoleOptions);

                ConfigAPI.getLevels().then(( res ) => {
                    if(res.data.success){
                        const { total, levels } = res.data.message;
        
                        let filterLevelOptions = [];
        
                        for(var level of levels){
                            filterLevelOptions.push({
                                value: level.lidx,
                                label: level.name
                            })
                        }
        
                        setLevelOptions(filterLevelOptions);
                    }
                })
            }
        })
    }, [])

    return (
        <Card className="card-form">
            <CardHeader>
                <CardTitle tag="h5">회원가입 설정</CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_used"
                                label="회원가입 사용"
                            />
                        </FormGroup>
                        <FormGroup>
                            <InputControl
                                control={control}
                                type="select"
                                name="signup_role_key"
                                label="회원가입 등급"
                                options={roleOptions}
                            />
                        </FormGroup>
                        <FormGroup>
                            <InputControl
                                control={control}
                                type="select"
                                name="signup_level_lidx"
                                label="회원가입 레벨"
                                options={levelOptions}
                            />
                        </FormGroup>
                        <hr />
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_used_name"
                                label="이름 사용"
                            />
                        </FormGroup>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_used_fullname"
                                label="풀네임 사용"
                            />
                        </FormGroup>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_required_name"
                                label="이름입력 필수"
                            />
                        </FormGroup>
                        <hr />
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_used_email"
                                label="이메일 사용"
                            />
                        </FormGroup>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_required_email"
                                label="이메일입력 필수"
                            />
                        </FormGroup>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_verify_email"
                                label="이메일인증 사용"
                            />
                        </FormGroup>
                        <hr />
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_used_phone"
                                label="연락처 사용"
                            />
                        </FormGroup>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_required_phone"
                                label="연락처입력 필수"
                            />
                        </FormGroup>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_verify_phone"
                                label="연락처인증 사용"
                            />
                        </FormGroup>
                        <hr />
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_used_address"
                                label="주소 사용"
                            />
                        </FormGroup>
                        <FormGroup switch>
                            <InputControl 
                                control={control}
                                type="switch"
                                name="signup_required_address"
                                label="주소입력 필수"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default SignupForm