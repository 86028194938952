import React from "react";

import SettingMarketsForm from "./SettingMarketsForm";

const SettingMarketsView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingMarketsForm />
            </div>
        </div>
    )
}

export default SettingMarketsView