import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

import ConfigAPI from "../../modules/ConfigAPI";
import BetAPI from "../../modules/BetAPI";
import Util from "../../modules/Util";

interface IEventResultStatsFormProps {
    event: {
        [ key: string ]: any
    },
    eventResult: {
        [ key: string ]: any
    }
}

const EventResultStatsForm = (props: IEventResultStatsFormProps) => { 
    const { event, eventResult } = props;

    const sport = event.sport;
    const scores = eventResult.scores;
    
    let filterScores: any = {};

    for (let [index, score] of scores.entries()) {

        filterScores[score.key] = score
    }

    const stats = eventResult.stats;

    return (
        <div>
            {(sport && sport.name == 'Soccer' && Object.keys(filterScores).length > 0 && Object.keys(stats).length > 0) && 
                <>
                    <table className="table">
                        <colgroup>
                            <col width="100px"/>
                            <col/>
                            <col/>
                            <col/>
                            <col/>
                            <col/>
                            <col/>
                            <col/>
                            <col/>
                            <col/>
                        </colgroup>
                        <thead className="align-middle text-center">
                            <tr>
                                <th>&nbsp;</th>
                                <th>공격</th>
                                <th>위협적인 공격</th>
                                <th>슈팅</th>
                                <th>유효슈팅</th>
                                <th>코너킥</th>
                                <th>패널티킥</th>
                                <th>옐로우카드</th>
                                <th>레드카드</th>
                                <th>선수교체</th>
                            </tr>
                        </thead>
                        <tbody className="align-middle text-center">
                            <tr>
                                <th>홈팀</th>
                                {stats.attacks && 
                                    <td>{stats.attacks[0]}</td>
                                }
                                {stats.dangerous_attacks && 
                                    <td>{stats.dangerous_attacks[0]}</td>
                                }
                                {stats.off_target && 
                                    <td>{stats.off_target[0]}</td>
                                }
                                {stats.on_target && 
                                    <td>{stats.on_target[0]}</td>
                                }
                                {stats.corners && 
                                    <td>{stats.corners[0]}</td>
                                }
                                {stats.penalties && 
                                    <td>{stats.penalties[0]}</td>
                                }
                                {stats.yellowcards && 
                                    <td>{stats.yellowcards[0]}</td>
                                }
                                {stats.redcards && 
                                    <td>{stats.redcards[0]}</td>
                                }
                                {stats.substitutions && 
                                    <td>{stats.substitutions[0]}</td>
                                }
                            </tr>
                            <tr>
                                <th>원정팀</th>
                                {stats.attacks && 
                                    <td>{stats.attacks[1]}</td>
                                }
                                {stats.dangerous_attacks && 
                                    <td>{stats.dangerous_attacks[1]}</td>
                                }
                                {stats.off_target && 
                                    <td>{stats.off_target[1]}</td>
                                }
                                {stats.on_target && 
                                    <td>{stats.on_target[1]}</td>
                                }
                                {stats.corners && 
                                    <td>{stats.corners[1]}</td>
                                }
                                {stats.penalties && 
                                    <td>{stats.penalties[1]}</td>
                                }
                                {stats.yellowcards && 
                                    <td>{stats.yellowcards[1]}</td>
                                }
                                {stats.redcards && 
                                    <td>{stats.redcards[1]}</td>
                                }
                                {stats.substitutions && 
                                    <td>{stats.substitutions[1]}</td>
                                }
                            </tr>
                        </tbody>
                    </table>
                </>
            }

            {(sport && sport.name == 'Basketball' && Object.keys(filterScores).length > 0) && 
                <>
                    <table className="table">
                        <colgroup>
                            <col width="100px"/>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                        </colgroup>
                        <thead className="align-middle text-center">
                            <tr>
                                <th>&nbsp;</th>
                                <th>1쿼터</th>
                                <th>2쿼터</th>
                                <th>전반</th>
                                <th>3쿼터</th>
                                <th>4쿼터</th>
                                <th>전체</th>
                            </tr>
                        </thead>
                        <tbody className="align-middle text-center">
                            <tr>
                                <th>홈팀</th>
                                <td>{filterScores['1'] && filterScores['1'].home}</td>
                                <td>{filterScores['2'] && filterScores['2'].home}</td>
                                <td>{filterScores['3'] && filterScores['3'].home}</td>
                                <td>{filterScores['4'] && filterScores['4'].home}</td>
                                <td>{filterScores['5'] && filterScores['5'].home}</td>
                                <td>{filterScores['7'] && filterScores['7'].home}</td>
                            </tr>
                            <tr>
                                <th>원정팀</th>
                                <td>{filterScores['1'] && filterScores['1'].away}</td>
                                <td>{filterScores['2'] && filterScores['2'].away}</td>
                                <td>{filterScores['3'] && filterScores['3'].away}</td>
                                <td>{filterScores['4'] && filterScores['4'].away}</td>
                                <td>{filterScores['5'] && filterScores['5'].away}</td>
                                <td>{filterScores['7'] && filterScores['7'].away}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table">
                        <colgroup>
                            <col width="100px"/>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                        </colgroup>
                        <thead className="align-middle text-center">
                            <tr>
                                <th rowSpan={2}>&nbsp;</th>
                                <th>득점 (2점)</th>
                                <th>파울</th>
                                
                                <th rowSpan={2}>biggest_lead</th>
                                <th rowSpan={2}>lead_changes</th>
                                <th rowSpan={2}>maxpoints_inarow</th>
                                <th rowSpan={2}>possession</th>
                                <th rowSpan={2}>success_attempts</th>
                                <th>time_outs</th>
                            </tr>
                            <tr>
                                <th>득점 (3점)</th>
                                <th>자유투</th>
                                <th>timespent_inlead</th>
                            </tr>
                        </thead>
                        <tbody className="align-middle text-center">
                            <tr>
                                <th rowSpan={2}>홈팀</th>
                                <td>{stats['2points'] && stats['2points'][0]}</td>
                                <td>{stats['fouls'] && stats['fouls'][0]}</td>
                                <td rowSpan={2}>{stats['biggest_lead'] && stats['biggest_lead'][0]}</td>
                                <td rowSpan={2}>{stats['lead_changes'] && stats['lead_changes'][0]}</td>
                                <td rowSpan={2}>{stats['maxpoints_inarow'] && stats['maxpoints_inarow'][0]}</td>
                                <td rowSpan={2}>{stats['possession'] && stats['possession'][0]}</td>
                                <td rowSpan={2}>{stats['success_attempts'] && stats['success_attempts'][0]}</td>
                                <td>{stats['time_outs'] && stats['time_outs'][0]}</td>
                            </tr>
                            <tr>
                                <td>{stats['3points'] && stats['3points'][0]}</td>
                                <td>{stats['free_throws'] && stats['free_throws'][0]} ({stats['free_throws_rate'] && stats['free_throws_rate'][0]}%)</td>
                                <td>{stats['timespent_inlead'] && stats['timespent_inlead'][0]}</td>
                            </tr>
                            <tr>
                                <th rowSpan={2}>원정팀</th>
                                <td>{stats['2points'] && stats['2points'][1]}</td>
                                <td>{stats['fouls'] && stats['fouls'][1]}</td>
                                <td rowSpan={2}>{stats['biggest_lead'] && stats['biggest_lead'][1]}</td>
                                <td rowSpan={2}>{stats['lead_changes'] && stats['lead_changes'][1]}</td>
                                <td rowSpan={2}>{stats['maxpoints_inarow'] && stats['maxpoints_inarow'][1]}</td>
                                <td rowSpan={2}>{stats['possession'] && stats['possession'][1]}</td>
                                <td rowSpan={2}>{stats['success_attempts'] && stats['success_attempts'][1]}</td>
                                <td>{stats['time_outs'] && stats['time_outs'][1]}</td>
                            </tr>
                            <tr>
                                <td>{stats['3points'] && stats['3points'][1]}</td>
                                <td>{stats['free_throws'] && stats['free_throws'][1]} ({stats['free_throws_rate'] && stats['free_throws_rate'][1]}%)</td>
                                <td>{stats['timespent_inlead'] && stats['timespent_inlead'][1]}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            }

            {(sport && sport.name == 'Baseball' && Object.keys(filterScores).length > 0) && 
                <>
                    <table className="table">
                        <colgroup>
                            <col width="100px"/>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                        </colgroup>
                        <thead className="align-middle text-center">
                            <tr>
                                <th>&nbsp;</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                                <th>R</th>
                                <th>H</th>
                            </tr>
                        </thead>
                        <tbody className="align-middle text-center">
                            <tr>
                                <th>홈팀</th>
                                <td>{filterScores['1'] && filterScores['1'].home}</td>
                                <td>{filterScores['2'] && filterScores['2'].home}</td>
                                <td>{filterScores['3'] && filterScores['3'].home}</td>
                                <td>{filterScores['4'] && filterScores['4'].home}</td>
                                <td>{filterScores['5'] && filterScores['5'].home}</td>
                                <td>{filterScores['6'] && filterScores['6'].home}</td>
                                <td>{filterScores['7'] && filterScores['7'].home}</td>
                                <td>{filterScores['8'] && filterScores['8'].home}</td>
                                <td>{filterScores['9'] && filterScores['9'].home}</td>
                                <td>{filterScores['run'] && filterScores['run'].home}</td>
                                <td>{filterScores['hit'] && filterScores['hit'].home}</td>
                            </tr>
                            <tr>
                                <th>원정팀</th>
                                <td>{filterScores['1'] && filterScores['1'].away}</td>
                                <td>{filterScores['2'] && filterScores['2'].away}</td>
                                <td>{filterScores['3'] && filterScores['3'].away}</td>
                                <td>{filterScores['4'] && filterScores['4'].away}</td>
                                <td>{filterScores['5'] && filterScores['5'].away}</td>
                                <td>{filterScores['6'] && filterScores['6'].away}</td>
                                <td>{filterScores['7'] && filterScores['7'].away}</td>
                                <td>{filterScores['8'] && filterScores['8'].away}</td>
                                <td>{filterScores['9'] && filterScores['9'].away}</td>
                                <td>{filterScores['run'] && filterScores['run'].away}</td>
                                <td>{filterScores['hit'] && filterScores['hit'].away}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            }
        </div>
    )
}

export default EventResultStatsForm