import React, { ChangeEventHandler, useState} from "react";
import { Controller, Control } from "react-hook-form";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import { 
    Label,
    Input,
} from 'reactstrap';

type InputType =
  | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'switch'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color'
  | 'hashtag'

type TInputControl = { 
    control: Control<any, any>;
    name: string;
    type?: InputType;
    disabled?: boolean;
    selected?: any;
    checked?: any;
    label?: string;
    rules?: object;
    placeholder?: string;
    options?: {
        value: string;
        label: string;
    }[];
    className?: string;
    step?: string;
    dateFormat?: string;
}

const InputControl = ({ control, name, label, rules, placeholder, options, disabled=false, selected='', checked='', type='text', className='', step='', dateFormat='YYYY-MM-dd HH:mm:ss' } : TInputControl) => {
    const [hashtag, setHashtag] = useState<string>('');
    const [hashtags, setHashtags] = useState<string[]>([]);

    const hashtagTyping = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const _value = event.currentTarget.value.trim();

        console.log(hashtags);
    }

    const hashtagAppend = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const _value = event.currentTarget.value.trim();

        if (event.key === 'Enter' || event.key === 'NumpadEnter'){
            setHashtags((hashtags) => {
                return [...hashtags, _value];
            });

            event.currentTarget.value = '';

            event.preventDefault();
            return false;
        }else{
            if(/[^a-zA-Z0-9가-힣]+/.test(event.key)){
                event.preventDefault();
                return false;
            }else{

            }
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState }) => {
                let valid, invalid;

                if(rules && fieldState.isDirty){
                    valid = !fieldState.error && field.value ? true : undefined;
                    valid = fieldState.error && fieldState.error.type === 'required' ? false : undefined;
                    valid = fieldState.error && fieldState.error.type === 'pattern' ? false : undefined;
                
                    invalid = fieldState.error ? true : undefined;
                }

                return (
                    <>
                    {label && 
                        <Label for={name}>{label}</Label>
                    }

                    {type === 'select' && 
                        <Input 
                            {...field} 
                            type={type} 
                            placeholder={placeholder}
                            disabled={disabled}
                            bsSize="sm"
                            className={className}
                            valid={valid}
                            invalid={invalid}
                        >
                            {options!.map((option, index) => {
                                return (
                                    <option key={index} value={option.value}>{option.label}</option>
                                )
                            })}
                        </Input>
                    }

                    {type === 'switch' && 
                        <Input 
                            type={type}
                            disabled={disabled}
                            bsSize="sm"
                            className={className}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                        />
                    }

                    {type === 'radio' && 
                        <Input 
                            {...field} 
                            type={type}
                            disabled={disabled}
                            bsSize="sm"
                            className={className}
                            defaultChecked={checked}
                        />
                    }

                    {type === 'hashtag' &&
                        <div className="hashtag-control">
                            {hashtags.length > 0 && 
                                <div className="tags">
                                    {hashtags.map((hashtag, index) => {
                                        return (
                                            <div key={index} className="tag">
                                                # {hashtag}
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <Input 
                                type="text"
                                placeholder={placeholder}
                                disabled={disabled}
                                bsSize="sm"
                                className={className}
                                valid={valid}
                                invalid={invalid}
                                onKeyDown={hashtagAppend}
                            />
                        </div>
                    }

                    {type === 'number' && 
                        <Input 
                            {...field} 
                            type={type} 
                            placeholder={placeholder}
                            disabled={disabled}
                            bsSize="sm"
                            className={className}
                            valid={valid}
                            invalid={invalid}
                            step={step}
                        />
                    }

                    {/*https://reactdatepicker.com/*/}
                    {type == 'date' && 
                        <DatePicker 
                            {...field} 
                            selected={selected}
                            showIcon
                            toggleCalendarOnIconClick
                            dateFormat={dateFormat}
                            className="form-control form-control-sm d-block"
                        />
                    }

                    {type !== 'date' && type !== 'number' && type !== 'select' && type !== 'switch' && type !== 'radio' && type !== 'hashtag' && 
                        <Input 
                            {...field} 
                            type={type} 
                            placeholder={placeholder}
                            disabled={disabled}
                            bsSize="sm"
                            className={className}
                            valid={valid}
                            invalid={invalid}
                        />
                    }
                    
                    </>
                );
            }}
        />
    )
}

export default InputControl;