import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { 
    Row, 
    Col, 
    Card,
    CardHeader,
    CardTitle,
    CardBody
} from "reactstrap";
import moment from "moment";

import useModal from "../../hooks/GlobalModals";

import StatsAPI from "../../modules/StatsAPI";
import Util from "../../modules/Util";

import BarChart from "../common/Chart/BarChart";
import AmountsAPI from "../../modules/AmountsAPI";

const Main = () => {
    const configState = useSelector((state: RootState) => state.configReducer);
    const statsState = useSelector((state: RootState) => state.statsReducer)

    const { modalAlert } = useModal();

    const [ chartData, setChartData ] = useState([
        {
          "country": "AD",
          "hot dog": 143,
          "hot dogColor": "hsl(139, 70%, 50%)",
          "burger": 194,
          "burgerColor": "hsl(141, 70%, 50%)",
          "sandwich": 38,
          "sandwichColor": "hsl(109, 70%, 50%)",
          "kebab": 28,
          "kebabColor": "hsl(326, 70%, 50%)",
          "fries": 66,
          "friesColor": "hsl(155, 70%, 50%)",
          "donut": 88,
          "donutColor": "hsl(162, 70%, 50%)"
        },
        {
          "country": "AE",
          "hot dog": 177,
          "hot dogColor": "hsl(60, 70%, 50%)",
          "burger": 111,
          "burgerColor": "hsl(13, 70%, 50%)",
          "sandwich": 53,
          "sandwichColor": "hsl(162, 70%, 50%)",
          "kebab": 33,
          "kebabColor": "hsl(160, 70%, 50%)",
          "fries": 31,
          "friesColor": "hsl(269, 70%, 50%)",
          "donut": 59,
          "donutColor": "hsl(154, 70%, 50%)"
        },
        {
          "country": "AF",
          "hot dog": 49,
          "hot dogColor": "hsl(8, 70%, 50%)",
          "burger": 66,
          "burgerColor": "hsl(59, 70%, 50%)",
          "sandwich": 129,
          "sandwichColor": "hsl(155, 70%, 50%)",
          "kebab": 190,
          "kebabColor": "hsl(115, 70%, 50%)",
          "fries": 48,
          "friesColor": "hsl(23, 70%, 50%)",
          "donut": 89,
          "donutColor": "hsl(155, 70%, 50%)"
        },
        {
          "country": "AG",
          "hot dog": 125,
          "hot dogColor": "hsl(80, 70%, 50%)",
          "burger": 185,
          "burgerColor": "hsl(300, 70%, 50%)",
          "sandwich": 105,
          "sandwichColor": "hsl(347, 70%, 50%)",
          "kebab": 149,
          "kebabColor": "hsl(313, 70%, 50%)",
          "fries": 177,
          "friesColor": "hsl(132, 70%, 50%)",
          "donut": 199,
          "donutColor": "hsl(237, 70%, 50%)"
        },
        {
          "country": "AI",
          "hot dog": 59,
          "hot dogColor": "hsl(207, 70%, 50%)",
          "burger": 90,
          "burgerColor": "hsl(113, 70%, 50%)",
          "sandwich": 113,
          "sandwichColor": "hsl(124, 70%, 50%)",
          "kebab": 169,
          "kebabColor": "hsl(228, 70%, 50%)",
          "fries": 103,
          "friesColor": "hsl(303, 70%, 50%)",
          "donut": 116,
          "donutColor": "hsl(254, 70%, 50%)"
        },
        {
          "country": "AL",
          "hot dog": 109,
          "hot dogColor": "hsl(236, 70%, 50%)",
          "burger": 174,
          "burgerColor": "hsl(271, 70%, 50%)",
          "sandwich": 188,
          "sandwichColor": "hsl(243, 70%, 50%)",
          "kebab": 153,
          "kebabColor": "hsl(120, 70%, 50%)",
          "fries": 143,
          "friesColor": "hsl(41, 70%, 50%)",
          "donut": 12,
          "donutColor": "hsl(350, 70%, 50%)"
        },
        {
          "country": "AM",
          "hot dog": 75,
          "hot dogColor": "hsl(343, 70%, 50%)",
          "burger": 176,
          "burgerColor": "hsl(308, 70%, 50%)",
          "sandwich": 64,
          "sandwichColor": "hsl(239, 70%, 50%)",
          "kebab": 178,
          "kebabColor": "hsl(141, 70%, 50%)",
          "fries": 165,
          "friesColor": "hsl(279, 70%, 50%)",
          "donut": 198,
          "donutColor": "hsl(342, 70%, 50%)"
        }
      ])

    const [ amountsData, setAmountsData ] = useState({
        total: 0,
        amounts: []
    });

    useEffect(() => {
        AmountsAPI.get(1, 10, '', '', '').then(( res ) => {
            const { total, amounts } = res.data.message;

            let filterAmounts: any = [];

            for( const amount of amounts ){
                let filterAmount = { 
                    ...amount, 
                    method_sign: (amount.method == '증액') ? '+' : '-',
                    username: (configState.signup_used_fullname == 'true') ? amount.fullname : `${amount.firstname} ${amount.lastname}`
                };

                filterAmounts.push(filterAmount);
            }

            setAmountsData({ total, amounts: filterAmounts });
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '메인'
            });
        });
    }, []);

    return (
        <div className="contents">
            <div className="contents-header">
                오늘의 통계
            </div>
            <Row className="mb-2">
                <Col className="pe-1">
                    <div className="stats-summary-box">
                        <div className="subject">입금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.amounts.total.deposit)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">출금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.amounts.total.withdraw)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">회원 입금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.amounts.member.deposit)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">회원 출금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.amounts.member.withdraw)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">총판 정산금 입금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.settlements.deposit_settlement)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="ps-1">
                    <div className="stats-summary-box">
                        <div className="subject">총판 정산금 출금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.settlements.withdraw_settlement)}</b> 원
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="pe-1">
                    <div className="stats-summary-box">
                        <div className="subject">베팅금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountBets.total.amount)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">당첨금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountBets.total.amount_win)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">적특금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountBets.total.amount_except)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">베팅회원</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountBetUsers)}</b> 명
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box">
                        <div className="subject">가입회원</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountJoins)}</b> 명
                        </div>
                    </div>
                </Col>
                <Col className="ps-1">
                    <div className="stats-summary-box">
                        <div className="subject">방문회원</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountVisited)}</b> 명
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col className="pe-1">
                    <div className="stats-summary-box mb-2">
                        <div className="subject">회원 보유금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountWallets.total.amount)}</b> 원
                        </div>
                    </div>
                </Col>
                <Col className="px-1">
                    <div className="stats-summary-box mb-2">
                        <div className="subject">회원 보유보너스</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountWallets.total.amount_bonus)}</b> P
                        </div>
                    </div>
                </Col>
                <Col className="ps-1">
                    
                    <div className="stats-summary-box mb-2">
                        <div className="subject">총판 보유 정산금액</div>
                        <div className="number">
                            <b>{Util.getNumberFormat(statsState.summary.today.accountWallets.total.amount_settlement)}</b> 원
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md={4}>
                    <div className="chart-box mb-3">
                        <div className="subject">오늘 입금/출금액</div>
                        <BarChart />
                    </div>
                    <div className="chart-box">
                        <div className="subject">오늘 베팅/당첨금액</div>
                        <BarChart />
                    </div>
                </Col>
                <Col>
                <div className="contents-header">
                        최근 머니내역
                    </div>
                    <Card>
                        <CardBody>
                            <table className="table table-sm mb-0">
                                <colgroup>
                                    <col width="50px" />
                                    <col width="100px" />
                                    <col width="140px" />
                                    <col />
                                    <col width="100px" />
                                    <col width="150px" />
                                </colgroup>
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col" rowSpan={2}>번호</th>
                                        <th scope="col">타입 / 카테고리</th>
                                        <th scope="col" rowSpan={2}>아이디</th>
                                        <th scope="col" rowSpan={2}>설명</th>
                                        <th scope="col">이전금액</th>
                                        <th scope="col">등록일</th>
                                    </tr>
                                    <tr className="align-middle">
                                        <th scope="col">상태</th>
                                        
                                        <th scope="col">이후금액</th>
                                        <th scope="col">처리일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {amountsData.amounts.map(( amount: any, key ) => 
                                        <React.Fragment key={key}>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <th rowSpan={2} scope="row">{key + 1}</th>
                                                <td>{amount.type} {amount.category}</td>
                                                <td rowSpan={2}>{amount.userid} ({amount.username})</td>
                                                <td rowSpan={2}>{amount.description}</td>
                                                <td>{Util.getNumberFormat(amount.amount_before)}</td>
                                                <td>{moment(amount.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            </tr>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <td>{amount.status}</td>
                                                <td>{Util.getNumberFormat(amount.amount_after)}</td>
                                                <td>{amount.processed_at ? moment(amount.processed_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {amountsData.total <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={10} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Main