import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardTitle, 
    CardBody, 
    Form, 
    FormGroup, 
    Button, 
    InputGroup, 
    InputGroupText, 
    Label 
} from 'reactstrap';

import moment from "moment";

import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link } from "react-router-dom";

import InputControl from '../common/InputControl';
import { ITreeItem, TTreeItems } from "../../interfaces/Tree";

import Tree from "./Tree/Tree";
import { IAgent } from "../../interfaces/Agent";
import BetAPI from "../../modules/BetAPI";

import AgentMoveFormModal from "./AgentMoveFormModal";
import ChildrenMoveFormModal from "./ChildrenMoveFormModal";

const AgentsView = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalForm } = useModal();

    const [ agents, setAgents ] = useState<IAgent[]>([])
    const [ children, setChildren ] = useState<{ total: number, children: IAgent[] }>({ total: 0, children: [] })

    const [ treeItems, setTreeItems ] = useState<TTreeItems>([]);
    const [ childTreeItems, setChildTreeItems ] = useState<TTreeItems>([]);

    const [ targetTreeItem, setTargetTreeItem ] = useState<IAgent | null>(null);
    const [ targetIdx, setTargetIdx ] = useState<string | null>(null)

    const agentTypeOptions = [
        { value: 'online', label: '온라인총판' },
        { value: 'offline', label: '오프라인총판' }
    ]

    const agentSettlementTypeOptions = [
        { value: 'designated_day', label: '지정일' },
        { value: 'designated_cycle', label: '지정일주기' },
        { value: 'end_month', label: '매 월말' }
    ]

    const agentLosingTypeOptions = [
        { value: 'default', label: '개별정산' },
        { value: 'formula_1', label: '(입금 - 출금 - 포인트전환) x 요율' },
        { value: 'formula_2', label: '{(입금 - 출금 - 포인트전환) x 요율} - 하부회원보유머니' },
    ]

    const initTreeItems = (agents: IAgent[]) => {
        let filterItems: IAgent[] = [];
        let filterAgents = [];
        
        for( const agent of agents ){
            filterItems.push({ ...agent, children:[] });

            filterAgents.push({
                userid: agent.userid,
                nickname: agent.detail.nickname,
                visible: true,
            })
        }

        filterItems = combineTreeItems(filterItems);

        setTreeItems(extractTreeItems(filterItems))
    }

    const initChildTreeItems = (agents: IAgent[]) => {
        let filterItems: IAgent[] = [];
        
        for( const agent of agents ){
            filterItems.push({ ...agent });
        }

        //filterItems = combineTreeItems(filterItems);

        setChildTreeItems(extractTreeItems(filterItems))
    }

    const combineTreeItems = (items: IAgent[]): IAgent[] => {
        let targets: IAgent[] = JSON.parse(JSON.stringify(items));
        let result: IAgent[] = [];

        while( targets.length > 0 ){
            const target = targets.shift();

            if(!target){
                break;
            }else{
                const childIndex = targets.findIndex(({ detail }) => detail.uuid_agent_parent === target.uuid)

                if(childIndex >= 0){
                    targets.push(target)
                }else{
                    const parentIndex = targets.findIndex(({ uuid }) => uuid === target.detail.uuid_agent_parent)

                    if(parentIndex >= 0){
                        targets[parentIndex].children.push(target)
                    }else{
                        result.push(target)
                    }
                }
            }
        }

        return result.sort((a ,b) => (a.idx - b.idx));
    }

    const extractTreeItems = (items: IAgent[]): TTreeItems => {
        let targets = [...items];
        let result: TTreeItems = [];

        for( const target of targets ){
            if(!target.children){
                result.push({
                    id: String(target.idx),
                    userid: target.userid,
                    nickname: target.detail.nickname,
                    agent_code: target.detail.agent_code,
                    children: [],
                    targeted: false,
                    collapsed: false
                })
            }else{
                result.push({
                    id: String(target.idx),
                    userid: target.userid,
                    nickname: target.detail.nickname,
                    agent_code: target.detail.agent_code,
                    children: extractTreeItems(target.children),
                    targeted: false,
                    collapsed: true
                }) 
            }
        }

        return result;
    }

    useEffect(() => {
        const target = agents.find((agent) => agent.idx === Number(targetIdx))

        if(!target){
            setTargetTreeItem(null)
            reset()
        }else{
            setTargetTreeItem(target)

            reset({
                uuid: target.uuid,
                userid: target.userid,
                nickname: target.detail.nickname,
                //uuid_agent_root: target.detail.uuid_agent_root,
                //uuid_agent_parent: target.detail.uuid_agent_parent,
                userid_agent_root: target.detail.userid_agent_root,
                userid_agent_parent: target.detail.userid_agent_parent,

                //agent_used: target.detail.agent_used,
                agent_type: target.detail.agent_type,
                //agent_used_code: target.detail.agent_used_code,
                //agent_code: target.detail.agent_code,
                //agent_used_child: target.detail.agent_used_child,
                agent_rolling_deposit: target.detail.agent_rolling_deposit,
                agent_rolling_upcoming: target.detail.agent_rolling_upcoming,
                agent_rolling_inplay: target.detail.agent_rolling_inplay,
                agent_losing_type: target.detail.agent_losing_type,
                agent_losing_formula: target.detail.agent_losing_formula,
                agent_losing_withdraw: target.detail.agent_losing_withdraw,
                agent_losing_upcoming: target.detail.agent_losing_upcoming,
                agent_losing_inplay: target.detail.agent_losing_inplay,
                agent_settlement_type: target.detail.agent_settlement_type,
                agent_settlement_interval: target.detail.agent_settlement_interval,
                agent_settlement_base_at: moment(target.detail.agent_settlement_base_at).format('YYYY-MM-DD HH:mm:ss'),
                agent_settlement_latest_at: moment(target.detail.agent_settlement_latest_at).format('YYYY-MM-DD HH:mm:ss'),

                amount_settlement: 
                    target.wallet.amount_settlement + 
                    target.wallet.amount_rolling_deposit + 
                    target.wallet.amount_rolling_withdraw + 
                    target.wallet.amount_rolling_upcoming + 
                    target.wallet.amount_rolling_inplay + 
                    target.wallet.amount_losing_deposit + 
                    target.wallet.amount_losing_withdraw + 
                    target.wallet.amount_losing_upcoming + 
                    target.wallet.amount_losing_inplay
            })

            BetAPI.getChildren(target.uuid).then(( res ) => {
                if(res.data.success){
                    const { total, accounts } = res.data.message;

                    initChildTreeItems(accounts);
                    setChildren({ total, children: accounts })
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '총판 관리'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '총판 관리'
                    });
                }
            });
        }
    },[targetIdx]);

    let defaultValues = {
        uuid: '',
        userid: '',
        nickname: '',
        //uuid_agent_root: '',
        //uuid_agent_parent: '',
        userid_agent_root: '',
        userid_agent_parent: '',
        //agent_used: true,
        agent_type: 'online',
        //agent_used_code: false,
        //agent_code: '',
        //agent_used_child: false,
        agent_rolling_deposit: 0,
        agent_rolling_upcoming: 0,
        agent_rolling_inplay: 0,
        agent_losing_type: 'default',
        agent_losing_formula: 0,
        agent_losing_withdraw: 0,
        agent_losing_upcoming: 0,
        agent_losing_inplay: 0,
        agent_settlement_type: 'designated_day',
        agent_settlement_interval: 1,
        agent_settlement_base_at: '',
        agent_settlement_latest_at: '',

        amount_settlement: 0
    }

    const { watch, handleSubmit, control, reset, formState: { errors, isSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues
    });

    const [
        watchUuid,
        watchUserid,
        agent_settlement_type,
        agent_settlement_base_at,
        userid_agent_parent,
        agent_losing_type
    ] = watch(['uuid', 'userid', 'agent_settlement_type', 'agent_settlement_base_at', 'userid_agent_parent', 'agent_losing_type'])

    let defaultValues2 = {
        amount: 0,
        password: ''
    }

    const { watch: watch2, handleSubmit: handleSubmit2, control: control2, reset: reset2, formState: { errors: errors2, isSubmitting: isSubmitting2 } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: defaultValues2
    });

    useEffect(() => {
        BetAPI.getAgents().then(( res ) => {
            const { total, agents } = res.data.message;

            initTreeItems(agents);
            setAgents(agents)
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '총판 관리'
            });
        });
    }, [])

    /*
    useEffect(() => {
        console.log(errors)
    }, [errors])
    */

    const handleOnSubmit: SubmitHandler<any> = (data) => {
        let filterParam = { 
            uuid: data.uuid, 
            agent_settlement_type: data.agent_settlement_type, 
            agent_settlement_interval: Number(data.agent_settlement_interval), 
            agent_settlement_base_at: data.agent_settlement_base_at, 
            agent_rolling_deposit: Number(data.agent_rolling_deposit), 
            agent_rolling_upcoming: Number(data.agent_rolling_upcoming), 
            agent_rolling_inplay: Number(data.agent_rolling_inplay), 
            agent_losing_type: data.agent_losing_type, 
            agent_losing_formula: Number(data.agent_losing_formula), 
            agent_losing_withdraw: Number(data.agent_losing_withdraw), 
            agent_losing_upcoming: Number(data.agent_losing_upcoming), 
            agent_losing_inplay: Number(data.agent_losing_inplay), 
        }

        BetAPI.setAgent(filterParam).then(( res ) => {
            if(res.data.success){
                modalAlert({
                    component: <>수정되었습니다.</>,
                    title: '총판 관리'
                });

                BetAPI.getAgents().then(( res ) => {
                    const { total, agents } = res.data.message;
        
                    initTreeItems(agents);
                    setAgents(agents)
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '총판 관리'
                    });
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '총판 관리'
                });
            }
        })
        .catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '총판 관리'
            });
        });
    }

    const handleOnSubmit2: SubmitHandler<any> = (data) => {
        let filterParam = { 
            amount: data.amount,
            password: data.password
        }
    }

    const handleMoveAgent = (userid_current_parent: string) => {
        modalForm({
            formId: 'modal-form-agent-move',
            component: <AgentMoveFormModal uuid={watchUuid} userid_current_parent={userid_current_parent} />,
            title: '총판이동',
            size: 'md'
        });
    }

    const handleMoveChildren = () => {
        modalForm({
            formId: 'modal-form-children-move',
            component: <ChildrenMoveFormModal uuid={watchUuid} userid_current_parent={watchUserid} />,
            title: '하위 총판/회원 이동',
            size: 'md'
        });
    }

    return (
        <div className="contents">
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Card className="card-form">
                                <CardHeader>
                                    <CardTitle tag="h5">총판 목록</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="tree-items">
                                        <Tree 
                                            items={treeItems} 
                                            setItems={setTreeItems} 
                                            onTarget={setTargetIdx}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="card-form">
                                <CardHeader>
                                    <CardTitle tag="h5">하위 총판/회원 목록 ({children.total})</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="tree-items">
                                        <Tree 
                                            items={childTreeItems} 
                                            setItems={setChildTreeItems} 
                                        />
                                    </div>
                                    <Row className="pt-3">
                                        <Col className="text-center py-2">
                                            <Button
                                                className="btn-round"
                                                color="secondary"
                                                onClick={(event) => handleMoveChildren()}
                                            >
                                                하위 총판/회원 이동
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                </Col>
                <Col>
                <Card className="card-form">
                        <CardHeader>
                            <CardTitle tag="h5">총판 설정</CardTitle>
                        </CardHeader>
                        <CardBody className="position-relative">
                            {!targetTreeItem &&
                                <>
                                    <Row>
                                        <Col className="text-center py-5">
                                            존재하는 총판을 선택하여 설정할 수 있습니다.
                                        </Col>
                                    </Row>
                                </>
                            }

                            {targetTreeItem &&
                                <>
                                    <Form onSubmit={handleSubmit(handleOnSubmit)}>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="hidden"
                                                    name="uuid"
                                                />
                                                <InputControl 
                                                    control={control}
                                                    name="userid"
                                                    label="아이디"
                                                    placeholder="아이디"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    name="nickname"
                                                    label="닉네임"
                                                    placeholder="닉네임"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    name="userid_agent_root"
                                                    label="루트총판"
                                                    placeholder="루트총판"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <Label>상위총판</Label>
                                            <InputGroup className="w-100">
                                                <InputControl 
                                                    control={control}
                                                    name="userid_agent_parent"
                                                    disabled={true}
                                                />
                                                <Button
                                                    color="primary"
                                                    onClick={(event) => handleMoveAgent(userid_agent_parent)}
                                                >
                                                    이동
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="select"
                                                    name="agent_type"
                                                    label="총판타입"
                                                    disabled={true}
                                                    options={agentTypeOptions}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <Label>보유금액</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="amount_settlement"
                                                    placeholder="보유금액"
                                                    disabled={true}
                                                />
                                                <InputGroupText>원</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="select"
                                                    name="agent_settlement_type"
                                                    label="정산타입"
                                                    options={agentSettlementTypeOptions}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <Label>정산기준</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_settlement_interval"
                                                    placeholder="정산기준"
                                                    disabled={(agent_settlement_type == 'end_month') ? true : false}
                                                />
                                                <InputGroupText>일</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="date"
                                                    name="agent_settlement_base_at"
                                                    label="정산기준일"
                                                    placeholder="정산기준일"
                                                    selected={agent_settlement_base_at}
                                                    dateFormat='YYYY-MM-dd 00:00:00'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    name="agent_settlement_latest_at"
                                                    label="최근정산일"
                                                    placeholder="최근정산일"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Label>롤링(입금)</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_rolling_deposit"
                                                    placeholder="롤링(입금)"
                                                    step="0.01"
                                                />
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Label>롤링(프리매치)</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_rolling_upcoming"
                                                    placeholder="롤링(프리매치)"
                                                    step="0.01"
                                                />
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Label>롤링(인플레이)</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_rolling_inplay"
                                                    placeholder="롤링(인플레이)"
                                                    step="0.01"
                                                />
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-8">
                                            <FormGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="select"
                                                    name="agent_losing_type"
                                                    label="루징 정산타입"
                                                    options={agentLosingTypeOptions}
                                                    disabled={(userid_agent_parent) ? true : false}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <Label>루징(계산식)</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_losing_formula"
                                                    placeholder="루징(계산식)"
                                                    step="0.01"
                                                    disabled={(agent_losing_type == 'default') ? true : false}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Label>루징(출금)</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_losing_withdraw"
                                                    placeholder="루징(출금)"
                                                    step="0.01"
                                                    disabled={(agent_losing_type != 'default') ? true : false}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Label>루징(프리매치)</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_losing_upcoming"
                                                    placeholder="루징(프리매치)"
                                                    step="0.01"
                                                    disabled={(agent_losing_type != 'default') ? true : false}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Label>루징(인플레이)</Label>
                                            <InputGroup>
                                                <InputControl 
                                                    control={control}
                                                    type="number"
                                                    name="agent_losing_inplay"
                                                    placeholder="루징(인플레이)"
                                                    step="0.01"
                                                    disabled={(agent_losing_type != 'default') ? true : false}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center py-2">
                                            <Button
                                                className="btn-round"
                                                color="primary"
                                                type="submit"
                                            >
                                                총판 수정
                                            </Button>
                                        </Col>
                                    </Row>
                                    </Form>
                                </>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default AgentsView