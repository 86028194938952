import React, { useEffect, useState } from "react";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor, EditorConfig } from "@ckeditor/ckeditor5-core";
import { EventInfo } from "@ckeditor/ckeditor5-utils";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { FileUploadAdapter } from "./FileUploadAdapter";

interface IErrorDetails {
    phase: 'initialization' | 'runtime';
    willEditorRestart?: boolean;
}

interface ITextEditorProps {
    data: string | null | undefined;
    placeholder?: string,
    onReady?: (editor: ClassicEditor) => void;
    onError?: (error: Error, details: IErrorDetails) => void;
    onChange?: (event: EventInfo, editor: ClassicEditor) => void;
    onFocus?: (event: EventInfo, editor: ClassicEditor) => void;
    onBlur?: (event: EventInfo, editor: ClassicEditor) => void;

    isUpload?: boolean;
    uploadType?: string;
    uploadKey?: string;
}

const TextEditor = (props: ITextEditorProps) => {
    const { 
        data,
        placeholder,
        onReady,
        onError,
        onChange,
        onFocus,
        onBlur,
        isUpload,
        uploadType,
        uploadKey
    } = props;

    function FileUploadPlugin(editor: Editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return FileUploadAdapter(loader, uploadType, uploadKey);
        };
    }

    let editorConfig: EditorConfig = { 
        placeholder,
        extraPlugins: isUpload ? [FileUploadPlugin] : undefined,
        removePlugins: !isUpload ? ["EasyImage","ImageUpload","MediaEmbed"] : undefined
    }
    
    return (
        <CKEditor
            editor={ ClassicEditor }
            config={editorConfig} 
            data={data}
            onReady={onReady}
            onError={onError}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    )
}

export default TextEditor;