import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

import ConfigAPI from "../../modules/ConfigAPI";

interface ISearchFormProps {
    setSearchForms: Dispatch<SetStateAction<any>>;
}

const SearchForm = (props: ISearchFormProps) => {
    const { setSearchForms } = props;

    const [roleKeyOptions, setRoleKeyOptions] = useState<{value: any, label: any}[]>([]);
    
    const { modalAlert } = useModal();

    useEffect(() => {
        ConfigAPI.getRoles().then(( res ) => {
            if(res.data.success){
                const { total, roles } = res.data.message;

                let filterRoleOptions = [{
                    value: '',
                    label: '전체'
                }];

                for(var role of roles){
                    filterRoleOptions.push({
                        value: role.ruid,
                        label: role.name
                    })
                }

                setRoleKeyOptions(filterRoleOptions);
            }else{
                modalAlert({
                    component: <>Unable to Initialize Application.</>,
                    title: 'Warning'
                });
            }
        })
    }, []);

    return (
        <div className="search-wrapper">
            <Card className="card-form-search">
                <CardHeader>
                    <CardTitle tag="h5">검색</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="2">
                            <Label for="ruid">회원등급</Label>
                            <Input
                                type="select"
                                name="ruid"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, ruid: e.target.value} })}
                            >
                                {roleKeyOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="10">
                            <Label for="keyword">검색어</Label>
                            <Input
                                name="keyword"
                                placeholder="검색어 ( 아이디, 이름 ... )"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, keyword: e.target.value} })}
                            />
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </div>
    )
}

export default SearchForm