import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler, useFieldArray, FieldError, FieldErrors, FieldValue } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button,
    InputGroup,
    Label,
    Input,
    InputGroupText
} from 'reactstrap';

import Util from '../../modules/Util';
import AccountAPI from "../../modules/AccountAPI";
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';

import PhonesForm from "./PhonesForm";
import EmailsForm from "./EmailsForm";
import AddressesForm from "./AddressesForm";
import PassbooksForm from "./PassbooksForm";

import { IAccount } from "../../interfaces/Account";

import profileDefaultImage from "../../assets/img/profile-default.svg";
import AmountAPI from "../../modules/AmountAPI";

import AgentMoveFormModal from "./AgentMoveFormModal";

const AccountForm = () => {
    const { uuid } = useParams();

    const authState = useSelector((state: RootState) => state.authReducer)
    const configState = useSelector((state: RootState) => state.configReducer);
    const modalState = useSelector((state: RootState) => state.modalReducer);

    const [roleOptions, setRoleOptions] = useState<{value: any, label: any}[]>([]);
    const [levelOptions, setLevelOptions] = useState<{value: any, label: any}[]>([]);
    const [passwordRequired, setPasswordRequired] = useState<boolean>(true);    

    const [procAmount, setProcAmount] = useState<number>(0);
    const [procAmountBonus, setProcAmountBonus] = useState<number>(0);

    const [formErrorMessage, setFormErrorMessage] = useState<string>('');

    const { modalAlert, modalForm } = useModal();

    const location = useLocation();

    const typeOptions = [
        { value: 'default', label: '기본회원' },
        { value: 'test', label: '테스트회원' },
        { value: 'interest', label: '관심회원' }
    ]

    const agentTypeOptions = [
        { value: 'online', label: '온라인총판' },
        { value: 'offline', label: '오프라인총판' }
    ]

    let defaultValues: IAccount = {
        type: 'default',
        role: 'waiter',
        level: 0,
        userid: '',
        password: '',
        passwordConfirm: '',
        password_withdraw: '',
        nickname: '',
        agent_parent_code: '',
        addresses: [],
        phones: [],
        emails: [],
        passbooks: []
    };

    if(uuid){
        defaultValues = {
            ...defaultValues, 
            userid_agent_root: '',
            userid_agent_parent: '',
            amount: 0,
            amount_bonus: 0,
            agent_used: false,
            agent_type: 'online',
            agent_used_code: false,
            agent_code: '',
            agent_used_child: false,
        }
    }

    if(Util.getBoolean(configState.signup_used_fullname)){
        defaultValues = {...defaultValues, fullname: ''}
    }else{
        defaultValues = {...defaultValues, firstname: '', lastname: ''}
    }

    const addressesDefaultValues = {
        default: false,
        type: 'home', 
        postalcode: '', 
        city: '', 
        country: '', 
        address: '' 
    };

    const phonesDefaultValues = {
        default: false,
        type: 'cell', 
        dialin: '82', 
        number: '' 
    };

    const emailsDefaultValues = {
        default: false,
        type: 'private', 
        email: '' 
    };

    let passbooksDefaultValues = {
        default: false,
        bankcode: '023',
        holder: '',
        number: ''
    };

    const { watch, handleSubmit, control, reset, getValues, setFocus, setError, clearErrors, formState } = useForm<IAccount>({
        mode: 'onSubmit', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const [
        userid, 
        password, 
        role,
        level,
        agent_used_code,
        userid_agent_parent,
        watchAddressesFields, 
        watchPhonesFields, 
        watchEmailsFields,
        watchPassbooksFields,
    ] = watch(['userid', 'password', 'role', 'level', 'agent_used_code', 'userid_agent_parent', 'addresses', 'phones', 'emails', 'passbooks'])

    const addressFields = useFieldArray({
        control: control, 
        name: 'addresses'
    });

    const phoneFields = useFieldArray({
        control: control, 
        name: 'phones'
    });

    const emailFields = useFieldArray({
        control: control, 
        name: 'emails'
    });

    const passbookFields = useFieldArray({
        control: control, 
        name: 'passbooks'
    });

    useEffect(() => {
        ConfigAPI.getRoles().then(( res ) => {
            if(res.data.success){
                const { total, roles } = res.data.message;

                let filterRoleOptions = [];

                for(var role of roles){
                    filterRoleOptions.push({
                        value: role.key,
                        label: role.name
                    })
                }

                setRoleOptions(filterRoleOptions);

                ConfigAPI.getLevels().then(( res ) => {
                    if(res.data.success){
                        const { total, levels } = res.data.message;
        
                        let filterLevelOptions = [];
        
                        for(var level of levels){
                            filterLevelOptions.push({
                                value: level.lidx,
                                label: level.name
                            })
                        }
        
                        setLevelOptions(filterLevelOptions);

                        reset(defaultValues);

                        if(uuid){
                            AccountAPI.get(uuid, true).then((res) => {
                                if(res.data.success){
                                    const _account = res.data.message;

                                    console.log(_account.passbooks)

                                    let resetValues: IAccount = {
                                        role: _account.role.key,
                                        level: _account.level.lidx,
                                        type: _account.type,
                                        userid: _account.userid,
                                        userid_agent_root: _account.userid_agent_root,
                                        userid_agent_parent: _account.userid_agent_parent,
                                        password: '',
                                        passwordConfirm: '',
                                        password_withdraw: (_account.password_withdraw) ? _account.password_withdraw : '',
                                        nickname: (_account.nickname) ? _account.nickname : '',
                                        amount: _account.amount,
                                        amount_bonus: _account.amount_bonus,
                                        agent_used: Util.getBoolean(_account.agent_used),
                                        agent_type: _account.agent_type,
                                        agent_used_code: Util.getBoolean(_account.agent_used_code),
                                        agent_code: _account.agent_code ? _account.agent_code :'',
                                        agent_used_child: Util.getBoolean(_account.agent_used_child),
                                        addresses: _account.addresses,
                                        phones: _account.phones,
                                        emails: _account.emails,
                                        passbooks: _account.passbooks.map((passbook: any) => ({
                                            default: passbook.default,
                                            bankcode: passbook.bankcode,
                                            //bankname: passbook.bankname,
                                            holder: passbook.holder,
                                            number: passbook.number
                                        }))
                                    }

                                    if(Util.getBoolean(configState.signup_used_fullname)){
                                        resetValues = {
                                            ...resetValues, 
                                            fullname: (_account.fullname) ? _account.fullname : ''
                                        }
                                    }else{
                                        resetValues = {
                                            ...resetValues, 
                                            firstname: (_account.firstname) ? _account.firstname : '', 
                                            lastname: (_account.lastname) ? _account.lastname : ''
                                        }
                                    }

                                    reset(resetValues);

                                    setPasswordRequired(false);
                                }else{
                                    modalAlert({
                                        component: <>This account does not exist.</>,
                                        title: 'Warning'
                                    });
                                }
                            });
                        }
                    }else{
                        modalAlert({
                            component: <>Unable to Initialize Application1.</>,
                            title: 'Warning'
                        });
                    }
                })
            }else{
                modalAlert({
                    component: <>Unable to Initialize Application1.</>,
                    title: 'Warning'
                });
            }
        })
    }, [location]);

    useEffect(() => {
        const errors = formState.errors;

        if(Object.keys(errors).length > 0){
            let filterErrorMesage = '';

            for( const error in errors ){
                const errorKey: keyof IAccount = error;

                filterErrorMesage = errors[errorKey]?.message as string ?? '';
                break;
            }

            if(filterErrorMesage){
                setFormErrorMessage(filterErrorMesage as string);
                clearErrors();
            }
        }
    }, [formState])

    useEffect(() => {
        if(!modalState.modalVisible && formErrorMessage){
            modalAlert({
                component: <>{formErrorMessage}</>,
                title: '회원 추가/수정'
            });

            setFormErrorMessage('');
        }
    }, [formErrorMessage])

    const handleOnSubmit: SubmitHandler<IAccount> = (formData) => {
        let { passwordConfirm, userid_agent_root, userid_agent_parent, ...formParams} = formData

        if(Util.getBoolean(configState.signup_required_address) && watchAddressesFields.length <= 0){
            addressFields.append(addressesDefaultValues);
            //setError('addresses.0.address', { type: 'leastone', message: 'Please enter at least one address.' });

            modalAlert({
                component: <>Please enter at least one address.</>,
                title: '회원 추가/수정'
            })

            return false;
        }

        for( const _field of watchAddressesFields ){
            if(!_field.type || !_field.postalcode || !_field.address){
                modalAlert({
                    component: <>Please enter the correct address.</>,
                    title: '회원 추가/수정'
                });

                return false;
            }
        }

        if(Util.getBoolean(configState.signup_required_phone) && watchPhonesFields.length <= 0){
            phoneFields.append(phonesDefaultValues);
            //setError('phones.0.number', { type: 'leastone', message: 'Please enter at least one mobile phone number.' });

            modalAlert({
                component: <>Please enter at least one mobile phone number.</>,
                title: '회원 추가/수정'
            })

            return false;
        }

        for( const _field of watchPhonesFields ){
            if(!_field.type || !_field.dialin || !_field.number){
                modalAlert({
                    component: <>Please enter the correct mobile phone number.</>,
                    title: '회원 추가/수정'
                });

                return false;
            }
        }

        if(Util.getBoolean(configState.signup_required_email) && watchEmailsFields.length <= 0){
            emailFields.append(emailsDefaultValues);
            //setError('emails.0.email', { type: 'leastone', message: 'Please enter at least one email.' });

            modalAlert({
                component: <>Please enter at least one email.</>,
                title: '회원 추가/수정'
            })

            return false;
        }

        for( const _field of watchEmailsFields ){
            if(!_field.type || !_field.email){
                modalAlert({
                    component: <>Please enter the correct email.</>,
                    title: '회원 추가/수정'
                });

                return false;
            }
        }

        if(watchPassbooksFields.length <= 0){
            passbookFields.append({ ...passbooksDefaultValues, default: true });
            //setError('passbooks.0.bankcode', { type: 'leastone', message: 'Please enter at least one passbook.' });

            modalAlert({
                component: <>Please enter at least one passbook.</>,
                title: '회원 추가/수정'
            })

            return false;
        }

        for( const _field of watchPassbooksFields ){
            if(!_field.bankcode || !_field.holder || !_field.number){
                modalAlert({
                    component: <>Please enter the correct passbook.</>,
                    title: '회원 추가/수정'
                });

                return false;
            }
        }

        if(uuid){
            formParams = {...formParams, uuid}

            console.log('formParams', formParams)

            AccountAPI.update(formParams).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>수정되었습니다.</>,
                        title: '회원 추가/수정'
                    });

                    reset(formParams);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '회원 추가/수정'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '회원 추가/수정'
                    });
                }
            })
        }else{
            AccountAPI.create(formParams).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>등록되었습니다.</>,
                        title: '회원 추가/수정'
                    });
                }else{
                    reset(formParams);

                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '회원 추가/수정'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '회원 추가/수정'
                    });
                }
            })
        }
    }

    const handleSetAmount = (uuid: string, method: string, category: string, amount: number, description: string = '') => {
        const amount_before = getValues('amount');

        AmountAPI.set({uuid, method, type: 'amount', category, description, amount}).then(( res ) => {
            if(res.data.success){
                if(typeof amount_before == 'number'){
                    const controlValues= getValues();

                    if(method == 'increase'){
                        reset({
                            ...controlValues,
                            amount: amount_before + amount
                        })
                    }else{
                        reset({
                            ...controlValues,
                            amount: amount_before - amount
                        })
                    }
                }

                modalAlert({
                    component: <>정상적으로 처리되었습니다.</>,
                    title: '회원 추가/수정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '회원 추가/수정'
                });
            }
        }).catch((error) => {
            const res = error.response;

            if(res){
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '회원 추가/수정'
                });
            }
        });

        setProcAmount(0);
    }

    const handleSetBonus = (uuid: string, method: string, category: string, amount: number, description: string = '') => {
        const amount_bonus_before = getValues('amount_bonus');

        AmountAPI.set({uuid, method, type: 'bonus', category, description, amount}).then(( res ) => {
            if(res.data.success){
                if(typeof amount_bonus_before == 'number'){
                    const controlValues = getValues();

                    if(method == 'increase'){
                        reset({
                            ...controlValues,
                            amount_bonus: amount_bonus_before + amount
                        })
                    }else{
                        reset({
                            ...controlValues,
                            amount_bonus: amount_bonus_before - amount
                        })
                    }
                }

                modalAlert({
                    component: <>정상적으로 처리되었습니다.</>,
                    title: '회원 추가/수정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '회원 추가/수정'
                });
            }
        }).catch((error) => {
            const res = error.response;

            if(res){
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '회원 추가/수정'
                });
            }
        });

        setProcAmountBonus(0);
    }

    const handleMoveAgent = (userid_current_parent: string) => {
        modalForm({
            formId: 'modal-form-agent-move',
            component: <AgentMoveFormModal uuid={uuid} userid_current_parent={userid_current_parent} />,
            title: '총판이동',
            size: 'md'
        });
    }
    
    return (
        <div className="account-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Row>
                    <Col lg="6">
                        <Row>
                            <Col>
                                <Card className="card-form">
                                    <div className="image">
                                        <img src={profileDefaultImage} alt="..." />
                                    </div>
                                    <CardBody>
                                        <div className="author">
                                            <img
                                                alt="..."
                                                className="avatar"
                                                src={profileDefaultImage}
                                            />
                                        </div>
                                        <Row>
                                            <Col className="pe-1">
                                                <FormGroup>
                                                    <InputControl
                                                        control={control}
                                                        type="select"
                                                        name="type"
                                                        label="회원타입"
                                                        options={typeOptions}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="ps-1">
                                                <FormGroup>
                                                    <InputControl
                                                        control={control}
                                                        type="select"
                                                        name="role"
                                                        label="회원등급"
                                                        options={roleOptions}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pe-1">
                                                <FormGroup>
                                                    <InputControl
                                                        control={control}
                                                        type="select"
                                                        name="level"
                                                        label="회원레벨"
                                                        options={levelOptions}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="ps-1">
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="userid"
                                                        label="아이디"
                                                        placeholder="아이디"
                                                        rules={{
                                                            required: true,
                                                            pattern: /^[a-z,0-9]{3,30}$/
                                                            //pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                                        }}
                                                        disabled={!!uuid}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pe-1">
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        type="password"
                                                        name="password"
                                                        label="비밀번호"
                                                        placeholder="비밀번호"
                                                        rules={{
                                                            required: passwordRequired,
                                                            minLength: 6
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="ps-1">
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        type="password"
                                                        name="passwordConfirm"
                                                        label="비밀번호 확인"
                                                        placeholder="비밀번호 확인"
                                                        rules={{
                                                            required: passwordRequired,
                                                            minLength: 6,
                                                            validate: {value: (value: string) => value == password}
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {Util.getBoolean(configState.signup_used_fullname)
                                            ?
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <InputControl 
                                                            control={control}
                                                            name="fullname"
                                                            label="이름"
                                                            placeholder="이름"
                                                            rules={{
                                                                required: Util.getBoolean(configState.signup_required_name)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col className="pe-1">
                                                    <FormGroup>
                                                        <InputControl 
                                                            control={control}
                                                            name="firstname"
                                                            label="이름"
                                                            placeholder="이름"
                                                            rules={{
                                                                required: Util.getBoolean(configState.signup_required_name)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="ps-1">
                                                    <FormGroup>
                                                        <InputControl 
                                                            control={control}
                                                            name="lastname"
                                                            label="이름 (성)"
                                                            placeholder="이름 (성)"
                                                            rules={{
                                                                required: Util.getBoolean(configState.signup_required_name)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="nickname"
                                                        label="닉네임"
                                                        placeholder="닉네임"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="password_withdraw"
                                                        label="출금 비밀번호 (숫자 4자리 이상)"
                                                        placeholder="출금 비밀번호"
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: '출금 비밀번호를 입력해주세요.'
                                                            },
                                                            pattern: {
                                                                value: /^[0-9]{4,}$/,
                                                                message: '형식에 맞지 않는 출금 비밀번호입니다.'
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {!uuid && 
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <InputControl 
                                                            control={control}
                                                            name="agent_parent_code"
                                                            label="총판코드"
                                                            placeholder="총판코드"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }

                                        {uuid && 
                                            <>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Label>보유머니</Label>
                                                        <InputGroup className="w-100">
                                                            <InputControl 
                                                                control={control}
                                                                type="text"
                                                                name="amount"
                                                                disabled={true}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col>
                                                        <Label>보유머니 처리</Label>
                                                        <InputGroup className="w-100">
                                                            <Input 
                                                                type="number"
                                                                id="proc_amount"
                                                                placeholder="0"
                                                                bsSize="sm"
                                                                value={procAmount}
                                                                onChange={(event) => setProcAmount(Number(event.currentTarget.value))}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                onClick={(event) => handleSetAmount(uuid, 'increase', 'payment', Number(procAmount))}
                                                            >
                                                                지급
                                                            </Button>
                                                            <Button
                                                                color="danger"
                                                                onClick={(event) => handleSetAmount(uuid, 'decrease', 'recovery', Number(procAmount))}
                                                            >
                                                                회수
                                                            </Button>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                    <Col>
                                                        <Label>보유보너스</Label>
                                                        <InputGroup className="w-100">
                                                            <InputControl 
                                                                control={control}
                                                                name="amount_bonus"
                                                                disabled={true}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col>
                                                        <Label>보유보너스 처리</Label>
                                                        <InputGroup className="w-100">
                                                            <Input 
                                                                type="number"
                                                                id="proc_amount_bonus"
                                                                placeholder="0"
                                                                bsSize="sm"
                                                                value={procAmountBonus}
                                                                onChange={(event) => setProcAmountBonus(Number(event.currentTarget.value))}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                onClick={(event) => handleSetBonus(uuid, 'increase', 'payment', Number(procAmountBonus))}
                                                            >
                                                                지급
                                                            </Button>
                                                            <Button
                                                                color="danger"
                                                                onClick={(event) => handleSetBonus(uuid, 'decrease', 'recovery', Number(procAmountBonus))}
                                                            >
                                                                회수
                                                            </Button>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <FormGroup>
                                                            <InputControl 
                                                                control={control}
                                                                name="userid_agent_root"
                                                                label="루트총판"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <Label>상위총판</Label>
                                                        <InputGroup className="w-100">
                                                            <InputControl 
                                                                control={control}
                                                                name="userid_agent_parent"
                                                                disabled={true}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                onClick={(event) => handleMoveAgent(userid_agent_parent)}
                                                            >
                                                                이동
                                                            </Button>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <table className="table">
                                                            <colgroup>
                                                                <col width="150px"/>
                                                                <col />
                                                                <col />
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">항목</th>
                                                                    <th scope="col">사용</th>
                                                                    <th scope="col">설정</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="align-middle text-center">
                                                                    <th scope="row">총판</th>
                                                                    <td>
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name="agent_used"
                                                                            />
                                                                        </FormGroup>
                                                                    </td>
                                                                    <td>
                                                                        <InputControl 
                                                                            control={control}
                                                                            type="select"
                                                                            name="agent_type"
                                                                            options={agentTypeOptions}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr className="align-middle text-center">
                                                                    <th scope="row">총판코드</th>
                                                                    <td>
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name="agent_used_code"
                                                                            />
                                                                        </FormGroup>
                                                                    </td>
                                                                    <td>
                                                                    <InputControl 
                                                                            control={control}
                                                                            name="agent_code"
                                                                            placeholder="총판코드"
                                                                            disabled={!agent_used_code}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr className="align-middle text-center">
                                                                    <th scope="row">하부총판</th>
                                                                    <td>
                                                                        <FormGroup switch className="mb-0">
                                                                            <InputControl 
                                                                                control={control}
                                                                                type="switch"
                                                                                name="agent_used_child"
                                                                            />
                                                                        </FormGroup>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row>
                            <Col>
                                <AddressesForm control={control} watch={watch} errors={formState.errors} addressFields={addressFields}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PhonesForm control={control} watch={watch} errors={formState.errors} phoneFields={phoneFields} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <EmailsForm control={control} watch={watch} errors={formState.errors} emailFields={emailFields} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PassbooksForm control={control} watch={watch} errors={formState.errors} passbookFields={passbookFields}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            type="submit"
                            color="primary"
                            disabled={formState.isSubmitting} 
                        >
                            회원 {uuid ? '수정' : '추가'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default AccountForm