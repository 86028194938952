import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button,
    Label
} from 'reactstrap';
import * as DOMPurify from 'dompurify';

import Util from '../../modules/Util';
import AccountAPI from "../../modules/AccountAPI";
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';
import TextEditor from '../common/Editor/TextEditor';

import { IBoard } from "../../interfaces/Board";
import BoardAPI from "../../modules/BoardAPI";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IParamPost, IPost } from "../../interfaces/Post";
import { hideModal } from "../../redux/reducers/modalReducer";
import BoardPostAPI from "../../modules/BoardPostAPI";

const PostViewModal = (props: any) => {
    const { key_board, type_board, uid_post } = props;

    const dispatch = useDispatch();

    const handleHideModal = () => dispatch(hideModal());

    const authState = useSelector((state: RootState) => state.authReducer);
    const configState = useSelector((state: RootState) => state.configReducer);

    const [ post, setPost ] = useState<IPost>({
        writer: '',
        subject: '',
        content: ''
    });

    const { modalAlert } = useModal();

    let defaultValues: any = {
        content: ''
    };

    const { watch, handleSubmit, control, reset, register, setValue } = useForm<any>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    useEffect(() => {
        if(!key_board || !uid_post){
            modalAlert({
                component: <>Incorrect post.</>,
                title: '게시글 보기'
            });
        }else{
            BoardPostAPI.get(key_board, uid_post).then(( res ) => {
                if(res.data.success){
                    const post = res.data.message;

                    console.log('post', post)

                    setPost(post);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시글 보기'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시글 보기'
                    });
                }
            })
        }
    }, []);

    const handleOnSubmit: SubmitHandler<IParamPost> = (formData) => {
        let { ...formParams } = formData;

        BoardPostAPI.setAnswer(key_board, {...formParams, uid_post}).then(( res ) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리되었습니다.</>,
                    title: '게시글 답변'
                });
            }else{
                reset(formParams);

                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '게시글 답변'
                });
            }
        }).catch((error) => {
            const res = error.response;

            if(res){
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '게시글 답변'
                });
            }
        })
    }
    
    return (
        <div className="modal-post-view">
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <div className="subject">{post.subject}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="writer">
                                <span>작성자: </span>
                                {post.writer}
                            </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <div className={`content ${type_board}`}>
                                <div 
                                    dangerouslySetInnerHTML={{ 
                                        __html: DOMPurify.sanitize(post.content) 
                                    }}
                                >
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {(type_board == 'qna' && !post.answer) && 
                        <Form onSubmit={handleSubmit(handleOnSubmit)} id="modal-form-answer">
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputControl
                                            control={control}
                                            type="textarea"
                                            name="content"
                                            placeholder="답변을 입력해주세요."
                                            className="form-control-answer"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    }

                    {(type_board == 'qna' && post.answer) && 
                        <Row>
                            <Col>
                                <div className="content-title">답변</div>
                                <div className="content">{post.answer.content}</div>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default PostViewModal