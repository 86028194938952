import React, { useState, useEffect } from "react";
import { useFieldArray, Control, UseFormWatch, UseFieldArrayUpdate, UseFieldArrayReturn, FieldErrors } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    FormFeedback,
    Button,
} from 'reactstrap';

import InputControl from '../common/InputControl';
import RadioControlBox from '../common/RadioArrayControlBox';

const InputDomains = (props: { control: Control<any>, controlUpdate: UseFieldArrayUpdate<any, any>, index: number, field: Record<'id', string>}) => {
    const { control, controlUpdate, index, field } = props;

    /*
    const value = useWatch({
        control,
        name: 'domains'
    });
    */

    return (
        <Col>
            <Row>
                <Col>
                    <InputControl 
                        control={control}
                        name={`domains.${index}.domain`}
                        placeholder="domain.com"
                    />
                </Col>
            </Row>
        </Col>
    )
};

const DomainsForm = (props: { control: Control<any, any>, watch: UseFormWatch<any>, errors: FieldErrors<any>, domainFields: UseFieldArrayReturn<any, "domains", "id"> }) => {
    let { control, watch, errors, domainFields } = props;

    //const [errorLeastone, setErrorLeastone] = useState('');

    let domainsDefaultValues = {
        domain: ''
    };

    const watchDomainFields = watch('domains');

    const fieldAppend = () => {
        if(watchDomainFields.length < 5)
            domainFields.append(domainsDefaultValues);
    }

    const fieldRemove = (index: number) => {                    
        domainFields.remove(index);
    }

    useEffect(() => {
        /*
        if(watchDomainFields.length <= 0)
            domainFields.append(domainsDefaultValues);
        */
    }, [watchDomainFields])

    /*
    useEffect(() => {
        if(
            errors && 
            errors.emails && 
            Array.isArray(errors.emails) && 
            errors.emails[0].email && 
            errors.emails[0].email.type == 'leastone'
        ){
            setErrorLeastone(errors.emails[0].email.message);
        }
    },  [errors])
    */

    return (
        <Card className="card-form">
            <CardHeader>
                <CardTitle tag="h5">사이트 도메인 설정</CardTitle>
            </CardHeader>
            <CardBody>
                {domainFields.fields.map(( field, index ) => {
                    return(
                        <React.Fragment key={index}>
                            {/*
                                errorLeastone && 
                                <div className="form-feedback-custom invalid mb-2">
                                    {errorLeastone}
                                </div>
                            */}
                            <Row className="align-items-center mb-3">
                                <InputDomains key={field.id} control={control} controlUpdate={domainFields.update} index={index} field={field}/>
                                <Col className="ps-1" md="2">
                                    <Button className="d-block w-100 btn-round" onClick={() => { fieldRemove(index) }}>
                                        <i className="fa fa-times"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                })}

                {domainFields.fields.length <= 0 &&
                    <Row>
                        <Col className="text-center py-5">
                            "도메인 추가" 버튼으로 도메인을 추가할 수 있습니다.
                        </Col>
                    </Row>
                }

                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            color="secondary"
                            type="button"
                            onClick={fieldAppend}
                        >
                            <i className="fa fa-plus"></i> 도메인 추가
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default DomainsForm