import { AxiosPromise } from "axios";
import API from "./API";
import { IConfig, IParamNavigations, IParamPermissions, IParamPermissionBridges, IParamRoles, IParamLevels } from "../interfaces/Config";
import { ROLES_KEY_DEFAULT, TRoleKey } from "../types/Config";

const ConfigAPI = {
    getModifires: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_MODIFIRES_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getCountries: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_COUNTRIES_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getDomains: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_DOMAINS_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    setBasics: (params: IConfig): AxiosPromise => {
        return API({
            method: 'patch',
            data: params,
            url: process.env.REACT_APP_API_CONFIG_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getRole: (ruid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_ROLE_URL + '/' + ruid
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getRoles: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_ROLES_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    setRoles: (params: IParamRoles): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_CONFIG_ROLES_URL,
            data: params,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getLevel: (lidx: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_LEVEL_URL + '/' + lidx
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getLevels: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_LEVELS_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    setLevels: (params: IParamLevels): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_CONFIG_LEVELS_URL,
            data: params,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getPermissionBridges: (ruid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_PERMISSION_BRIDGES_URL + '/' + ruid
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    updatePermissionBridges: (params: IParamPermissionBridges): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_CONFIG_PERMISSION_BRIDGES_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    /*
    updateRolePermissions: (params: IConfigRolePermissions): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_CONFIG_ROLE_PERMISSIONS_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    */
    isRolesKeyDefault:(value: string): boolean => {
        return ROLES_KEY_DEFAULT.includes(value as TRoleKey)
    },
    isLevelsKeyDefault:(value: string): boolean => {
        return ROLES_KEY_DEFAULT.includes(value as TRoleKey)
    },
    getAccess: (type: string, page: number = 1, limit: number = 10): AxiosPromise => {
        let _query = (page <= 1) ? '' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_ACCESS_URL + '/' + type + `?${_query}`
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getPermissions: (page: number = 1, limit: number = 10): AxiosPromise => {
        let _query = (page <= 1) ? '' : `&page=${page}`;
        _query += (limit < 0) ? '&limit=1' : `&limit=${limit}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_PERMISSIONS_URL + `?${_query}`
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    updatePermissions: (params: IParamPermissions): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_CONFIG_PERMISSIONS_URL,
            data: params,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getNavigationRoles: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_NAVIGATION_ROLES_URL,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getNavigations: (ruid: string = '', keyword: string = ''): AxiosPromise => {
        let _query = (ruid) ? `&ruid=${ruid}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_NAVIGATIONS_URL + `?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    updateNavigations: (params: IParamNavigations): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_CONFIG_NAVIGATIONS_URL,
            data: params,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getBanks: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_BANKS_URL,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

export default ConfigAPI;