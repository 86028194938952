import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    Button
} from 'reactstrap';

import InputControl from '../common/InputControl';
import Pagination from "../common/Pagination";

import useModal from "../../hooks/GlobalModals";
import ConfigAPI from "../../modules/ConfigAPI";
import PermissionBridgesFormModal from "./PermissionBridgesFormModal";


const SettingLevelsForm = () => {
    const [ data, setData ] = useState({
        total: 0,
        levels: []
    });
    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const navigate = useNavigate();

    const { modalAlert, modalForm } = useModal();

    const levelsDefaultValues = {
        luid: '',
        name: '',
    };

    let defaultValues = {   
        levels: [levelsDefaultValues]
    }

    const { watch, handleSubmit, control, reset, formState: { errors, isSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues
    });

    const watchLevelFields = watch('levels');

    const levelFields = useFieldArray({
        control: control, 
        name: 'levels'
    });

    const fieldAppend = () => {
        let filterLevels = [];

        for( const level of watchLevelFields ){
            filterLevels.push(level);
        }

        filterLevels.push(levelsDefaultValues);

        reset({
            levels: filterLevels
        });
    }

    const fieldRemove = (index: number) => {                    
        levelFields.remove(index);
    }

    useEffect(() => {
        ConfigAPI.getLevels().then(( res ) => {
            if(res.data.success){
                const data = res.data.message;

                setData(data);

                let filterLevels = [];

                for( const level of data.levels ){
                    filterLevels.push({
                        luid: level.luid,
                        name: level.name
                    })
                }

                reset({
                    levels: filterLevels
                });
            }
        });
    }, [page, limit]);

    const handleEdit = (luid: string, level_name: string) => {
        console.log('luid', luid)
        console.log('level_name', level_name)
        /*
        modalForm({
            formId: 'modal-form-role-permission',
            component: <PermissionBridgesFormModal ruid={ruid} role_name={role_name} />,
            title: '회원등급별 회원권한 수정'
        });
        */
    }

    const handleOnSubmit: SubmitHandler<any> = (data) => {
        console.log(data);

        ConfigAPI.setLevels(data).then(( res ) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리가 완료되었습니다.</>,
                    title: '회원레벨 설정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '회원레벨 설정'
                });
            }
        }).catch(( error ) => {
            const req = error.request;
            const res = error.response;

            if (res) {
                const errorDetails = res.data.message.details;

                console.log('[ ' + res.status + ' ] Error Message:', errorDetails[0].message);

                modalAlert({
                    component: <>{errorDetails[0].message}</>,
                    title: '회원레벨 설정'
                });
            } else if (req) {
                console.log(req);
            } else {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '회원레벨 설정'
                });
            }
        });
    }
    
    return (
        <div className="settings-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Row className="mb-3">
                                    <Col>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                className="btn-round"
                                                type="button"
                                                color="secondary" 
                                                onClick={fieldAppend}
                                            >
                                                <i className="fa fa-plus"></i> 회원레벨 추가
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Card className="card-form">
                                    <CardHeader>
                                        <CardTitle tag="h5">회원레벨 설정</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="row">번호</th>
                                                    <th scope="col">레벨명</th>
                                                    <th scope="col">관리</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {levelFields.fields.map((level: any, index) => {
                                                    return (
                                                        <tr key={level.id} className="align-middle">
                                                            <th scope="row">{index + 1}</th>
                                                            <td>
                                                                <InputControl 
                                                                    control={control}
                                                                    name={`levels.${index}.name`}
                                                                    placeholder="레벨명"
                                                                    disabled={(index == 0)}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                {index > 0 &&
                                                                    <Button
                                                                        className="btn-table-icon"
                                                                        type="button"
                                                                        color="danger"
                                                                        onClick={() => { fieldRemove(index) }}
                                                                    >
                                                                        <i className="fas fa-trash" />
                                                                    </Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                {levelFields.fields.length <= 0 && 
                                                    <tr className="align-middle">
                                                        <td colSpan={4} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        <Pagination 
                                            total={data.total}
                                            limit={limit}
                                            page={page}
                                            setPage={setPage}
                                            setLimit={setLimit}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                        >
                            회원레벨 수정
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default SettingLevelsForm