import React from "react";

import SettingAmountForm from "./SettingAmountForm";

const SettingAmountView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingAmountForm />
            </div>
        </div>
    )
}

export default SettingAmountView