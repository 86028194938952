import { AxiosPromise } from "axios";
import API from "./API";
import { IParamApplication } from "../interfaces/Application";

const ApplicationAPI = {
    get: (auid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_APPLICATION_URL + `/${auid}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getStatuses: () => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_APPLICATION_STATUSES_URL,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getAlarms: () => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_APPLICATION_ALARMS_URL,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getAdministrators: (keyword: string = ''): AxiosPromise => {
        let _query = (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_APPLICATION_ADMINISTRATORS_URL + `?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    create: (params: IParamApplication): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_APPLICATION_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    update: (params: IParamApplication): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_APPLICATION_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default ApplicationAPI;