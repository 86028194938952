import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { setAuth } from '../../redux/reducers/authReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    Nav, 
    NavItem, 
    NavLink, 
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Button
} from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

import axios from "axios";

import InputControl from '../common/InputControl';

import logo from "../../assets/img/logo.svg";

import { ISIgn } from "../../interfaces/SignX";
import SignXAPI from "../../modules/SignXAPI";
import { useNavigate } from "react-router-dom";

const SignForm = () => {
    const navigate = useNavigate();

    const authState = useSelector((state: RootState) => state.authReducer);
    const dispatch = useDispatch();

    const { modalAlert } = useModal();

    let defaultValues: ISIgn = {
        userid: '',
        password: '',
        passwordConfirm: ''
    };

    const [signMode, setSignMode] = React.useState('in');
    const [title, setTitle] = React.useState('로그인');
    const { watch, handleSubmit, control, reset, formState: { errors, isSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        defaultValues
    });

    const userid = watch('userid');
    const password = watch('password');

    const handleSignMode = (mode = '') => {
        const _mode = mode ? mode : signMode == 'in' ? 'up':'in';
        const _title = (_mode == 'up') ? '회원가입' : '로그인';

        setTitle(_title);

        setSignMode(_mode);

        // Inputs value reset
        reset();
    };

    const handleOnSubmit: SubmitHandler<any> = (data) => {
        const _mode = signMode;

        SignXAPI.sign(_mode, data).then(( res ) => {
            if(res.data.success){
                if(_mode == 'in'){
                    const _message = res.data.message;

                    dispatch(setAuth(_message));
                }else{
                    modalAlert({
                        component: <>Registration is completed.</>,
                        title: '회원가입'
                    });

                    handleSignMode('in');
                }
            }else{
                reset({userid: userid});

                modalAlert({
                    component: <>{res.data.message}</>,
                    title: (_mode == 'up') ? '회원가입' : '로그인'
                });
            }
        })
        .catch(( error ) => {
            reset({userid: userid, password: ''});

            modalAlert({
                component: <>{error.message}</>,
                title: (_mode == 'up') ? '회원가입' : '로그인'
            });
        });
    }
    
    return (
        <div className="sign-form">
            <Row>
                <Col></Col>
                <Col xl={5} lg={6} md={7}>
                    <Card className="p-5">
                        <h2 className="text-center mb-4">{title}</h2>
                        <br />
                        <Nav tabs fill>
                            <NavItem>
                                <NavLink className="cursor-pointer" onClick={(event) => handleSignMode('in')} active={signMode == 'in'}>로그인</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="cursor-pointer" onClick={(event) => handleSignMode('up')} active={signMode == 'up'}>회원가입</NavLink>
                            </NavItem>
                        </Nav>
                        <br />
                        <Form className="form mt-5" onSubmit={handleSubmit(handleOnSubmit)}>
                            <FormGroup>
                                <InputControl 
                                    control={control}
                                    name="userid"
                                    label="아이디"
                                    placeholder="아이디"
                                    rules={{
                                        required: true,
                                        pattern: /^[a-z,0-9]{3,30}$/
                                        //pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                    }}
                                />
                                <FormFeedback>
                                    형식에 맞는 아이디를 입력해주세요.
                                </FormFeedback>
                                {/*
                                <FormFeedback valid>
                                    That's a tasty looking email you've got there.
                                </FormFeedback>
                                <FormText>You can use username or email.</FormText>
                                */}
                            </FormGroup>
                            <FormGroup>
                                <InputControl 
                                    control={control}
                                    type="password"
                                    name="password"
                                    label="비밀번호"
                                    placeholder='**********'
                                    rules={{
                                        required: true,
                                        minLength: 6
                                    }}
                                />
                            </FormGroup>
                            {signMode == 'up' && (
                                <FormGroup>
                                    <InputControl 
                                        control={control}
                                        type="password"
                                        name="passwordConfirm"
                                        label="비밀번호 확인"
                                        placeholder='**********'
                                        rules={{
                                            required: true,
                                            minLength: 6,
                                            validate: {value: (value: string) => value == password}
                                        }}
                                    />
                                    <FormFeedback>
                                        비밀번호가 일치하지 않습니다.
                                    </FormFeedback>
                                    <FormFeedback valid>
                                        비밀번호가 확인되었습니다.
                                    </FormFeedback>
                                </FormGroup>
                            )}
                            <br/>
                            <Button 
                                className="btn-round"
                                type="submit" 
                                color="primary"
                                disabled={isSubmitting} 
                                block
                            >
                                {title}
                            </Button>
                            <br/>
                            <div className="text-center">
                                <a href="#" className="font-size-small">
                                    Forgot your account?
                                </a>
                            </div>
                        </Form>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
        </div>
    );
}

export default SignForm