import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import BetAPI from "../../modules/BetAPI";
import APISocket from "../../modules/APISocket";

const UpcomingsView = () => {

    const { modalAlert, modalConfirm } = useModal();

    const [ inplayEvents, setInplayEvents ] = useState<any[]>([]);

    useEffect(() => {
        const filterEvents: any[] = [
            {timer: '00:00', score_home: '1', score_away: '0', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '1', score_away: '1', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '3', score_away: '2', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '2', score_away: '1', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '1', score_away: '1', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '2', score_away: '0', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '0', score_away: '3', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '1', score_away: '2', home: 'Event Team 1', away: 'Event Team 2'},
            {timer: '00:00', score_home: '2', score_away: '2', home: 'Event Team 1', away: 'Event Team 2'},
        ];

        setInplayEvents(filterEvents)
    }, [])

    useEffect(() => {
        const targetSocket = APISocket.getIo(process.env.REACT_APP_API_SOCKET_URL + '/upcoming');

        targetSocket.connect();

        targetSocket.on('connect', () => {
            console.log('Connected APISocket.');

            //APISocket.emit('/upcoming', { sport_id: 1 })
        });

        targetSocket.on('message', (data) => {
            switch(data.type){
                case 'error':
                    console.error(data.message);
                    break;
            }
        });

        targetSocket.on('disconnect', () => {
            console.log('Disonnected APISocket.')
        });
    }, []);

    return (
        <div className="contents">
            <div className="inplays">
                <Row>
                    <Col>
                        <Row>
                            {inplayEvents.map(( inplayEvent: any, key ) => 
                                <Col key={key} className="col-6">
                                    <Card className="card-inplay">
                                        <CardBody>
                                            <div className="timer">{inplayEvent.timer}</div>
                                            <div className="score">
                                                <div className="image home"></div>
                                                <div className="home">{inplayEvent.score_home}</div>
                                                <div className="vs">VS</div>
                                                <div className="away">{inplayEvent.score_away}</div>
                                                <div className="image away"></div>
                                            </div>
                                            <div className="folder">
                                                <div className="odds">
                                                    <div>{inplayEvent.home}</div>
                                                    <div>0.00</div>
                                                </div>
                                                <div className="draw">VS</div>
                                                <div className="odds">
                                                    <div>0.00</div>
                                                    <div>{inplayEvent.away}</div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}

                            {inplayEvents.length <= 0 && 
                                <Col>
                                    <Card className="card-form">
                                        <CardBody>
                                            <Row>
                                                <Col className="text-center py-5">
                                                    Inplays not found.
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default UpcomingsView
