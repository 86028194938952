import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody, Input, Button, Row, Col } from 'reactstrap';
import { useNavigate, useParams } from "react-router-dom";

import useSpinner from "../../hooks/GlobalSpinner";
import useModal from "../../hooks/GlobalModals";

import BetAPI from "../../modules/BetAPI";
import APISocket from "../../modules/APISocket";

import InplayEventItem from "./InplayEventItem";

import { IInplayEventItem } from "../../interfaces/Inplay";


const InplayEventView = () => {
    const { uid, uid_sport } = useParams();

    //const [ inplayEvents, setInplayEvents ] = useState<IInplayEventItem[]>([]);
    const [ inplayEvents, setInplayEvents ] = useState<any[]>([]);

    const navigate = useNavigate();

    const { spinnerVisible } = useSpinner();

    useEffect(() => {
        spinnerVisible(true);

        const targetSocket = APISocket.getIo(process.env.REACT_APP_API_SOCKET_URL + '/inplay-event');

        targetSocket.connect();

        targetSocket.on('connect', () => {
            console.log('Connected APISocket.');

            if(!uid){
                console.error('Invalid Request.');

                targetSocket.disconnect();
            }else{
                targetSocket.emit('message', { type: 'inplay-event', uid, uid_sport });
            }
        });

        targetSocket.on('message', (response) => {
            switch(response.type){
                case 'load':
                    break;
                case 'inplay-event':
                    spinnerVisible(false);

                    const inplayEventData = response.data;

                    if(inplayEventData){
                        const { info, marketGroups, markets, columns, odds } = inplayEventData;

                        console.log('info', info)

                        //let filterEvents: IInplayEventItem[] = [];
                        let filterEvents: any = [];

                        for( const oddsKey in odds ){
                            const targetOdds = odds[oddsKey];

                            if(targetOdds.uid_column){
                                let filterColumn: any = null;
    
                                for( const columnKey in columns ){
                                    const targetColumn = columns[columnKey];

                                    //filterColumn = (targetColumn.uid == targetOdds.uid_column) ? targetColumn : null;
                                    if(targetColumn.uid != targetOdds.uid_column){
                                        filterColumn = null;
                                    }else{
                                        filterColumn = targetColumn;
                                        break;
                                    }
                                }

                                if(!filterColumn){
                                    continue;
                                }else{
                                    if(filterColumn.uid_market){
                                        let filterMarket: any = null;

                                        for( const marketKey in markets ){
                                            const targetMarket = markets[marketKey];

                                            if(targetMarket.uid != filterColumn.uid_market){
                                                filterMarket = null;
                                            }else{
                                                filterMarket = targetMarket;
                                                break;
                                            }
                                        }

                                        if(!filterMarket){
                                            continue;
                                        }else{
                                            if(filterMarket.uid_market_group){
                                                let filterMarketGroup: any = null;
        
                                                for( const marketGroupKey in marketGroups ){
                                                    const targetMarketGroup = marketGroups[marketGroupKey];
        
                                                    if(targetMarketGroup.uid != filterMarket.uid_market_group){
                                                        filterMarketGroup = null;
                                                    }else{
                                                        filterMarketGroup = targetMarketGroup;
                                                        break;
                                                    }
                                                }
        
                                                if(!filterMarketGroup){
                                                    continue;
                                                }else{
                                                    if(!filterEvents[filterMarketGroup.order])
                                                        filterEvents[filterMarketGroup.order] = { ...filterMarketGroup, markets: [] };

                                                    if(!filterEvents[filterMarketGroup.order].markets[filterMarket.order])
                                                        filterEvents[filterMarketGroup.order].markets[filterMarket.order] = { ...filterMarket, odds: [] };

                                                    filterEvents[filterMarketGroup.order].markets[filterMarket.order].odds[targetOdds.order] = targetOdds;
                                                }
                                            }else{
                                                if(!filterEvents[filterMarket.order])
                                                    filterEvents[filterMarket.order] = { ...filterMarket, odds: [] };
        
                                                filterEvents[filterMarket.order].odds[targetOdds.order] = targetOdds;
                                            }
                                        }
                                    }
                                }
                            }

                            if(targetOdds.uid_market){
                                let filterMarket: any = null;

                                for( const marketKey in markets ){
                                    const targetMarket = markets[marketKey];

                                    if(targetMarket.uid != targetOdds.uid_market){
                                        filterMarket = null;
                                    }else{
                                        filterMarket = targetMarket;
                                        break;
                                    }
                                }

                                if(!filterMarket){
                                    continue;
                                }else{
                                    if(filterMarket.uid_market_group){
                                        let filterMarketGroup: any = null;

                                        for( const marketGroupKey in marketGroups ){
                                            const targetMarketGroup = marketGroups[marketGroupKey];

                                            if(targetMarketGroup.uid != filterMarket.uid_market_group){
                                                filterMarketGroup = null;
                                            }else{
                                                filterMarketGroup = targetMarketGroup;
                                                break;
                                            }
                                        }

                                        if(!filterMarketGroup){
                                            continue;
                                        }else{
                                            if(!filterEvents[filterMarketGroup.order])
                                                filterEvents[filterMarketGroup.order] = { ...filterMarketGroup, markets: [] };

                                            if(!filterEvents[filterMarketGroup.order].markets[filterMarket.order])
                                                filterEvents[filterMarketGroup.order].markets[filterMarket.order] = { ...filterMarket, odds: [] };

                                            filterEvents[filterMarketGroup.order].markets[filterMarket.order].odds[targetOdds.order] = targetOdds;
                                        }
                                    }else{
                                        if(!filterEvents[filterMarket.order])
                                            filterEvents[filterMarket.order] = { ...filterMarket, odds: [] };

                                        filterEvents[filterMarket.order].odds[targetOdds.order] = targetOdds;
                                    }
                                }
                            }
                        }

                        //console.log('filterEvents', filterEvents);

                        setInplayEvents(() => [ ...filterEvents ]);
                    }

                    //targetSocket.disconnect();
                    
                    break;
                case 'error':
                    console.error(response.message);
                    break;
            }
        });

        targetSocket.on('disconnect', () => {
            console.log('Disonnected APISocket.');

            navigate('/inplay');
        });

        targetSocket.on('connect_error', (error) => {
            console.log(error.message);
        })

        return() => {
            targetSocket.disconnect();
        }
    }, []);

    useEffect(() => {
        //console.log(inplayEvents)
    }, [inplayEvents])

    return (
        <div className="contents">
            <div className="inplay-events">
                <Row>
                    <Col>
                        <Row>
                            {inplayEvents.length}
                        </Row>
                        <Row>
                            {inplayEvents.map((inplayEvent, key) => 
                                <InplayEventItem key={key} item={inplayEvent} />
                            )}

                            {inplayEvents.length <= 0 && 
                                <Col>
                                    <Card className="card-form">
                                        <CardBody>
                                            <Row>
                                                <Col className="text-center py-5">
                                                    Inplay-events not found.
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default InplayEventView
