import React from "react";

import SettingLeaguesForm from "./SettingLeaguesForm";

const SettingLeaguesView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingLeaguesForm />
            </div>
        </div>
    )
}

export default SettingLeaguesView