import React from "react";

import SettingUpcomingForm from "./SettingUpcomingForm";

const SettingUpcomingView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingUpcomingForm />
            </div>
        </div>
    )
}

export default SettingUpcomingView