import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import BetAPI from "../../modules/BetAPI";
import APISocket from "../../modules/APISocket";
import { useNavigate, useParams } from "react-router-dom";
import { IInplayItem, IInplayItemProps } from "../../interfaces/Inplay";

import InplayItemTimer from "./InplayItemTimer";
import InplayItemScore from "./InplayItemScore";

const InplayItem = (props: IInplayItemProps) => {
    const { item } = props;
    
    const navigate = useNavigate();

    const [ inplayItem, setInplayItem ] = useState<IInplayItem | null>(null);

    useEffect(() => {
        setInplayItem(item)
    }, [item])

    return (
        <>
            {inplayItem != null &&
                <Col className="col-6">
                    <Card className="card-inplay" onClick={(event) => navigate(`/inplay-event/${inplayItem.sport.uid}/${inplayItem.uid}`)}>
                        <CardBody>
                            <div>{inplayItem.uid} / {inplayItem.key}</div>
                            <InplayItemTimer timer={inplayItem.timer} />
                            <InplayItemScore score={inplayItem.score} images={inplayItem.images} />
                            <div className="folder">
                                <div className="odds">
                                    <div>{inplayItem.home}</div>
                                    <div>{/*0.00*/}</div>
                                </div>
                                <div className="draw">VS</div>
                                <div className="odds">
                                    <div>{/*0.00*/}</div>
                                    <div>{inplayItem.away}</div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            }
        </>
    )
}

export default InplayItem
