import React, { useRef, useState, useEffect, useMemo, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Input, Button, FormGroup, Form, Row, Col, CardHeader, CardTitle, Label } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import MarketsSearchForm from './MarketsSearchForm';
import BetAPI from "../../modules/BetAPI";

const SettingMarketCombinesForm = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ marketGroups, setMarketGroups ] = useState<any[]>([]);
    const [ marketGroupCombines, setMarketGroupCombines ] = useState<any[]>([]);

    const [ targetCombine, setTargetCombine ] = useState<{ uid: string, sport: any, allowed_same_match: boolean, allowed_different_match: boolean, market_groups: any[] }>();

    const [ { target, sport, keyword }, setSearchForms ] = useState({
        target: 'upcoming',
        sport: '',
        keyword: ''
    });

    useEffect(() => {
        if(sport){
            spinnerVisible(true);

            const delay = setTimeout(() => {
                BetAPI.getConfigMarkets(target, sport, keyword).then(( res ) => {
                    if(res.data.success){
                        const { total, marketGroups: targetMarketGroups } = res.data.message;

                        let filterMarketGroups: any[] = [];

                        for( const targetMarketGroup of targetMarketGroups ){
                            filterMarketGroups.push({
                                uid: targetMarketGroup.uid,
                                name: targetMarketGroup.name,
                                name_kr: targetMarketGroup.name_kr,
                                sport: targetMarketGroup.sport
                            })
                        }

                        setMarketGroups(filterMarketGroups);
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '마켓 설정'
                        });
                    }

                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '마켓 설정'
                    });
                });

                BetAPI.getConfigMarketCombines(target, sport).then(( res ) => {
                    if(res.data.success){
                        const { total, combines: targetCombines } = res.data.message;

                        console.log('targetCombines1', targetCombines)

                        setMarketGroupCombines(targetCombines);
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '마켓 설정'
                        });
                    }
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '마켓 설정'
                    });
                });
            }, 300);

            return () => clearTimeout(delay)
        }
    }, [target, sport, keyword]);

    const marketGroupAppend = (uid: string) => {
        const targetMarketGroup = marketGroups.find((marketGroup) => marketGroup.uid == uid);

        if(!targetMarketGroup){
            modalAlert({
                component: <>해당하는 마켓을 찾지 못했습니다.</>,
                title: '마켓 설정'
            });
        }else{
            setTargetCombine((targetCombine: any) => {
                if(!targetCombine){
                    return { uid: '', sport: targetMarketGroup.sport, allowed_same_match: true, allowed_different_match: false, market_groups: [ targetMarketGroup ] };
                }else{
                    let filterMarketGroups = targetCombine.market_groups;
                    let filterMarketGroupIndex = filterMarketGroups.findIndex((market_group: any) => market_group.uid == targetMarketGroup.uid)

                    if(filterMarketGroups.length < 10 && filterMarketGroupIndex < 0)
                        filterMarketGroups.push(targetMarketGroup);

                    return { ...targetCombine, market_groups: [ ...filterMarketGroups ] };
                }
            });
        }
    }

    const marketGroupRemove = (uid: string) => {
        setTargetCombine((targetCombine: any) => {
            if(!targetCombine){
                return targetCombine;
            }else{
                let filterMarketGroups = targetCombine.market_groups.filter((market_group: any) => market_group.uid != uid);

                if(filterMarketGroups.length <= 0){
                    return undefined;
                }else{
                    return { ...targetCombine, market_groups: filterMarketGroups }
                }
            }
        });
    }

    const handleSetTargetCombine = (uid: string) => {
        const filterCombine = marketGroupCombines.find((marketGroupCombine) => marketGroupCombine.uid == uid);

        if(filterCombine) setTargetCombine(filterCombine)
    }

    const handleResetTargetCombine = () => {
        setTargetCombine(undefined);
    }

    const handleSetCombines = () => {
        spinnerVisible(true);

        console.log('targetCombine', targetCombine)

        if(targetCombine){
            let filterParams = { 
                uid: targetCombine.uid,
                target, 
                uid_sport: targetCombine.sport.uid, 
                allowed_same_match: targetCombine.allowed_same_match,
                allowed_different_match: targetCombine.allowed_different_match,
                market_groups: targetCombine.market_groups
            };
    
            if(filterParams.market_groups && filterParams.market_groups.length < 2){
                spinnerVisible(false);

                modalAlert({
                    component: <>마켓을 2개 이상 조합해주세요.</>,
                    title: '마켓 설정'
                });
            }else{

                BetAPI.setConfigMarketCombines(filterParams).then((res) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리가 완료되었습니다.</>,
                            title: '마켓 설정'
                        });
    
                        if(targetCombine.uid){
                            setMarketGroupCombines((marketGroupCombines) => {
                                const filterMarketGroupCombines = marketGroupCombines.map((marketGroupCombine) => {
                                    if(marketGroupCombine.uid == targetCombine.uid){
                                        return { 
                                            ...marketGroupCombine, 
                                            allowed_same_match: targetCombine.allowed_same_match, 
                                            allowed_different_match: targetCombine.allowed_different_match, 
                                            market_groups: targetCombine.market_groups 
                                        }
                                    }else{
                                        return marketGroupCombine;
                                    }
                                })

                                return filterMarketGroupCombines;
                            })
                        }else{
                            const uid = res.data.message;
                            const sport = (marketGroups.length > 0) ? marketGroups[0].sport : null;

                            if(sport){
                                setMarketGroupCombines((marketGroupCombines) => {
                                    const filterMarketGroupCombines = [ ...marketGroupCombines ]

                                    filterMarketGroupCombines.push({
                                        uid,
                                        sport,
                                        allowed_same_match: targetCombine.allowed_same_match,
                                        allowed_different_match: targetCombine.allowed_different_match,
                                        market_groups: targetCombine.market_groups
                                    })

                                    return filterMarketGroupCombines;
                                })
                            }
                        }
    
                        handleResetTargetCombine();
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '마켓 설정'
                        });
                    }
    
                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);
    
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '마켓 설정'
                    });
                });
            }
        }
    }

    const handleDeleteCombine = (uid: string) => {
        spinnerVisible(true);

        BetAPI.deleteConfigMarketCombine(uid).then(( res ) => {
            if(res.data.success){
                setMarketGroupCombines((marketGroupCombines) => {
                    const filterMarketGroupCombines = marketGroupCombines.filter((marketGroupCombine) => marketGroupCombine.uid != uid);

                    return filterMarketGroupCombines;
                });

                handleResetTargetCombine();

                modalAlert({
                    component: <>처리가 완료되었습니다.</>,
                    title: '마켓 설정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '마켓 설정'
                });
            }

            spinnerVisible(false);
        }).catch(( error ) => {
            spinnerVisible(false);

            modalAlert({
                component: <>{error.message}</>,
                title: '마켓 설정'
            });
        });
    }

    return (
        <div className="contents">
            <MarketsSearchForm target={target} setSearchForms={setSearchForms} />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <table className="table">
                                <colgroup>
                                    <col width="120px"/>
                                    <col />
                                    <col width="80px"/>
                                </colgroup>
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col">코드</th>
                                        <th scope="col" rowSpan={2}>마켓명</th>
                                        <th scope="col" rowSpan={2}>선택</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">종목</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marketGroups.map(( marketGroup: any, key ) => 
                                        <React.Fragment key={key}>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <td>{marketGroup.uid}</td>
                                                <td rowSpan={2}>{marketGroup.name_kr ? marketGroup.name_kr : marketGroup.name}</td>
                                                <td rowSpan={2}>
                                                    <Button
                                                        color="secondary"
                                                        type="button"
                                                        onClick={(event) => marketGroupAppend(marketGroup.uid)}
                                                    >
                                                        선택
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <td>{marketGroup.sport.name_kr ? marketGroup.sport.name_kr : marketGroup.sport.name}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {marketGroups.length <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={9} className="py-5 text-center">해당되는 마켓이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className="card-form">
                        <CardHeader>
                            <CardTitle tag="h5">마켓조합 추가/수정</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {targetCombine && 
                                <>
                                    <Row>
                                        <Col>
                                            <Row className="mb-1">
                                                <Col>
                                                    <FormGroup switch className="mb-0">
                                                        <Label className="form-label">동일경기 비허용</Label>
                                                        <Input 
                                                            type="switch"
                                                            className="form-control-sm"
                                                            checked={targetCombine.allowed_same_match}
                                                            onChange={(event) => setTargetCombine((combine) => {
                                                                if(combine)
                                                                    return { ...combine, allowed_same_match: !combine.allowed_same_match }
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup switch className="mb-0">
                                                        <Label className="form-label">다른경기 비허용</Label>
                                                        <Input 
                                                            type="switch"
                                                            className="form-control-sm"
                                                            checked={targetCombine.allowed_different_match}
                                                            onChange={(event) => setTargetCombine((combine) => {
                                                                if(combine)
                                                                    return { ...combine, allowed_different_match: !combine.allowed_different_match }
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <table className="table mt-3">
                                                <colgroup>
                                                    <col width="120px"/>
                                                    <col />
                                                    <col width="80px"/>
                                                </colgroup>
                                                <thead>
                                                    <tr className="align-middle">
                                                        <th scope="col">코드</th>
                                                        <th scope="col" rowSpan={2}>마켓명</th>
                                                        <th scope="col" rowSpan={2}>선택</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">종목</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {targetCombine.market_groups.map((market_group, key) => 
                                                        <React.Fragment key={key}>
                                                            <tr className="align-middle text-center">
                                                                <td>{market_group.uid}</td>
                                                                <td rowSpan={2}>{market_group.name_kr ? market_group.name_kr : market_group.name}</td>
                                                                <td rowSpan={2}>
                                                                    <Button
                                                                        color="danger"
                                                                        type="button"
                                                                        onClick={(event) => marketGroupRemove(market_group.uid)}
                                                                    >
                                                                        삭제
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            <tr className="align-middle text-center">
                                                                <td>
                                                                    {/*market_group.sport.name_kr ? market_group.sport.name_kr : market_group.sport.name*/}
                                                                    {targetCombine.sport.name_kr ? targetCombine.sport.name_kr : targetCombine.sport.name}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center py-2">
                                            <Button
                                                className="btn-round"
                                                color="primary"
                                                type="button"
                                                onClick={handleSetCombines}
                                            >
                                                조합 추가/수정
                                            </Button>
                                            &nbsp;
                                            <Button
                                                className="btn-round"
                                                color="secondary"
                                                type="button"
                                                onClick={handleResetTargetCombine}
                                            >
                                                새로 등록
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            }

                            {!targetCombine && 
                                <Row>
                                    <Col className="text-center pt-4 pb-5">
                                        마켓목록의 "선택" 버튼으로 마켓조합을 추가할 수 있습니다.
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <table className="table">
                                <colgroup>
                                    <col width="120px"/>
                                    <col />
                                    <col width="120px"/>
                                </colgroup>
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col">코드</th>
                                        <th scope="col" rowSpan={4}>마켓명</th>
                                        <th scope="col" rowSpan={4}>관리</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">종목</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">동일경기</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">다른경기</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marketGroupCombines.map((marketGroupCombine, key) => 
                                        <React.Fragment key={key}>
                                            <tr className="align-middle text-center">
                                                <td>{marketGroupCombine.uid}</td>
                                                <td rowSpan={4}>
                                                    {marketGroupCombine.market_groups.map((marketGroup: any, key2: number) => 
                                                        <div key={key2} className={`border p-1 ${key2 > 0 ? 'mt-1' : ''}`}>
                                                            {marketGroup.name_kr ? marketGroup.name_kr : marketGroup.name}
                                                        </div>
                                                    )}
                                                </td>
                                                <td rowSpan={4}>
                                                    <Button
                                                        color="secondary"
                                                        type="button"
                                                        onClick={(event) => handleSetTargetCombine(marketGroupCombine.uid)}
                                                    >
                                                        수정
                                                    </Button>
                                                    &nbsp;
                                                    <Button
                                                        color="danger"
                                                        type="button"
                                                        onClick={(event) => handleDeleteCombine(marketGroupCombine.uid)}
                                                    >
                                                        삭제
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr className="align-middle text-center">
                                                <td>{marketGroupCombine.sport.name_kr ? marketGroupCombine.sport.name_kr : marketGroupCombine.sport.name}</td>
                                            </tr>
                                            <tr className="align-middle text-center">
                                                <td>{marketGroupCombine.allowed_same_match ? '허용' : '비허용'}</td>
                                            </tr>
                                            <tr className="align-middle text-center">
                                                <td>{marketGroupCombine.allowed_different_match ? '허용' : '비허용'}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {marketGroupCombines.length <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={3} className="py-5 text-center">해당되는 마켓조합이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SettingMarketCombinesForm