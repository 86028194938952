import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button,
    Label
} from 'reactstrap';
import Select, { ActionMeta, SingleValue, PropsValue, CSSObjectWithLabel, MultiValue } from 'react-select'

import Util from '../../modules/Util';
import AccountAPI from "../../modules/AccountAPI";
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';
import TextEditor from '../common/Editor/TextEditor';

import { IBoard } from "../../interfaces/Board";
import BoardAPI from "../../modules/BoardAPI";
import { IMemo } from "../../interfaces/Memo";
import AccountsAPI from "../../modules/AccountsAPI";
import MemoAPI from "../../modules/MemoAPI";


const MemoFormModal = () => {
    const location = useLocation();

    const { modalAlert } = useModal();

    const [accountOptions, setAccountOptions] = useState<{ value: any, label: any }[]>([]);
    const [receiverValues, setReceiverValues] = useState<MultiValue<{ value: any, label: any }>>();
    const [ { keyword }, setSearchForms ] = useState({
        keyword: ''
    });
    
    let selectRef = useRef<any>();

    let defaultValues: IMemo = {
        subject: '',
        contents: ''
    };

    const { watch, handleSubmit, control, reset, setFocus, setValue, setError, formState } = useForm<IMemo>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const watchContents = watch('contents');

    useEffect(() => {
        const delay = setTimeout(() => {
            AccountsAPI.get(true, false, 1, 50, '', keyword).then(( res ) => {
                if(res.data.success){
                    const { total, accounts } = res.data.message;

                    let filterAccounts = [];

                    for(var account of accounts){
                        filterAccounts.push({
                            value: account.uuid,
                            label: `${account.userid} (${account.nickname})`
                        })
                    }

                    setAccountOptions(filterAccounts);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '개별쪽지보내기'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '개별쪽지보내기'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [keyword]);

    useEffect(() => {
        console.log(formState.errors)
    }, [formState])

    const handleSelectKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const keywordInputRef = selectRef.current.inputRef;

        setSearchForms({keyword: keywordInputRef.value})
    }

    const handleSelectChange = (newValue: MultiValue<{value: any, label: any}>, actionMeta: ActionMeta<{value: any, label: any}>) => {
        setReceiverValues(newValue)
    }

    const handleOnSubmit: SubmitHandler<any> = (formData) => {
        const uuid_receivers = (receiverValues && receiverValues.length > 0) ? receiverValues.map((receiverValue) => receiverValue.value) : []

        if(uuid_receivers.length <= 0){
            modalAlert({
                component: <>받을 사람을 1명이상 입력해주세요.</>,
                title: '쪽지보내기'
            });
        }else{
            let filterFormData = {
                type: 'personal',
                ...formData,
                uuid_receivers
            }

            MemoAPI.set(filterFormData).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '개별쪽지보내기'
                    });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '개별쪽지보내기'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '개별쪽지보내기'
                });
            });
        }
    }
    
    return (
        <div className="modal-form">
            <Form onSubmit={handleSubmit(handleOnSubmit)} id="modal-form-memo">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <InputControl
                                        control={control}
                                        name="subject"
                                        label="제목"
                                        placeholder="제목"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col onKeyUp={handleSelectKeyUp}>
                                <Label>받는 사람</Label>
                                <Select
                                    ref={selectRef}
                                    isMulti
                                    placeholder="받는 사람 검색"
                                    defaultValue={accountOptions[0]}
                                    options={accountOptions} 
                                    onChange={handleSelectChange}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isDisabled ? '#ddd' : '#ddd',
                                            backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
                                        } as CSSObjectWithLabel),
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            zIndex: 10
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isDisabled ? '#666 !important' : '#212529'
                                        } as CSSObjectWithLabel)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <TextEditor
                                        data={watchContents}
                                        placeholder="내용을 입력하세요."
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            //console.log( { event, editor, data } );
                                            setValue('contents', data);
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            //console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            //console.log( 'Focus.', editor );
                                        } }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default MemoFormModal