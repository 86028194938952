import { AxiosPromise } from "axios";
import API from "./API";
import { IParamAmount, IParamAmountConfig, IParamAmountStatus } from "../interfaces/Amount";

const AmountAPI = {
    getConfigsByTarget: (uid_level: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_AMOUNT_CONFIGS_URL + `/${uid_level}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setConfigs: (params: IParamAmountConfig): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_AMOUNT_CONFIGS_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    set: (params: IParamAmount): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_AMOUNT_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setStatus: (params: IParamAmountStatus): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_AMOUNT_URL + `/status`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    }
}

export default AmountAPI;