import { IApplicationAlarm } from "../../interfaces/Application";

const SET_ALARM = 'alarm/SET_ALARM' as const;

export const setAlarm = (alarm: IApplicationAlarm) => ({ type: SET_ALARM, alarm });

type AlarmAction =
    | ReturnType<typeof setAlarm>

const defaultState: IApplicationAlarm = {
    request_deposit: 0,
    request_withdraw: 0,
    request_transform: 0,
    request_deposit_settlement: 0,
    request_withdraw_settlement: 0,
    confirm_join: 0,
    inquiry_customer: 0
}

const alarmReducer = (state: IApplicationAlarm = defaultState, action: AlarmAction): IApplicationAlarm => {
    switch(action.type){
        case SET_ALARM:
            return {
                ...state,
                ...action.alarm
            };
        default:
            return state;
    }
}

export default alarmReducer;