import { Editor } from "@ckeditor/ckeditor5-core";
import { FileLoader, UploadAdapter } from "@ckeditor/ckeditor5-upload";
import PopupAPI from "../../../modules/PopupAPI";
import BoardPostAPI from "../../../modules/BoardPostAPI";

export const FileUploadAdapter = (loader: FileLoader, uploadType: string = '', uploadKey: string = ''): UploadAdapter => {
    return {
        upload: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const basePath = '/uploads/images/editor';

                    const file = await loader.file;

                    if(file){
                        let targetPath = basePath + `/${uploadType}`;
                        targetPath = (uploadKey) ? targetPath + `/${uploadKey}` : targetPath;

                        switch(uploadType){
                            case 'popup':
                                PopupAPI.upload(file, uploadKey).then(( res ) => {
                                    if(res.data.success){
                                        resolve({
                                            default: `${targetPath}${res.data.message}`
                                        });
                                    }else{
                                        reject('File upload failed.');
                                    }
                                }).catch(( error ) => {
                                    reject(error)
                                });
                                break;
                            case 'board':
                                BoardPostAPI.upload(file, uploadKey).then(( res ) => {
                                    if(res.data.success){
                                        resolve({
                                            default: `${targetPath}${res.data.message}`
                                        });
                                    }else{
                                        reject('File upload failed.');
                                    }
                                    
                                }).catch(( error ) => {
                                    reject(error)
                                });
                                break;
                        }
                    }else{
                        reject('File not found.');
                    }
                } catch (error) {
                    reject(error);
                }
            });
        },
        abort: () => {
        }
    };
}