import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { setAuth } from '../../redux/reducers/authReducer';
import { setAlarm } from '../../redux/reducers/alarmReducer';
import { setSummaryToday } from '../../redux/reducers/statsReducer';
import { clearNotifyItems, removeNotifyItem } from "../../redux/reducers/notifyReducer";
import { Link, useLocation, matchPath, useNavigate } from "react-router-dom";
import { 
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    InputGroup,
    InputGroupText,
    Input,
} from "reactstrap";

import useModal from "../../hooks/GlobalModals";
import useNotify from "../../hooks/GlobalNotifies";

import { IMultiRoute } from '../../interfaces/MultiRoutes';
import SignXAPI from "../../modules/SignXAPI";
import ApplicationAPI from "../../modules/ApplicationAPI";
import StatsAPI from "../../modules/StatsAPI";

const Header = ({ routes, ...props }: { routes: IMultiRoute[], [key: string]: any }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const authState = useSelector((state: RootState) => state.authReducer)
    const alarmState = useSelector((state: RootState) => state.alarmReducer);

    const dispatch = useDispatch();

    const [color, setColor] = React.useState("transparent");
    const sidebarToggle = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [dropdownAlarm, setDropdownAlarm] = React.useState(false);
    const [dropdownAccount, setDropdownAccount] = React.useState(false);

    const { modalAlert } = useModal();
    const { notifyDefault } = useNotify();

    useEffect(() => {
        let alarmsInterval: any = null;

        if(authState.uuid){
            alarmsInterval = setInterval(() => {
                ApplicationAPI.getAlarms().then(( res ) => {
                    if(res.data.success){
                        const alarms = res.data.message;

                        const alarmKeys = Object.keys(alarms);
                        let alarmMessage = '';

                        for( const alarmKey of alarmKeys ){
                            const alarmCount = alarms[alarmKey];

                            switch(alarmKey){
                                case 'request_deposit':
                                    alarmMessage = '새로운 입금신청이 있습니다.';
                                    break;
                                case 'request_withdraw':
                                    alarmMessage = '새로운 출금신청이 있습니다.';
                                    break;
                                case 'request_transform':
                                    alarmMessage = '새로운 전환신청이 있습니다.';
                                    break;
                                case 'request_deposit_settlement':
                                    alarmMessage = '새로운 정산입금신청이 있습니다.';
                                    break;
                                case 'request_withdraw_settlement':
                                    alarmMessage = '새로운 정산출금신청이 있습니다.';
                                    break;
                                case 'confirm_join':
                                    alarmMessage = '새로운 가입승인이 있습니다.';
                                    break;
                                case 'inquiry_customer':
                                    alarmMessage = '새로운 고객문의가 있습니다.';
                                    break;
                            }

                            if(alarmCount > 0){
                                notifyDefault({
                                    notifyKey: `alarm_${alarmKey}`,
                                    component: <>{alarmMessage}</>
                                })
                            }else{
                                dispatch(removeNotifyItem(`alarm_${alarmKey}`));
                            }
                        }
        
                        dispatch(setAlarm(alarms));
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '알림'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '알림'
                    });
                });
            }, 30000);

            StatsAPI.getSummaryToday().then(( res ) => {
                if(res.data.success){
                    const statsTodayData = res.data.message;
    
                    dispatch(setSummaryToday(statsTodayData));
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '오늘의 통계'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '오늘의 통계'
                });
            });
        }

        return () => {
            clearInterval(alarmsInterval)
        }
    }, [])

    const toggle = () => {
        if (isOpen) {
            setColor("transparent");
        } else {
            setColor("dark");
        }

        setIsOpen(!isOpen);
    };

    const dropdownToggle = (event: any, type: string) => {
        switch(type){
            case 'alarm':
                setDropdownAlarm(!dropdownAlarm);
                break;
            case 'account':
                setDropdownAccount(!dropdownAccount);
                break;
        }
    };

    const getBrand = (routes: IMultiRoute[]) => {
        let brandName = "";

        for( const route of routes ){
            brandName = (matchPath(route.path, location.pathname)) ? route.name : '';

            if(!brandName && route.childRoutes){
                for( const childRoute of route.childRoutes) {
                    let fullpath = route.path + '/' + childRoute.path;
    
                    if(matchPath(fullpath, location.pathname)){
                        brandName = childRoute.name;
                        break;
                    }
                }
            }

            if(!brandName && route.subRoutes){
                brandName = getBrand(route.subRoutes);
            }

            if(brandName) break;
        }

        return brandName;
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current!.classList.toggle("toggled");
    };

    const handleSignOut = () => {
        SignXAPI.signout().then(( res ) => {
            if(!res.data.success){
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '로그아웃'
                });
            }else{
                dispatch(clearNotifyItems());
                dispatch(setAuth({ 
                    modifire: 'PUBLIC',
                    uuid: '',
                    role: null,
                    level: null,
                    userid: '',
                    name: '',
                    amount: 0,
                    amount_bonus: 0,
                    amount_point: 0,
                    ip: '',
                    expires: 0
                }));

                navigate('/signx')
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '로그아웃'
            });
        });
    }

    return (
        <header className="header">
            <div className="header-top">
                <div className="header-alarm-items">
                    <div className={`header-alarm-item ${alarmState.request_deposit > 0 ? 'blink':''}`}>
                        <div className="subject">입금신청</div>
                        <div className="number">{alarmState.request_deposit}</div>
                    </div>
                    <div className={`header-alarm-item ${alarmState.request_withdraw > 0 ? 'blink':''}`}>
                        <div className="subject">출금신청</div>
                        <div className="number">{alarmState.request_withdraw}</div>
                    </div>
                    <div className={`header-alarm-item ${alarmState.request_transform > 0 ? 'blink':''}`}>
                        <div className="subject">전환신청</div>
                        <div className="number">{alarmState.request_transform}</div>
                    </div>
                    <div className={`header-alarm-item ${alarmState.request_deposit_settlement > 0 ? 'blink':''}`}>
                        <div className="subject">정산입금신청</div>
                        <div className="number">{alarmState.request_deposit_settlement}</div>
                    </div>
                    <div className={`header-alarm-item ${alarmState.request_withdraw_settlement > 0 ? 'blink':''}`}>
                        <div className="subject">정산출금신청</div>
                        <div className="number">{alarmState.request_withdraw_settlement}</div>
                    </div>
                    <div className={`header-alarm-item ${alarmState.confirm_join > 0 ? 'blink':''}`}>
                        <div className="subject">가입승인</div>
                        <div className="number">{alarmState.confirm_join}</div>
                    </div>
                    <div className={`header-alarm-item ${alarmState.inquiry_customer > 0 ? 'blink':''}`}>
                        <div className="subject">고객센터</div>
                        <div className="number">{alarmState.inquiry_customer}</div>
                    </div>
                    <div className="header-alarm-item">
                        <div className="subject">접속회원</div>
                        <div className="number">0</div>
                    </div>
                </div>
                <div className="header-user-items">
                    <ul>
                        <Dropdown
                            nav
                            isOpen={dropdownAlarm}
                            toggle={(e: any) => dropdownToggle(e, 'alarm')}
                        >
                            <DropdownToggle caret nav>
                                <i className="fa fa-bell" />
                                <p>
                                    <span className="d-lg-none d-md-block">Some Actions</span>
                                </p>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag="a">Action</DropdownItem>
                                <DropdownItem tag="a">Another Action</DropdownItem>
                                <DropdownItem tag="a">Something else here</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown
                            nav
                            isOpen={dropdownAccount}
                            toggle={(e: any) => dropdownToggle(e, 'account')}
                        >
                            <DropdownToggle caret nav>
                                <i className="fa fa-cog" />
                                <p>
                                    <span className="d-lg-none d-md-block">Account</span>
                                </p>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag="span" onClick={handleSignOut}>로그아웃</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </ul>
                </div>
            </div>
            <Navbar
                color={
                    location.pathname.indexOf("full-screen-maps") !== -1
                        ? "dark"
                        : color
                }
                expand={true}
                className={
                    location.pathname.indexOf("full-screen-maps") !== -1
                        ? "navbar-absolute fixed-top"
                        : "navbar-absolute fixed-top " +
                            (color === "transparent" ? "navbar-transparent " : "")
                }
            >
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            className="navbar-toggler"
                            onClick={() => openSidebar()}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <Link className="navbar-brand" to={location.pathname}>{getBrand(routes)}</Link>
                </div>

                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>

                {/*
                <Collapse isOpen={isOpen} navbar className="justify-content-end">
                    <form className="navbar-form">
                        <InputGroup>
                            <Input placeholder="Search..." />
                            <InputGroupText>
                                <i className="fa fa-search" />
                            </InputGroupText>
                        </InputGroup>
                    </form>
                    <Nav navbar>
                        <Dropdown
                            nav
                            isOpen={dropdownAlarm}
                            toggle={(e: any) => dropdownToggle(e, 'alarm')}
                        >
                            <DropdownToggle caret nav>
                                <i className="fa fa-bell" />
                                <p>
                                    <span className="d-lg-none d-md-block">Some Actions</span>
                                </p>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag="a">Action</DropdownItem>
                                <DropdownItem tag="a">Another Action</DropdownItem>
                                <DropdownItem tag="a">Something else here</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown
                            nav
                            isOpen={dropdownAccount}
                            toggle={(e: any) => dropdownToggle(e, 'account')}
                        >
                            <DropdownToggle caret nav>
                                <i className="fa fa-cog" />
                                <p>
                                    <span className="d-lg-none d-md-block">Account</span>
                                </p>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag="span" onClick={handleSignOut}>Sign Out</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Collapse>
            */}
            </Navbar>
        </header>
    );
}

export default Header