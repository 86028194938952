import React, { useRef, useState, useEffect, useMemo, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useNavigate, Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Card, CardBody, Input, Button, FormGroup, Form, InputGroup, InputGroupText, Row, Col } from 'reactstrap';

import moment from "moment";

import useModal from "../../hooks/GlobalModals";

import Util from "../../modules/Util";

import MarketsSearchForm from './MarketsSearchForm';
import InputControl from '../common/InputControl';
import { IAccount } from "../../interfaces/Account";
import BetAPI from "../../modules/BetAPI";
import { IConfig } from "../../interfaces/Config";
import { IBetConfigObject, IBetConfigValues } from "../../interfaces/BetConfig";
import useSpinner from "../../hooks/GlobalSpinner";

const SettingMarketsForm = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ data, setData ] = useState<{total: number, marketGroups: any[]}>({
        total: 0,
        marketGroups: []
    });

    const [ { target, sport, keyword }, setSearchForms ] = useState({
        target: 'upcoming',
        sport: '',
        keyword: ''
    });

    const [ configValues, setConfigValues ] = useState<IConfig>();
    const [ configObjects, setConfigObjects ] = useState<IBetConfigObject[]>([]);

    const [ orderOptions, setOrderOptions ] = useState<{value: any, label: any}[]>([]);

    useEffect(() => {
        if(sport){
            spinnerVisible(true);

            const delay = setTimeout(() => {
                BetAPI.getConfigMarkets(target, sport, keyword).then(( res ) => {
                    if(res.data.success){
                        const { total, marketGroups } = res.data.message;
                        
                        setData({ total, marketGroups });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '마켓 설정'
                        });
                    }
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '마켓 설정'
                    });
                });
            }, 300);

            return () => clearTimeout(delay)
        }
    }, [target, sport, keyword]);

    useEffect(() => {
        const { total, marketGroups } = data;

        console.log('marketGroups', marketGroups)

        let filterConfigObjects: IBetConfigObject[] = [];
        let filterConfigValues: IBetConfigValues = {};

        for( const marketGroup of marketGroups ){
            const inputName = marketGroup.uid;
            const inputUsedValue = marketGroup.useable;
            const inputRate1Value = marketGroup.rate_1;
            const inputRateXValue = marketGroup.rate_x;
            const inputRate2Value = marketGroup.rate_2;
            const inputLimitPerValue = marketGroup.limit_per;
            const inputOrderValue = marketGroup.order;

            let filterConfigObject: any = {
                inputUsedName: `${inputName}_used`,
                inputOrderName: `${inputName}_order`
            };

            if(typeof marketGroup.rate_1 == 'number'){
                filterConfigObject = {
                    ...filterConfigObject,
                    inputRate1Name: `${inputName}_rate_1`
                }

                /*
                if(configValues){
                    filterConfigValues[`${inputName}_rate_1`] = (typeof configValues[`${inputName}_rate_1`] == 'number') ? configValues[`${inputName}_rate_1`] : Number(inputRate1Value);
                }else{
                    filterConfigValues[`${inputName}_rate_1`] = Number(inputRate1Value);
                }
                */

                filterConfigValues[`${inputName}_rate_1`] = Number(inputRate1Value);
            }

            if(typeof marketGroup.rate_x == 'number'){
                filterConfigObject = {
                    ...filterConfigObject,
                    inputRateXName: `${inputName}_rate_x`
                }

                /*
                if(configValues){
                    filterConfigValues[`${inputName}_rate_x`] = (typeof configValues[`${inputName}_rate_x`] == 'number') ? configValues[`${inputName}_rate_x`] : Number(inputRateXValue);
                }else{
                    filterConfigValues[`${inputName}_rate_x`] = Number(inputRateXValue);
                }
                */

                filterConfigValues[`${inputName}_rate_x`] = Number(inputRateXValue);
            }

            if(typeof marketGroup.rate_2 == 'number'){
                filterConfigObject = {
                    ...filterConfigObject,
                    inputRate2Name: `${inputName}_rate_2`
                }

                /*
                if(configValues){
                    filterConfigValues[`${inputName}_rate_2`] = (typeof configValues[`${inputName}_rate_2`] == 'number') ? configValues[`${inputName}_rate_2`] : Number(inputRate2Value);
                }else{
                    filterConfigValues[`${inputName}_rate_2`] = Number(inputRate2Value);
                }
                */

                filterConfigValues[`${inputName}_rate_2`] = Number(inputRate2Value);
            }

            if(typeof marketGroup.limit_per == 'number'){
                filterConfigObject = {
                    ...filterConfigObject,
                    inputLimitPerName: `${inputName}_limit_per`
                }

                /*
                if(configValues){
                    filterConfigValues[`${inputName}_limit_per`] = (typeof configValues[`${inputName}_limit_per`] == 'number') ? configValues[`${inputName}_limit_per`] : Number(inputRate2Value);
                }else{
                    filterConfigValues[`${inputName}_limit_per`] = Number(inputLimitPerValue);
                }
                */

                filterConfigValues[`${inputName}_limit_per`] = Number(inputLimitPerValue);
            }
            
            filterConfigObjects.push(filterConfigObject);

            if(configValues){
                filterConfigValues[`${inputName}_used`] = (typeof configValues[`${inputName}_used`] == 'boolean') ? configValues[`${inputName}_used`] : (inputUsedValue == 'true') ? true : false;
                filterConfigValues[`${inputName}_order`] = (typeof configValues[`${inputName}_order`] == 'number') ? configValues[`${inputName}_order`] : inputOrderValue;
            }else{
                filterConfigValues[`${inputName}_used`] = (inputUsedValue == 'true') ? true : false;
                filterConfigValues[`${inputName}_order`] = inputOrderValue;
            }
        }

        setConfigObjects((configObjects) => [ ...filterConfigObjects ]);
        setConfigValues((configValues) => ({ ...filterConfigValues }));

        setOrderOptions(() => filterConfigObjects.map((_filterConfigObject: any, index: number) => ({ value: String(index), label: String(index + 1) }) ))

        spinnerVisible(false);
    }, [data])

    const handleConfigValue = (key: string, value: number | string | boolean) => {
        setConfigValues((configValues) => {
            if(configValues && Object.keys(configValues).length > 0){
                return { ...configValues, [`${key}`]: value }
            }else{
                return configValues;
            }
        })
    }

    const handleSetOrder = (uid: string, value: string) => {
        spinnerVisible(true);

        let filterMarketGroups: any[] = [];

        setData((data) => {
            let { total, marketGroups } = data;

            const currentOrder = marketGroups.findIndex((marketGroup) => marketGroup.uid == uid)
            const targetOrder = Number(value);

            if(currentOrder < 0 || typeof targetOrder != 'number'){
                modalAlert({
                    component: <>순서변경을 실패했습니다.</>,
                    title: '마켓 설정'
                });

                spinnerVisible(false);

                return data;
            }else{
                filterMarketGroups = [ ...marketGroups ];

                const currentMarketGroup = filterMarketGroups.find((marketGroup, index) => index == currentOrder);
                const targetMarketGroup = filterMarketGroups.find((marketGroup, index) => index == targetOrder);

                if(currentMarketGroup && targetMarketGroup){
                    handleConfigValue(`${currentMarketGroup.uid}_order`, targetOrder)
                    handleConfigValue(`${targetMarketGroup.uid}_order`, currentOrder)

                    filterMarketGroups.splice(currentOrder, 1, targetMarketGroup);
                    filterMarketGroups.splice(targetOrder, 1, currentMarketGroup);
                }

                let filterParams = filterMarketGroups.map((marketGroup, index) => ({ uid_target: marketGroup.uid, key: 'bet_order', value: index }))

                BetAPI.setConfigMarket(filterParams).then((res) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리가 완료되었습니다.</>,
                            title: '마켓 설정'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '마켓 설정'
                        });
                    }

                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '마켓 설정'
                    });
                });

                return { total, marketGroups: filterMarketGroups };
            }
        });
    }

    const handleSetRate = (uid: string) => {
        if(configValues && Object.keys(configValues).length > 0){
            spinnerVisible(true);
            
            let params = [];

            if(typeof configValues[`${uid}_rate_1`] == 'number'){
                params.push({
                    uid_target: uid,
                    key: 'bet_rate_1',
                    value: configValues[`${uid}_rate_1`]
                });
            }

            if(typeof configValues[`${uid}_rate_x`] == 'number'){
                params.push({
                    uid_target: uid,
                    key: 'bet_rate_x',
                    value: configValues[`${uid}_rate_x`]
                });
            }

            if(typeof configValues[`${uid}_rate_2`] == 'number'){
                params.push({
                    uid_target: uid,
                    key: 'bet_rate_2',
                    value: configValues[`${uid}_rate_2`]
                });
            }

            BetAPI.setConfigMarket(params).then((res) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리가 완료되었습니다.</>,
                        title: '마켓 설정'
                    });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '마켓 설정'
                    });
                }

                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);

                modalAlert({
                    component: <>{error.message}</>,
                    title: '마켓 설정'
                });
            });
        }
    }

    const handleSetUsed = (uid: string, value: string) => {
        spinnerVisible(true);

        let params = {
            uid_target: uid,
            key: 'bet_used',
            value
        }

        BetAPI.setConfigMarket(params).then((res) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리가 완료되었습니다.</>,
                    title: '마켓 설정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '마켓 설정'
                });
            }

            spinnerVisible(false);
        }).catch(( error ) => {
            spinnerVisible(false);
            
            modalAlert({
                component: <>{error.message}</>,
                title: '마켓 설정'
            });
        });
    }

    return (
        <div className="contents">
            <MarketsSearchForm target={target} setSearchForms={setSearchForms} />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <table className="table">
                                <colgroup>
                                    <col width="120px"/>
                                    <col />
                                    <col width="100px"/>
                                    <col width="100px"/>
                                    <col width="100px"/>
                                    <col width="140px"/>
                                    <col width="80px"/>
                                    <col width="100px"/>
                                </colgroup>
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col">코드</th>
                                        <th scope="col" rowSpan={2}>마켓명</th>
                                        <th scope="col" colSpan={3}>증차감</th>
                                        <th scope="col" rowSpan={2}>제한율</th>
                                        <th scope="col" rowSpan={2}>-</th>
                                        <th scope="col">순서</th>
                                        
                                    </tr>
                                    <tr className="align-middle">
                                        <th scope="col">종목</th>
                                        <th scope="col">1</th>
                                        <th scope="col">X</th>
                                        <th scope="col">2</th>
                                        <th scope="col">사용</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(configObjects.length > 0 && configValues) && data.marketGroups.map(( marketGroup: any, key ) => 
                                        <React.Fragment key={key}>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <td>{marketGroup.uid}</td>
                                                <td rowSpan={2}>{marketGroup.name_kr ? marketGroup.name_kr : marketGroup.name}</td>
                                                <td rowSpan={2}>
                                                    {(configObjects[key] && typeof configObjects[key].inputRate1Name == 'string') && 
                                                        <Input 
                                                            type="number"
                                                            name={`${configObjects[key].inputRate1Name}`}
                                                            value={configValues[configObjects[key].inputRate1Name]}
                                                            onChange={(event) => handleConfigValue(`${marketGroup.uid}_rate_1`, event.target.value)}
                                                            step="0.01"
                                                        />
                                                    }
                                                </td>
                                                <td rowSpan={2}>
                                                    {(configObjects[key] && typeof configObjects[key].inputRateXName == 'string') && 
                                                        <Input 
                                                            type="number"
                                                            name={`${configObjects[key].inputRateXName}`}
                                                            value={configValues[configObjects[key].inputRateXName]}
                                                            onChange={(event) => handleConfigValue(`${marketGroup.uid}_rate_x`, event.target.value)}
                                                            step="0.01"
                                                        />
                                                    }
                                                </td>
                                                <td rowSpan={2}>
                                                    {(configObjects[key] && typeof configObjects[key].inputRate2Name == 'string') && 
                                                        <Input 
                                                            type="number"
                                                            name={`${configObjects[key].inputRate2Name}`}
                                                            value={configValues[configObjects[key].inputRate2Name]}
                                                            onChange={(event) => handleConfigValue(`${marketGroup.uid}_rate_2`, event.target.value)}
                                                            step="0.01"
                                                        />
                                                    }
                                                </td>
                                                <td rowSpan={2}>
                                                    {(configObjects[key] && typeof configObjects[key].inputLimitPerName == 'string') && 
                                                        <InputGroup>
                                                            <Input 
                                                                type="number"
                                                                name={`${configObjects[key].inputLimitPerName}`}
                                                                value={configValues[configObjects[key].inputLimitPerName]}
                                                                onChange={(event) => handleConfigValue(`${marketGroup.uid}_limit_per`, event.target.value)}
                                                                step="1"
                                                                min={0}
                                                                max={100}
                                                            />
                                                            <InputGroupText>%</InputGroupText>
                                                        </InputGroup>
                                                    }
                                                </td>
                                                <td rowSpan={2}>
                                                    <Button
                                                        color="secondary"
                                                        type="button"
                                                        onClick={(event) => handleSetRate(marketGroup.uid)}
                                                    >
                                                        적용
                                                    </Button>
                                                </td>
                                                <td>
                                                    {configObjects[key] && 
                                                        <Input 
                                                            type="select"
                                                            name={`${configObjects[key].inputOrderName}`}
                                                            value={configValues[configObjects[key].inputOrderName]}
                                                            onChange={(event) => handleSetOrder(marketGroup.uid, event.target.value)}
                                                        >
                                                            {orderOptions!.map((option, index) => {
                                                                return (
                                                                    <option key={index} value={option.value}>{option.label}</option>
                                                                )
                                                            })}
                                                        </Input>
                                                    }
                                                </td>
                                            </tr>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <td>{marketGroup.sport.name_kr ? marketGroup.sport.name_kr : marketGroup.sport.name}</td>
                                                <td>
                                                    <FormGroup switch className="mb-0">
                                                        {configObjects[key] && 
                                                            <Input 
                                                                type="switch"
                                                                className="form-control-sm"
                                                                name={`${configObjects[key].inputUsedName}`}
                                                                checked={configValues[configObjects[key].inputUsedName]}
                                                                onChange={(event) => {
                                                                    handleConfigValue(`${marketGroup.uid}_used`, event.currentTarget.checked)
                                                                    handleSetUsed(marketGroup.uid, String(event.currentTarget.checked))
                                                                }}
                                                            />
                                                        }
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {data.marketGroups.length <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={8} className="py-5 text-center">해당되는 마켓이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SettingMarketsForm