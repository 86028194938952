import { AxiosPromise } from "axios";
import API from "./API";

import { IAccount } from "../interfaces/Account";

const AccountsAPI = {
    get: (detail: boolean = false, online: boolean = false, page: number = 1, limit: number = 10, ruid: string = '', keyword: string = ''): AxiosPromise => {
        const baseUrl = process.env.REACT_APP_API_ACCOUNTS_URL;
        const targetUrl = (!detail) 
            ?  baseUrl
            :  baseUrl + '/detail';

        let _query = (page <= 1) ? '' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (ruid) ? `&ruid=${ruid}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';
        _query += (online) ? `&online=1` : '';

        return API({
            method: 'get',
            url: targetUrl + `?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
}

export default AccountsAPI;