import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link } from "react-router-dom";

import Util from "../../modules/Util";
import ConfigAPI from "../../modules/ConfigAPI";
import AccountAPI from "../../modules/AccountAPI";
import AccountsAPI from "../../modules/AccountsAPI";

import SearchForm from './SearchForm';
import InputControl from '../common/InputControl';
import { IAccount } from "../../interfaces/Account";
import SignXAPI from "../../modules/SignXAPI";

const AccountsOnlineView = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();

    const [ data, setData ] = useState({
        total: 0,
        accounts: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { ruid, keyword }, setSearchForms ] = useState({
        ruid: '',
        keyword: ''
    });

    useEffect(() => {
        AccountsAPI.get(true, true, page, limit, ruid).then(( res ) => {
            const data = res.data.message;

            setData(data);
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: 'Accounts'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            AccountsAPI.get(true, true, 1, limit, ruid, keyword).then(( res ) => {
                if(res.data.success){
                    const data = res.data.message;

                    setData(data);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: 'Accounts'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: 'Accounts'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [ruid, keyword]);

    const handleDeleteAccount = (uuid: string) => {
        modalConfirm({
            component: <>Are you sure you want to delete?</>,
            title: 'Delete Account',
            handleConfirm: () => {
                AccountAPI.delete(uuid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>Deletion is complete.</>,
                            title: 'Alert'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: 'Alert'
                        });
                    }
                }).catch((error) => {
                    const res = error.response;
        
                    if(res){
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: 'Alert'
                        });
                    }
                })
            }
        })
    }

    const handleSighoutForced = (uuid: string) => {
        modalConfirm({
            component: <>정말 강제로그아웃 시키겠습니까?</>,
            title: '강제로그아웃',
            handleConfirm: () => {
                SignXAPI.signoutForced(uuid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>처리되었습니다.</>,
                            title: '강제로그아웃'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '강제로그아웃'
                        });
                    }
                }).catch((error) => {
                    const res = error.response;
        
                    if(res){
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '강제로그아웃'
                        });
                    }
                })
            }
        })
    }

    return (
        <div className="contents">
            <SearchForm setSearchForms={setSearchForms} />
            <Card>
                <CardBody>
                    <table className="table">
                        <thead>
                            <tr className="align-middle">
                                <th scope="col" rowSpan={2}>번호</th>
                                <th scope="col" >루트총판</th>
                                <th scope="col" rowSpan={2}>아이디</th>
                                <th scope="col" >이름</th>
                                <th scope="col" rowSpan={2}>회원등급</th>
                                <th scope="col" >최근 접속일</th>
                                <th scope="col" rowSpan={2}>관리</th>
                            </tr>
                            <tr className="align-middle">
                                <th scope="col" >상위총판</th>
                                <th scope="col">닉네임</th>
                                <th scope="col">최근 접속IP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.accounts.map(( account: any, key ) => 
                                <React.Fragment key={key}>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <th rowSpan={2} scope="row">{key + 1}</th>
                                        <td>{account.userid_agent_root}</td>
                                        <td rowSpan={2}>
                                            <Link to={'/account/' + account.uuid}>
                                                {account.userid}
                                            </Link>
                                        </td>
                                        <td>
                                            {Util.getBoolean(configState.signup_used_fullname) ? account.fullname : `${account.firstname} ${account.lastname}`}
                                        </td>
                                        <td rowSpan={2}>{account.role.name}</td>
                                        <td>{account.visited_at ? moment(account.visited_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                        <td rowSpan={2}>
                                            <Button
                                                className="btn-table-icon"
                                                type="button"
                                                color="danger"
                                                onClick={(event) => handleSighoutForced(account.uuid)}
                                            >
                                                <i className="fas fa-sign-out-alt" />
                                            </Button>
                                            &nbsp;
                                            <Button
                                                className="btn-table-icon"
                                                type="button"
                                                color="primary"
                                                onClick={(event) => navigate('/account/' + account.uuid)}
                                            >
                                                <i className="fas fa-user-edit" />
                                            </Button>
                                            &nbsp;
                                            {account.role.key != 'deleted' && 
                                                <Button
                                                    className="btn-table-icon"
                                                    type="button"
                                                    color="danger"
                                                    onClick={(event) => handleDeleteAccount(account.uuid)}
                                                >
                                                    <i className="fas fa-trash" />
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <td>{account.userid_agent_parent}</td>
                                        <td>{account.nickname}</td>
                                        <td>{account.visited_ip}</td>
                                    </tr>
                                </React.Fragment>
                            )}

                            {data.total <= 0 && 
                                <tr className="align-middle">
                                    <td colSpan={8} className="py-5 text-center">등록된 회원이 없습니다.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                        total={data.total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default AccountsOnlineView