import React, { useRef, useState, useEffect, useMemo, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { useNavigate, Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Card, CardBody, Input, Button, FormGroup, Form, Row, Col } from 'reactstrap';

import moment from "moment";

import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import Util from "../../modules/Util";

import EventsSearchForm from './EventsSearchForm';
import InputControl from '../common/InputControl';
import { IAccount } from "../../interfaces/Account";
import BetAPI from "../../modules/BetAPI";
import { IConfig } from "../../interfaces/Config";
import { IBetConfigObject, IBetConfigValues } from "../../interfaces/BetConfig";
import EventResultFormModal from "./EventResultFormModal";


const SettingEventsForm = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalForm } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ data, setData ] = useState({
        total: 0,
        events: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { target, sport, status, keyword }, setSearchForms ] = useState({
        target: 'upcoming',
        sport: '',
        status: 0,
        keyword: ''
    });

    const [ configValues, setConfigValues ] = useState<IConfig>();
    const [ configObjects, setConfigObjects ] = useState<IBetConfigObject[]>([]);

    useEffect(() => {
        if(sport){
            spinnerVisible(true);

            BetAPI.getConfigEvents(target, page, limit, sport, status, keyword).then(( res ) => {
                const data = res.data.message;

                console.log(data);

                setData(data);

                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);

                modalAlert({
                    component: <>{error.message}</>,
                    title: '경기설정'
                });
            });
        }
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            if(sport){
                spinnerVisible(true);
                
                BetAPI.getConfigEvents(target, 1, limit, sport, status, keyword).then(( res ) => {
                    if(res.data.success){
                        const { total, events } = res.data.message;

                        console.log('events', events)

                        let filterConfigObjects: IBetConfigObject[] = [];
                        let filterConfigValues: IBetConfigValues = {};

                        for( const event of events ){
                            const inputName = event.uid;
                            const inputValue = event.useable;

                            filterConfigObjects.push({
                                inputName
                            })

                            filterConfigValues[`${inputName}`] = (inputValue == 'true') ? true : false;
                        }

                        setConfigObjects((configObjects) => ([ ...filterConfigObjects ]));
                        setConfigValues((configValues) => ({ ...filterConfigValues }));

                        setData({ total, events });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '경기설정'
                        });
                    }

                    spinnerVisible(false);
                }).catch(( error ) => {
                    spinnerVisible(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '경기설정'
                    });
                });
            }
        }, 300);

        return () => clearTimeout(delay)
    }, [target, sport, status, keyword]);

    const handleSetConfig = (target: string, uid: string, value: string) => {
        BetAPI.setConfigEvent(target, uid, 'bet_used', value).then((res) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리가 완료되었습니다.</>,
                    title: '경기설정'
                });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '경기설정'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '경기설정'
            });
        });
    }

    const handleEdit = (target: string, event: any) => {
        modalAlert({
            component: <EventResultFormModal target={target} event={event}/>,
            title: '경기기록',
            size: 'xl'
        });
    }

    return (
        <div className="contents">
            <EventsSearchForm target={target} status={status} setSearchForms={setSearchForms} />
            {configValues && 
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <table className="table">
                                    <colgroup>
                                        <col width="120px"/>
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col width="100px"/>
                                    </colgroup>
                                    <thead>
                                        <tr className="align-middle">
                                            <th scope="col">코드</th>
                                            <th scope="col">종목</th>
                                            <th scope="col">홈팀</th>
                                            <th scope="col" rowSpan={2}>스코어</th>
                                            <th scope="col">시작시간</th>
                                            <th scope="col" rowSpan={2}>관리</th>
                                            <th scope="col" rowSpan={2}>사용</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">상태</th>
                                            <th scope="col">리그</th>
                                            <th scope="col">원정팀</th>
                                            <th scope="col">등록시간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.events.map(( event: any, key ) => 
                                            <React.Fragment key={key}>
                                                <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                    <td>{event.uid}</td>
                                                    <td>{event.sport.name_kr ? event.sport.name_kr : event.sport.name}</td>
                                                    <td>{event.team_home.name_kr ? event.team_home.name_kr : event.team_home.name}</td>
                                                    <td rowSpan={2}>{event.scores[0].home} - {event.scores[0].away}</td>
                                                    <td>{event.start_time && moment.unix(event.start_time).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                    <td rowSpan={2}>
                                                        <Button
                                                            className="btn-table-icon"
                                                            type="button"
                                                            color="primary"
                                                            onClick={(e) => { handleEdit(target, event) }}
                                                        >
                                                            <i className="fas fa-file-video"></i>
                                                        </Button>
                                                    </td>
                                                    <td rowSpan={2}>
                                                        <FormGroup switch className="mb-0">
                                                            <Input 
                                                                type="switch"
                                                                className="form-control-sm"
                                                                name={`${configObjects[key].inputName}`}
                                                                checked={configValues[configObjects[key].inputName]}
                                                                onChange={(e) => handleSetConfig(target, configObjects[key].inputName, String(e.currentTarget.checked))}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                                <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                    <td>{Util.getTimeStatus(event.status)}</td>
                                                    <td>{event.league.name_kr ? event.league.name_kr : event.league.name}</td>
                                                    <td>{event.team_away.name_kr ? event.team_away.name_kr : event.team_away.name}</td>
                                                    <td>{moment(event.created_at).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                </tr>
                                            </React.Fragment>
                                        )}

                                        {data.events.length <= 0 &&
                                            <tr>
                                                <td colSpan={7} className="text-center py-5">자료가 없습니다.</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <Pagination 
                                    total={data.total}
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    setLimit={setLimit}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default SettingEventsForm