import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

import ConfigAPI from "../../modules/ConfigAPI";
import BetAPI from "../../modules/BetAPI";
import Util from "../../modules/Util";

interface ISearchFormProps {
    target: string;
    setSearchForms: Dispatch<SetStateAction<any>>;
}

const LeaguesSearchForm = (props: ISearchFormProps) => {
    const { target, setSearchForms } = props;
    
    const { modalAlert } = useModal();

    const targetOptions = [
        { value: 'upcoming', label: '프리매치' },
        { value: 'inplay', label: '인플레이' }
    ]

    const [ sportOptions, setSportOptions ] = useState<{value: any, label: any}[]>([])

    useEffect(() => {
        BetAPI.getSports(target).then(( res ) => {
            if(res.data.success){
                const sports = res.data.message;

                let filterSportOptions: {value: any, label: any}[] = [];
    
                let forCount = 0;
                for( const sport of sports ){
                    if(forCount == 0)
                        setSearchForms((searchForm: any) => ({ ...searchForm, sport: sport.uid }))

                    filterSportOptions.push({
                        value: sport.uid,
                        label: (sport.name_kr) ? sport.name_kr : sport.name
                    })

                    forCount++;
                }

                setSportOptions([ ...filterSportOptions ]);
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '리그 설정'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '리그 설정'
            });
        });
    }, [target])

    return (
        <div className="search-wrapper">
            <Card className="card-form-search">
                <CardHeader>
                    <CardTitle tag="h5">검색</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="2">
                            <Label for="target">타입</Label>
                            <Input
                                type="select"
                                name="target"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, target: e.target.value} })}
                            >
                                {targetOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="2">
                            <Label for="target">종목</Label>
                            <Input
                                type="select"
                                name="sport"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, sport: e.target.value} })}
                            >
                                {sportOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="8">
                            <Label for="keyword">검색어</Label>
                            <Input
                                name="keyword"
                                placeholder="검색어 ( 코드, 리그명 ... )"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, keyword: e.target.value} })}
                            />
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </div>
    )
}

export default LeaguesSearchForm