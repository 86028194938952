import React, { useRef, useState, useEffect } from "react";

import { IInplayItemScoreProps } from "../../interfaces/Inplay";

const InplayItemScore = (props: IInplayItemScoreProps) => {
    const { score, images } = props;

    return (
        <div className="score">
            <div className="image home" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${images[0]}.png')`}}></div>
            <div className="home">{score[0].home}</div>
            <div className="vs">VS</div>
            <div className="away">{score[0].away}</div>
            <div className="image away" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${images[1]}.png')`}}></div>
        </div>
    )
}

export default InplayItemScore
