import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Row, Col, Card, CardBody, Input, Button } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link } from "react-router-dom";

import Util from "../../modules/Util";
import BoardsAPI from "../../modules/BoardsAPI";

import SearchForm from './SearchForm';
import BoardFormModal from "./BoardFormModal";

const BoardsView = () => {
    const navigate = useNavigate();

    const authState = useSelector((state: RootState) => state.authReducer);
    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm, modalForm } = useModal();

    const [ data, setData ] = useState({
        total: 0,
        boards: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { keyword }, setSearchForms ] = useState({
        keyword: ''
    });

    useEffect(() => {
        BoardsAPI.get(page, limit).then(( res ) => {
            const { total, boards } = res.data.message;

            setData({ total, boards });
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '게시판 목록'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1){
            setPage(1);
        }else{
            const delay = setTimeout(() => {
                BoardsAPI.get(1, limit, keyword).then(( res ) => {
                    const { total, boards } = res.data.message;
    
                    setData({ total, boards });
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '게시판 목록'
                    });
                });
            }, 300);
            
            return () => clearTimeout(delay)
        }
    }, [keyword]);

    const handleEdit = (uid: string = '') => {
        modalForm({
            formId: 'modal-form-board',
            component: <BoardFormModal uid={uid} />,
            title: (!uid) ? '게시판 추가' : '게시판 수정'
        });
    }

    const handleDeleteBoard = (uuid: string) => {
        modalConfirm({
            component: <>정말로 삭제하시겠습니까?</>,
            title: '게시판 목록',
            handleConfirm: () => {
                /*
                AccountAPI.delete(uuid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            message: 'Deletion is complete.',
                            title: 'Alert'
                        });
                    }else{
                        modalAlert({
                            message: res.data.message,
                            title: 'Alert'
                        });
                    }
                }).catch((error) => {
                    const res = error.response;
        
                    if(res){
                        modalAlert({
                            message: res.data.message,
                            title: 'Alert'
                        });
                    }
                })
                */
            }
        })
    }

    return (
        <div className="contents">
            <Row className="mb-3">
                <Col>
                    <div className="d-flex justify-content-end">
                        <Button
                            className="btn-round"
                            type="button"
                            color="secondary" 
                            onClick={(event) => handleEdit()}
                        >
                            <i className="fa fa-plus"></i> 게시판 추가
                        </Button>
                    </div>
                </Col>
            </Row>
            <SearchForm setSearchForms={setSearchForms} />
            <Card>
                <CardBody>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">번호</th>
                                <th scope="col">키</th>
                                <th scope="col">게시판명</th>
                                <th scope="col">생성일</th>
                                <th scope="col">수정일</th>
                                <th scope="col">관리</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.boards.map(( board: any, key ) => 
                                <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`} key={key}>
                                    <th scope="row">{key + 1}</th>
                                    <td>{board.key}</td>
                                    <td>{board.name}</td>
                                    <td>{moment(board.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>{moment(board.updated_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>
                                        <Button
                                            className="btn-table-icon"
                                            type="button"
                                            color="secondary"
                                            onClick={(event) => navigate('/posts/' + board.key, { state: { type_board: board.type } })}
                                        >
                                            <i className="fas fa-list" />
                                        </Button>
                                        &nbsp;
                                        <Button
                                            className="btn-table-icon"
                                            type="button"
                                            color="primary"
                                            onClick={(event) => handleEdit(board.uid)}
                                        >
                                            <i className="fas fa-edit" />
                                        </Button>
                                        {authState.role.key == 'root' && 
                                            <>
                                                &nbsp;
                                                <Button
                                                    className="btn-table-icon"
                                                    type="button"
                                                    color="danger"
                                                    onClick={(event) => handleDeleteBoard(board.uid)}
                                                >
                                                    <i className="fas fa-trash" />
                                                </Button>
                                            </>
                                        }
                                    </td>
                                </tr>
                            )}

                            {data.total <= 0 && 
                                <tr className="align-middle">
                                    <td colSpan={8} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                        total={data.total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default BoardsView