import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

import ConfigAPI from "../../modules/ConfigAPI";

import InputControl from '../common/InputControl';

interface ISearchFormProps {
    setSearchForms: Dispatch<SetStateAction<any>>;
}

const SearchForm = (props: ISearchFormProps) => {
    const { setSearchForms } = props;
    
    const { modalAlert, modalConfirm } = useModal();

    return (
        <div className="search-wrapper">
            <Card className="card-form-search">
                <CardHeader>
                    <CardTitle tag="h5">검색</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="12">
                            <Label for="keyword">검색어</Label>
                            <Input
                                name="keyword"
                                placeholder="검색어 ( 작성자, 제목, 내용 ... )"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, keyword: e.target.value} })}
                            />
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </div>
    )
}

export default SearchForm