import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col, Tooltip } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useNavigate, Link, useParams } from "react-router-dom";
import * as DOMPurify from 'dompurify';

import Util from "../../modules/Util";

import SearchForm from './SearchForm';
import AmountsAPI from "../../modules/AmountsAPI";
import MemosAPI from "../../modules/MemosAPI";
import MemoFormModal from "./MemoFormModal";
import MemoGroupFormModal from "./MemoGroupFormModal";
import MemoAPI from "../../modules/MemoAPI";

const MemosView = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm, modalForm } = useModal();

    const [ viewIndex, setViewIndex ] = useState<number | null>(null)
    const [ data, setData ] = useState({
        total: 0,
        memos: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ searchForms, setSearchForms ] = useState({
        method: '',
        type: '',
        keyword: ''
    });

    useEffect(() => {
        MemosAPI.get(page, limit, searchForms.method, searchForms.type, searchForms.keyword).then(( res ) => {
            const { total, memos } = res.data.message;

            let filterMemos: any = [];

            for( const memo of memos ){
                let filterMemo = {
                    ...memo
                }

                filterMemos.push(filterMemo);
            }

            setData({ total, memos: filterMemos });

        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '쪽지 목록'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            MemosAPI.get(1, limit, searchForms.method, searchForms.type, searchForms.keyword).then(( res ) => {
                const { total, memos } = res.data.message;

                console.log('memos', memos)
    
                let filterMemos: any = [];
    
                for( const memo of memos ){
                    let filterMemo = {
                        ...memo
                    }
    
                    filterMemos.push(filterMemo);
                }
    
                setData({ total, memos: filterMemos });
    
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '쪽지 목록'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [searchForms.method, searchForms.type, searchForms.keyword]);

    const handleDelete = (idx: number) => {
        modalConfirm({
            component: <>정말 삭제하시겠습니까?</>,
            title: '쪽지 삭제',
            handleConfirm: () => {
                MemoAPI.delete(idx).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>삭제되었습니다.</>,
                            title: '쪽지 삭제'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '쪽지 삭제'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '쪽지 삭제'
                    });
                });
            }
        })
    }

    const handleDeleteGroup = (uid: string) => {
        modalConfirm({
            component: <>관련된 쪽지는 모두 삭제됩니다.<br />정말 삭제하시겠습니까?</>,
            title: '쪽지 삭제',
            handleConfirm: () => {
                MemoAPI.deleteGroup(uid).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>삭제되었습니다.</>,
                            title: '쪽지 삭제'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '쪽지 삭제'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '쪽지 삭제'
                    });
                });
            }
        })
    }

    const handleEdit = () => {
        modalForm({
            formId: 'modal-form-memo',
            component: <MemoFormModal />,
            title: '개별쪽지보내기'
        });
    }

    const handleGroupEdit = () => {
        modalForm({
            formId: 'modal-form-memo-group',
            component: <MemoGroupFormModal />,
            title: '단체쪽지보내기'
        });
    }

    const handleViewIndex = (index: number) => {
        setViewIndex((_viewIndex) => {
            if(_viewIndex == index){
                return null
            }else{
                return index;
            }
        })
    }

    return (
        <div className="contents">
            <Row className="mb-3">
                <Col>
                    <div className="d-flex justify-content-end">
                        <Button
                            className="btn-round"
                            type="button"
                            color="secondary" 
                            onClick={(event) => handleEdit()}
                        >
                            <i className="fas fa-envelope"></i> 개별쪽지보내기
                        </Button>
                        &nbsp;
                        <Button
                            className="btn-round"
                            type="button"
                            color="secondary" 
                            onClick={(event) => handleGroupEdit()}
                        >
                            <i className="fas fa-envelope"></i> 단체쪽지보내기
                        </Button>
                    </div>
                </Col>
            </Row>
            <SearchForm searchForms={searchForms} setSearchForms={setSearchForms} />
            <Card>
                <CardBody>
                    <table className="table">
                        <colgroup>
                            <col width="80px"/>
                            <col width="160px"/>
                            <col width="150px"/>
                            <col width="150px"/>
                            <col />
                            <col width="200px"/>
                            <col width="150px"/>
                        </colgroup>
                        <thead>
                            <tr className="align-middle">
                                <th rowSpan={2} scope="col">번호</th>
                                <th scope="col">타입</th>
                                <th rowSpan={2} scope="col">보낸 사람</th>
                                <th rowSpan={2} scope="col">{(searchForms.method == 'send') ? '읽은 수 / 받은 수':'받은 사람'}</th>
                                <th rowSpan={2} scope="col">제목</th>
                                <th rowSpan={2} scope="col">등록일</th>
                                <th rowSpan={2} scope="col">관리</th>
                            </tr>
                            <tr>
                                <th scope="col">상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.memos.map(( memo: any, key ) => 
                                searchForms.method == 'send'
                                ? 
                                <React.Fragment key={key}>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <th rowSpan={2} scope="row">{key + 1}</th>
                                        <td>[{memo.type}] {memo.group ? memo.group : ''}</td>
                                        <td>{memo.sender.userid}</td>
                                        <td rowSpan={2}>
                                            <Button
                                                className="btn-table-icon"
                                                type="button"
                                                color="secondary"
                                            >
                                                <i className="fas fa-user"></i>  {memo.count_read} / {memo.receivers ? memo.receivers.length : 0}
                                            </Button>
                                        </td>
                                        <td rowSpan={2}><span onClick={(event) => handleViewIndex(key)} className="text-primary text-decoration-underline cursor-pointer">{memo.subject}</span></td>
                                        <td rowSpan={2}>{moment(memo.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td rowSpan={2}>
                                            <Button
                                                className="btn-table-icon"
                                                type="button"
                                                color="danger"
                                                onClick={(event) => handleDeleteGroup(memo.uid)}
                                            >
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <td>-</td>
                                        <td>{memo.sender.nickname}</td>
                                    </tr>
                                    <tr className={`${(viewIndex == key) ? 'd-table-row' : 'd-none'}`}>
                                        <td colSpan={7}>
                                            <div 
                                                dangerouslySetInnerHTML={{ 
                                                    __html: DOMPurify.sanitize(memo.contents) 
                                                }}
                                                className="p-3"
                                                style={{minHeight: '150px', backgroundColor: '#efefef'}}
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                                : 
                                <React.Fragment key={key}>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <th rowSpan={2} scope="row">{key + 1}</th>
                                        <td>[{memo.type}] {memo.group ? memo.group : ''}</td>
                                        <td>{memo.sender.userid}</td>
                                        <td>{memo.receiver ? memo.receiver.userid : ''}</td>
                                        <td rowSpan={2}><span onClick={(event) => handleViewIndex(key)} className="text-primary text-decoration-underline cursor-pointer">{memo.subject}</span></td>
                                        <td rowSpan={2}>{moment(memo.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td rowSpan={2}>
                                            <Button
                                                className="btn-table-icon"
                                                type="button"
                                                color="danger"
                                                onClick={(event) => handleDelete(memo.idx)}
                                            >
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                        <td>{memo.is_read ? '읽음' : '안읽음'}</td>
                                        <td>{memo.sender.nickname}</td>
                                        <td>{memo.receiver ? memo.receiver.nickname : ''}</td>
                                    </tr>
                                    <tr className={`${(viewIndex == key) ? 'd-table-row' : 'd-none'}`}>
                                        <td colSpan={7}>
                                            <div 
                                                dangerouslySetInnerHTML={{ 
                                                    __html: DOMPurify.sanitize(memo.contents) 
                                                }}
                                                className="p-3"
                                                style={{minHeight: '150px', backgroundColor: '#efefef'}}
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}

                            {data.total <= 0 && 
                                <tr className="align-middle">
                                    <td colSpan={7} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                        total={data.total}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default MemosView