import React from "react";

import SettingInplayForm from "./SettingInplayForm";

const SettingInplayView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingInplayForm />
            </div>
        </div>
    )
}

export default SettingInplayView