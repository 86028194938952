import React from "react";

import SettingLevelsForm from "./SettingLevelsForm";

const SettingLevelsView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingLevelsForm />
            </div>
        </div>
    )
}

export default SettingLevelsView