import React, { useEffect, useState, useRef, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { 
    Row, 
    Col, 
    Form,
    FormGroup,
    Button,
    Input,
    Label
} from 'reactstrap';
import Select, { ActionMeta, SingleValue, PropsValue } from 'react-select'

import useModal from "../../hooks/GlobalModals";

import { hideModal } from "../../redux/reducers/modalReducer";
import BetAPI from "../../modules/BetAPI";

import EventResultStatsForm from "./EventResultStatsForm";

const EventResultFormModal = (props: any) => {
    const { target, event } = props;

    const sport = event.sport;

    const dispatch = useDispatch();

    const handleHideModal = () => dispatch(hideModal());

    const { modalAlert } = useModal();

    const [eventResult, setEventResult] = useState<any>({});

    const default_image = `/images/${sport.uid}.svg`;

    useEffect(() => {
        if(event.uid){
            BetAPI.getEventResult(target, sport.uid, event.uid).then((res) => {
                if(res.data.success){
                    const eventResult = res.data.message;

                    let filterEventResult = (eventResult) ? eventResult : {}

                    console.log('filterEventResult', filterEventResult)

                    setEventResult(filterEventResult)
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '경기기록'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '경기기록'
                });
            });
        }else{
            modalAlert({
                component: <>경기기록을 찾을 수 없습니다.</>,
                title: '경기기록',
                handleConfirm: () => {
                    handleHideModal();
                }
            });
        }
    }, []);

    const handleOnSubmit = (event: FormEvent) => {
        event.preventDefault();
        
        
    }
    
    return (
        <div className="modal-form">
            <Form onSubmit={(event) => { handleOnSubmit(event) }} id="modal-form-event-result">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                {Object.keys(eventResult).length > 0 &&
                                    <React.Fragment>
                                        <Row>
                                            <Col>
                                                <div className="players">
                                                    <div className="player">
                                                        <div className="team">
                                                            <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/0.png'), url('${default_image}')`}}></div>
                                                            <div className="name">{eventResult.team_home.name}</div>
                                                        </div>
                                                        <div className="score">{eventResult.scores[0].home}</div>
                                                    </div>
                                                    <div className="vs">
                                                        <div>{BetAPI.getTimeStatus(eventResult.status)}</div>
                                                        VS
                                                    </div>
                                                    <div className="player">
                                                        <div className="score">{eventResult.scores[0].away}</div>
                                                        <div className="team">
                                                            <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/0.png'), url('${default_image}')`}}></div>
                                                            <div className="name">{eventResult.team_away.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <EventResultStatsForm event={event} eventResult={eventResult} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="histories">
                                                    {eventResult.histories.map((history: any, key: number) => 
                                                        <div key={key}>{history.text}</div>
                                                    )}

                                                    {eventResult.histories.length <= 0 &&
                                                        <div className="py-5 text-center">자료가 없습니다.</div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                }

                                {Object.keys(eventResult).length == 0 && 
                                    <Row>
                                        <Col className="text-center py-5">
                                            경기기록이 없습니다.
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default EventResultFormModal