import { AxiosPromise } from "axios";
import API from "./API";

import { IAccount } from "../interfaces/Account";

const AccountAPI = {
    get: (uuid: string, detail: boolean = false): AxiosPromise => {
        const baseUrl = process.env.REACT_APP_API_ACCOUNT_URL;
        const targetUrl = (!detail) 
            ?  baseUrl + '/' + uuid
            :  baseUrl + '/' + uuid + '/detail';

        return API({
            method: 'get',
            url: targetUrl,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getPassbook: (uuid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_ACCOUNT_URL + '/' + uuid + '/passbook',
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    create: (params: IAccount): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_ACCOUNT_URL,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    update: (params: IAccount): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_ACCOUNT_URL + '/' + params.uuid,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    delete: (uuid: string): AxiosPromise => {
        return API({
            method: 'delete',
            url: process.env.REACT_APP_API_ACCOUNT_URL + '/' + uuid,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
}

export default AccountAPI;