import { ITreeItem, TTreeItems } from "../../../interfaces/Tree";

export function setProperty<T extends keyof ITreeItem>(
    items: TTreeItems,
    id: string,
    property: T,
    setter: (value: ITreeItem[T]) => ITreeItem[T]
) {
    for (const item of items) {
        if (item.id === id) {
            item[property] = setter(item[property]);
            continue;
        }

        if (item.children.length) {
            item.children = setProperty(item.children, id, property, setter);
        }
    }

    return [...items];
}

export function setPropertyAll<T extends keyof ITreeItem>(
    items: TTreeItems, 
    property: T, 
    value: ITreeItem[T]
) {
    const targets = [...items]
    const result: TTreeItems = []

    for( const target of targets ){
        if(target.children.length > 0){
            const targetChildren = setPropertyAll(target.children, property, value);

            target.children = targetChildren
        }

        target[property] = value;

        result.push(target);
    }

    return result;
}