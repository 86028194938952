import React from "react";

import SettingEventsForm from "./SettingEventsForm";

const SettingEventsView = () => {
    return (
        <div className="contents">
            <div className="settings">
                <SettingEventsForm />
            </div>
        </div>
    )
}

export default SettingEventsView